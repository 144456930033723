// import { PublicKey } from '@solana/web3.js'
import { DexLabMarketInfo } from '../utils/types'

// import media from '../assets/icons/media.jpeg'
// import step from '../assets/icons/step.png'
// import rope from '../assets/icons/rope.png'
// import samo from '../assets/icons/samo.webp'
// import raydium from '../assets/icons/raydium.png'
// import dexlab from '../assets/icons/dexlab.png'

// Dexlab markets
export const DEXLAB_EXTRA_MARKETS: DexLabMarketInfo[] = []

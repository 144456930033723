import { Col, Row } from 'antd'
import React, { useRef, useEffect, useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useMarket } from '../utils/dex-markets'
import { isEqual, getDecimalCount } from '../utils/utils'
import { useInterval } from '../utils/useInterval'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import usePrevious from '../utils/usePrevious'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ThemeContext, isDark } from '../utils/Contexts/ThemeContext'

const SizeTitle = styled(Row)`
  padding: 0 0 14px;
`

const MarkPriceTitle = styled(Row)`
  padding: 0px 0 14px;
  font-weight: 700;
`

const Line = styled.div`
  text-align: right;
  float: right;
  height: 100%;
  ${(props) =>
    props['data-width'] &&
    css`
      width: ${props['data-width']};
    `}
  ${(props) =>
    props['data-bgcolor'] &&
    css`
      background-color: ${props['data-bgcolor']};
    `}
`

const Price = styled.div`
  position: absolute;
  right: 5px;
`

export default function Orderbook({ smallScreen, type = 'ALL', depth = 7, orderbook, markPrice, onPrice, onSize }) {
  const [theme] = useContext(ThemeContext)
  const { t: trText } = useTranslation()
  const { baseCurrency, quoteCurrency } = useMarket()

  const currentOrderbookData = useRef(null)
  const lastOrderbookData = useRef(null)

  const [orderbookData, setOrderbookData] = useState(null)

  useInterval(() => {
    if (
      !currentOrderbookData.current ||
      JSON.stringify(currentOrderbookData.current) !== JSON.stringify(lastOrderbookData.current)
    ) {
      let bids = orderbook?.bids || []
      let asks = orderbook?.asks || []

      let sum = (total, [, size], index) => (index < depth ? total + size : total)
      let totalSize = bids.reduce(sum, 0) + asks.reduce(sum, 0)

      let bidsToDisplay = getCumulativeOrderbookSide(bids, totalSize, false)
      let asksToDisplay = getCumulativeOrderbookSide(asks, totalSize, true)

      currentOrderbookData.current = {
        bids: orderbook?.bids,
        asks: orderbook?.asks,
      }

      setOrderbookData({ bids: bidsToDisplay, asks: asksToDisplay })
    }
  }, 250)

  useEffect(() => {
    lastOrderbookData.current = {
      bids: orderbook?.bids,
      asks: orderbook?.asks,
    }
  }, [orderbook])

  function getCumulativeOrderbookSide(orders, totalSize, backwards = false) {
    let cumulative = orders.slice(0, depth).reduce((cumulative, [price, size], i) => {
      const cumulativeSize = (cumulative[i - 1]?.cumulativeSize || 0) + size
      cumulative.push({
        price,
        size,
        cumulativeSize,
        sizePercent: Math.round((cumulativeSize / (totalSize || 1)) * 100),
      })
      return cumulative
    }, [])
    if (backwards) {
      cumulative = cumulative.reverse()
    }
    return cumulative
  }

  return (
    <FloatingElement
      style={
        smallScreen
          ? { flex: 1 }
          : { height: '460px', overflow: type === 'ALL' ? 'hidden' : 'scroll', backgroundColor: '#00000000' }
      }
    >
      <SizeTitle style={{ color: isDark(theme) ? '#FFFFFF' : '#2b2b2b' }}>
        <Col span={12} style={{ textAlign: 'left' }}>
          {trText('order_size')} ({baseCurrency})
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {trText('order_price')} ({quoteCurrency})
        </Col>
      </SizeTitle>
      {(type === 'ALL' || type === 'ASK') &&
        orderbookData?.asks.map(({ price, size, sizePercent }) => (
          <OrderbookRow
            theme={theme}
            key={price + ''}
            price={price}
            size={size}
            side={'sell'}
            sizePercent={sizePercent}
            onPriceClick={() => onPrice(price)}
            onSizeClick={() => onSize(size)}
          />
        ))}
      {type === 'ALL' && <MarkPriceComponent markPrice={markPrice} />}
      {(type === 'ALL' || type === 'BID') &&
        orderbookData?.bids.map(({ price, size, sizePercent }) => (
          <OrderbookRow
            theme={theme}
            key={price + ''}
            price={price}
            size={size}
            side={'buy'}
            sizePercent={sizePercent}
            onPriceClick={() => onPrice(price)}
            onSizeClick={() => onSize(size)}
          />
        ))}
    </FloatingElement>
  )
}

const OrderbookRow = React.memo(
  ({ theme, side, price, size, sizePercent, onSizeClick, onPriceClick }) => {
    const element = useRef()

    const { market } = useMarket()

    useEffect(() => {
      // eslint-disable-next-line
      !element.current?.classList.contains('flash') && element.current?.classList.add('flash')
      const id = setTimeout(
        () => element.current?.classList.contains('flash') && element.current?.classList.remove('flash'),
        250,
      )
      return () => clearTimeout(id)
    }, [price, size])

    let formattedSize =
      market?.minOrderSize && !isNaN(size) ? Number(size).toFixed(getDecimalCount(market.minOrderSize) + 1) : size

    let formattedPrice =
      market?.tickSize && !isNaN(price) ? Number(price).toFixed(getDecimalCount(market.tickSize) + 1) : price

    return (
      <Row ref={element} style={{ marginBottom: 1 }} onClick={onSizeClick}>
        <Col span={12} style={{ textAlign: 'left', cursor: 'pointer' }}>
          {formattedSize}
        </Col>
        <Col span={12} style={{ textAlign: 'right', cursor: 'pointer' }}>
          <Line
            data-width={sizePercent + '%'}
            data-bgcolor={side === 'buy' ? 'rgba(65, 199, 122, 0.8)' : 'rgba(214, 0, 0, 0.8)'}
          />
          <Price style={{ color: isDark(theme) ? '#FFFFFF' : '#2B2B2B' }} onClick={onPriceClick}>
            {formattedPrice}
          </Price>
        </Col>
      </Row>
    )
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps, ['price', 'size', 'sizePercent']),
)

const MarkPriceComponent = React.memo(
  ({ markPrice }) => {
    const { market } = useMarket()
    const previousMarkPrice = usePrevious(markPrice)

    let markPriceColor =
      markPrice > previousMarkPrice ? '#26A69A' : markPrice < previousMarkPrice ? '#F6465D' : '#FFFFFF'

    let formattedMarkPrice = markPrice && market?.tickSize && markPrice.toFixed(getDecimalCount(market.tickSize))

    return (
      <MarkPriceTitle justify="center">
        <Col style={{ color: markPriceColor }}>
          {markPrice > previousMarkPrice && <ArrowUpOutlined style={{ marginRight: 5 }} />}
          {markPrice < previousMarkPrice && <ArrowDownOutlined style={{ marginRight: 5 }} />}
          {formattedMarkPrice || '----'}
        </Col>
      </MarkPriceTitle>
    )
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps, ['markPrice']),
)

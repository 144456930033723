import fetchRetry from 'fetch-retry'
import {
  DexlabIdoMarket,
  PreOrderRequest,
  DexlabIdoMarketDetail,
  OrderConfirmRequest,
  PreOrderResponse,
  OrderConfirmResponse,
  PreOrderUpdateRequest,
  MyOrderItemResponse,
  MyOrderDetailResponse,
  DexlabIdoMarketTransactionResponse,
  DexlabIdoFaqResponse,
  MyIdoWhitelistResponse,
} from './../types'
import { DEXLAB_API_HOST } from '../../application'

export default class DexLabIdoApi {
  static V1_BASE_HOST: string = `${DEXLAB_API_HOST}/v1/ido`
  static V2_BASE_HOST: string = `${DEXLAB_API_HOST}/v2/ido`

  static async getBoolean(path: string, version: 'V1' | 'V2' = 'V1') {
    try {
      const response = await fetch(`${version === 'V2' ? this.V2_BASE_HOST : this.V1_BASE_HOST}` + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success
      }
    } catch (err: any) {
      console.log(`Error [GET] fetching from DexLabIdoApi API ${path}: ${err}`)
    }
    return false
  }

  static async get(path: string, version: 'V1' | 'V2' = 'V1') {
    try {
      const response = await fetch(`${version === 'V2' ? this.V2_BASE_HOST : this.V1_BASE_HOST}` + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error [GET] fetching from DexLabIdoApi API ${path}: ${err}`)
    }
    return null
  }

  static async post(path: string, body: any, version: 'V1' | 'V2' = 'V1') {
    try {
      const response = await fetch(`${version === 'V2' ? this.V2_BASE_HOST : this.V1_BASE_HOST}` + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      } else {
        const responseJson = await response.json()
        throw new Error(responseJson.message)
      }
    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  static async put(path: string, body: any, version: 'V1' | 'V2' = 'V1') {
    try {
      const response = await fetch(`${version === 'V2' ? this.V2_BASE_HOST : this.V1_BASE_HOST}` + path, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error [PUT] fetching from DexLabIdoApi API ${path}: ${err}`)
    }
    return null
  }

  static async retryPut(path: string, body: any, version: 'V1' | 'V2' = 'V1') {
    // 실패시 1.5초 단위로 100번까지 재시도
    try {
      const customFetch = fetchRetry(fetch)
      const response = await customFetch(`${version === 'V2' ? this.V2_BASE_HOST : this.V1_BASE_HOST}` + path, {
        retryOn: [400, 500, 502],
        retries: 100,
        retryDelay: 1500,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error [PUT] fetching from DexLabIdoApi API ${path}: ${err}`)
    }
    return null
  }

  // IDO FAQ 조회
  static async getIdoFaqList(lang?: string): Promise<DexlabIdoFaqResponse[] | null | undefined> {
    return DexLabIdoApi.get(`/faq${lang ? `?lang=${lang}` : ''}`)
  }

  // IDO 마켓리스트 조회(V2)
  static async getIdoMarketList(): Promise<DexlabIdoMarket[] | null | undefined> {
    return DexLabIdoApi.get(`/markets`, 'V2')
  }

  // 특정 IDO 마켓정보 조회
  static async getIdoMarket(marketId, ownerPublicKey?): Promise<DexlabIdoMarketDetail | undefined> {
    return DexLabIdoApi.get(`/markets/${marketId}${ownerPublicKey ? `?ownerPublicKey=${ownerPublicKey}` : ''}`)
  }

  // 특정 마켓 트렌젝션 내역 조회
  static async getIdoMarketTransactions(marketId): Promise<DexlabIdoMarketTransactionResponse[] | null | undefined> {
    return DexLabIdoApi.get(`/markets/${marketId}/transactions`)
  }

  // IDO 검증 및 요청
  static async preOrder(marketId: string, request: PreOrderRequest): Promise<PreOrderResponse | null | undefined> {
    return DexLabIdoApi.post(`/sale/${marketId}/pre-order`, request)
  }

  // tx id 업데이트
  static async updatePreOrderTxId(
    marketId: string,
    request: PreOrderUpdateRequest,
  ): Promise<PreOrderResponse | null | undefined> {
    return DexLabIdoApi.put(`/sale/${marketId}/pre-order`, request)
  }

  // 전송승인(TX ID)
  static async orderConfirm(
    orderId: string,
    request: OrderConfirmRequest,
  ): Promise<OrderConfirmResponse | null | undefined> {
    return DexLabIdoApi.retryPut(`/sale/${orderId}/confirm`, request)
  }

  // 내 주문리스트 조 회
  static async myOrders(ownerPublicKey: string): Promise<MyOrderItemResponse[]> {
    return DexLabIdoApi.get(`/sale/${ownerPublicKey}/my-orders`)
  }

  // 특정 주문 조회
  static async myOrder(ownerPublicKey: string, orderId: string): Promise<MyOrderDetailResponse | null | undefined> {
    return DexLabIdoApi.get(`/sale/${ownerPublicKey}/my-orders/${orderId}`)
  }

  // whitelist 모든 당첨 조회
  static async getWhiteListWinners(ownerPublicKey: string): Promise<MyIdoWhitelistResponse[]> {
    return DexLabIdoApi.get(`/whitelist/winners/${ownerPublicKey}`)
  }

  // whitelist 특정 마켓 당첨 조회
  static async checkWhiteListWinner(marketId: string, ownerPublicKey: string): Promise<MyIdoWhitelistResponse> {
    return DexLabIdoApi.get(`/whitelist/winners/${ownerPublicKey}/${marketId}`)
  }

  // 당첨 이메일 재전송
  static async resendWinnerEmail(marketId: string, ownerPublicKey: string): Promise<MyIdoWhitelistResponse> {
    return DexLabIdoApi.post(`/whitelist/winners/${ownerPublicKey}/${marketId}/send-email`, {})
  }
}

import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Table, Tag, Space, Avatar, Button, Divider, Alert } from 'antd'
import { SOLANA_EXPLORER_URL } from '../application'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import { notify } from '../utils/notifications'
import { Row } from 'antd'
import { useWallet } from '../utils/wallet'
import WalletConnectGuide from '../componentsv2/WalletConnectGuide'
import DexLabIdoApi from '../utils/client/dexlabIdoApiConnector'
import DexLabIdoClaimApi from '../utils/client/dexlabClaimApiConnector'
import DexLabWhitelistApi from '../utils/client/dexlabWhitelistApiConnector'
import IdoOrderDetailModal from '../componentsv2/IdoOrderDetailModal'
// import DxlSnapshotHistoryModal from '../componentsv2/DxlSnapshotHistoryModal'
import { MyOrderDetailResponse, SaleOrderStatus, DexlabMyApplyWhitelistResponse } from '../utils/types'
import { getTokenSymbolImageUrl } from '../utils/tokens-v2'
import { Spinner } from 'react-bootstrap'
import { numberWithCommasNormal } from '../utils/dexlab-utils'

// IDO가 종료된 화이트리스트는 숨김처리
// 토큰 지급을 받은 구매내역은 숨김처리(열기 버튼 추가)
// 화이트리스트 참여내역 추가
export default function MyIdoListPage() {
  document.title = `My Idos - The best DEX platform on SOLANA.`

  const { t: trText } = useTranslation()
  const { wallet, connected } = useWallet()
  const [isLoading, setIsLoading] = useState(true)
  const [myOrders, setMyOrders] = useState<any[]>([])
  const [myWhitelistWinners, setMyWhitelistWinners] = useState<any[]>([])
  const [myApplyWhitelist, setMyApplyWhitelist] = useState<DexlabMyApplyWhitelistResponse[]>([])
  const [selectOrderId, setSelectOrderId] = useState<string>()
  const [selectOrderInfo, setSelectOrderInfo] = useState<MyOrderDetailResponse>()
  const [orderDetailModal, setOrderDetailModal] = useState(false)
  const [isClaimLoading, setIsClaimLoading] = useState(false)

  // const [dxlSnapshotHistoryModal, setDxlSnapshotHistoryModal] = useState(false)
  // const [selectMyApplyWhitelist, setSelectMyApplyWhitelist] = useState<DexlabMyApplyWhitelistResponse | null>(null)
  // const [sendEmailCount, setSendEmailCount] = useState(0)

  // async function handleResendEmail(marketId) {
  //   if (sendEmailCount > 10) {
  //     window.alert(`Too many email resend requests.\nYour IP may be blocked if you are making persistent requests.`)
  //     return
  //   }
  //   if (wallet) {
  //     const confirm = window.confirm(
  //       `If you retry multiple times, your account may be suspended.\nIf you cannot receive the email, please check the IDO participation email and spam.\n\nWould you like to receive the winning email again?`,
  //     )
  //     if (confirm) {
  //       const response = await DexLabIdoApi.resendWinnerEmail(marketId, wallet?.publicKey.toBase58())
  //       if (response) {
  //         setSendEmailCount(sendEmailCount + 1)
  //         window.alert(`Has been sent again.`)
  //       }
  //     }
  //   }
  // }

  // function onCloseDxlSnapshotModal() {
  //   setDxlSnapshotHistoryModal(false)
  // }

  const myApplyWhiteListColumns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (key) => `${key + 1}`,
    },
    {
      title: 'Name',
      render: (item) => (
        <>
          {/* <Link to={`/v2/ido/${item.whitelist.id}`}> */}
          <span>{item.whitelist.name}</span>
          {/* </Link> */}
        </>
      ),
    },
    {
      title: 'Whitelist Date(UTC)',
      render: (item) => (
        <>
          <span>
            {item.whitelist.startDate} ~ {item.whitelist.endDate}
          </span>
        </>
      ),
    },
    // {
    //   title: 'Avg Dxl Snapshots',
    //   render: (item) => (
    //     <>
    //       <span
    //         style={{ cursor: 'pointer' }}
    //         onClick={() => {
    //           setDxlSnapshotHistoryModal(true)
    //           setSelectMyApplyWhitelist(item)
    //         }}
    //       >
    //         {_.size(item.dailySnapshots)} snapshots
    //       </span>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Avg Dxl Quanity',
    //   render: (item) => (
    //     <>
    //       <span>
    //         <Tag color="green">{item.dxlQuantityText}</Tag>
    //       </span>
    //     </>
    //   ),
    // },
    {
      title: 'Apply Date(UTC)',
      render: (item) => (
        <>
          <span>{item.createdAt}</span>
        </>
      ),
    },
  ]

  const myWhiteListcolumns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (key) => `${key + 1}`,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: ({ logoUrl, quote, name }) => (
        <>
          <Avatar.Group>
            <Avatar size={25} style={{ marginRight: '7px', marginBottom: '5px' }} src={logoUrl} />
            <Avatar size={25} style={{ marginRight: '7px', marginBottom: '5px' }} src={getTokenSymbolImageUrl(quote)} />
          </Avatar.Group>
          <span>{name}</span>
        </>
      ),
    },
    {
      title: 'Pair',
      dataIndex: 'pair',
      key: 'pair',
      render: ({ base, quote }) => `${base}-${quote}`,
    },
    {
      title: 'Sale start',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (startDate) => startDate,
    },
    {
      title: 'Sale end',
      key: 'endDate',
      dataIndex: 'endDate',
      render: (endDate) => endDate,
    },
    {
      title: 'Allocation quantity',
      key: 'purchaseAvailableQuantity',
      dataIndex: 'purchaseAvailableQuantity',
      render: ({ purchaseAvailableQuantity, base }) => (
        <b>
          {numberWithCommasNormal(parseFloat(`${purchaseAvailableQuantity}`))} {base}
        </b>
      ),
    },
    // {
    //   title: 'Winner Round',
    //   key: 'stage',
    //   dataIndex: 'stage',
    //   render: (stage) => `Round ${stage}`,
    // },
    // {
    //   title: 'Kyc certification',
    //   dataIndex: 'isKycCertification',
    //   key: 'isKycCertification',
    //   render: (isKycCertification) => (isKycCertification ? 'Completed' : 'Uncertified'),
    // },
    // {
    //   title: 'Winning email',
    //   key: 'resendEmail',
    //   render: ({ marketId }) => (
    //     <Space size="middle">
    //       <Button
    //         onClick={() => {
    //           handleResendEmail(marketId)
    //         }}
    //       >
    //         Resend
    //       </Button>
    //     </Space>
    //   ),
    // },
  ]

  const myIdocolumns = [
    {
      title: 'OrderId',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (orderId) => orderId,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: ({ logoUrl, quote, name }) => (
        <>
          <Avatar.Group>
            <Avatar size={25} style={{ marginRight: '7px', marginBottom: '5px' }} src={logoUrl} />
            <Avatar size={25} style={{ marginRight: '7px', marginBottom: '5px' }} src={getTokenSymbolImageUrl(quote)} />
          </Avatar.Group>
          <span>{name}</span>
        </>
      ),
    },
    {
      title: 'Pair',
      dataIndex: 'pair',
      key: 'pair',
      render: ({ base, quote }) => `${base}-${quote}`,
    },
    {
      title: 'Purchase quantity',
      dataIndex: 'withdrawBaseTokenAmount',
      key: 'withdrawBaseTokenAmount',
      render: ({ withdrawBaseTokenAmount, base }) => `${parseFloat(withdrawBaseTokenAmount)}${base}`,
    },
    // {
    //   title: 'Claim Status',
    //   dataIndex: 'depositCompleted',
    //   key: 'depositCompleted',
    //   render: ({ depositCompleted, depositCompletedTxid }) =>
    //     depositCompleted ? (
    //       <a
    //         style={{ color: '#FFFFFF' }}
    //         href={`${SOLANA_EXPLORER_URL}/tx/${depositCompletedTxid}`}
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Complete
    //       </a>
    //     ) : (
    //       'To be distributed'
    //     ),
    // },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: ({ status, withdrawMessage }) => {
        let color = ''
        if (status === SaleOrderStatus.COMPLETE) {
          color = 'blue'
          return <Tag color={color}>{trText('ido_order_status_distribution_complete')}</Tag>
        } else if (status === SaleOrderStatus.PROCEEDING) {
          color = 'lime'
          return <Tag color={color}>{withdrawMessage ? `Distributed after IDO ends` : status}</Tag>
        } else if (status === SaleOrderStatus.PENDING) {
          color = 'lime'
          return <Tag color={color}>{trText('ido_order_status_paid_complete')}</Tag>
        } else if (status === SaleOrderStatus.RETRY_TXID_CONFIRM) {
          color = 'red'
          return <Tag color={color}>{trText('ido_order_status_error')}</Tag>
        } else if (status === SaleOrderStatus.CANCEL_WAITING) {
          return <Tag color={color}>{`Bad transaction, pending cancellation`}</Tag>
        } else {
          color = 'purple'
          return <Tag color={color}>{'Community Inquiries'}</Tag>
        }
      },
    },
    // {
    //   title: 'Date',
    //   key: 'createdAt',
    //   dataIndex: 'createdAt',
    //   render: (createdAt) => createdAt,
    // },
    {
      title: 'Action',
      dataIndex: 'orderInfo',
      key: 'orderInfo',
      render: ({ orderId, marketId, withdrawStatus, depositCompleted, depositCompletedTxid, withdrawMessage }) => {
        console.log(`withdrawMessage: ${withdrawMessage}`)
        return (
          <Space size="middle">
            <Button
              type="default"
              onClick={() => {
                setSelectOrderId(orderId)
                setOrderDetailModal(true)
              }}
            >
              Order Detail
            </Button>
            <Button
              style={{ width: '120px' }}
              type={withdrawStatus !== 'WAITING' ? 'primary' : 'default'}
              onClick={() => {
                if (withdrawMessage) {
                  setSelectOrderId(orderId)
                  setOrderDetailModal(true)
                } else {
                  if (depositCompleted && depositCompletedTxid) {
                    window.open(`${SOLANA_EXPLORER_URL}/tx/${depositCompletedTxid}`)
                  } else if (withdrawStatus === 'PROCEEDING_WITHDRAW') {
                    requestClaimOrder({ marketId, orderId })
                  }
                }
              }}
            >
              {isClaimLoading ? (
                <Spinner size="sm" animation="grow" />
              ) : depositCompleted ? (
                'Completed'
              ) : withdrawStatus === 'PROCEEDING_WITHDRAW' ? (
                'Claim'
              ) : withdrawMessage ? (
                'Notice check'
              ) : (
                'Claim Wait'
              )}
            </Button>
          </Space>
        )
      },
    },
  ]

  /* eslint-disable */
  useEffect(() => {
    if (connected) {
      getMyOrders()
      getMyApplyWhitelist()
      getWhiteListWinners()
    }
  }, [connected])

  useEffect(() => {
    async function getOrder() {
      const response = await DexLabIdoApi.myOrder(wallet!!.publicKey.toBase58(), selectOrderId!!)
      if (response) {
        setSelectOrderInfo(response)
      }
    }

    if (connected && selectOrderId && wallet) {
      getOrder()
    }
  }, [selectOrderId])

  async function requestClaimOrder({ marketId, orderId }) {
    if (connected && wallet) {
      try {
        const confirm = window.confirm(`Request a claim?\n(It may take a few minutes for the token to be transferred.)`)
        if (!confirm) {
          notify({
            message: `Cancel claim`,
            type: 'error',
          })
          return
        }

        setIsClaimLoading(true)
        const response = await DexLabIdoClaimApi.claimOrder(marketId, wallet.publicKey.toBase58(), orderId)
        // const response = await DexLabIdoClaimApi.claimOrder('0919b4cd-6cbb-46df-b692-013ec006bc70', 'GiFPrCCZD87nLG17tCgNvrXsP1tuhRN6SNjb5ojUrZ1i', '6301-696434-4404')
        if (response) {
          if (response.depositStatus === 'COMPLETE') {
            notify({
              message: `A claim has been made. It may take a few minutes to process.`,
              txid: response.txId,
              type: 'success',
            })
          } else {
            notify({
              message: `Token transfer transaction failed. Please try again later`,
              type: 'error',
            })
          }
        } else {
          notify({
            message: `A claim error has occurred.`,
            type: 'error',
          })
        }
        setIsClaimLoading(false)
        // reload
        getMyOrders()
      } catch (e: any) {
        notify({
          message: e.message ?? `A claim error has occurred.`,
          type: 'error',
        })
        setIsClaimLoading(false)
      }
    }
  }

  async function getMyApplyWhitelist() {
    if (connected && wallet) {
      setIsLoading(true)
      const response = await DexLabWhitelistApi.getMyApplyWhitelists(wallet.publicKey.toBase58())
      if (response) {
        if (!_.isEmpty(response)) {
          setMyApplyWhitelist(response)
        }
      }
      setIsLoading(false)
    }
  }

  async function getMyOrders() {
    if (connected && wallet) {
      setIsLoading(true)
      const response = await DexLabIdoApi.myOrders(wallet.publicKey.toBase58())
      if (response) {
        if (!_.isEmpty(response)) {
          // notify({ message: `There are ${_.size(response)} order information.`, type: 'info' })
          const results = response.map((r, idx) => {
            return {
              key: idx,
              name: { logoUrl: r.logoUrl, name: r.name, quote: r.quote },
              pair: { base: r.base, quote: r.quote },
              orderId: r.orderId,
              withdrawBaseTokenAmount: { withdrawBaseTokenAmount: r.withdrawBaseTokenAmount, base: r.base },
              depositCompleted: { depositCompleted: r.depositCompleted, depositCompletedTxid: r.depositCompletedTxid },
              orderInfo: {
                marketId: r.marketId,
                orderId: r.orderId,
                withdrawStatus: r.withdrawStatus,
                withdrawMessage: r.withdrawMessage,
                depositCompleted: r.depositCompleted,
                depositCompletedTxid: r.depositCompletedTxid,
              },
              status: { status: r.status, withdrawMessage: r.withdrawMessage },
              // createdAt: r.createdAt,
            }
          })
          setMyOrders(results.reverse())
        }
      }
    }
    setIsLoading(false)
  }

  async function getWhiteListWinners() {
    if (connected && wallet) {
      setIsLoading(true)
      const response = await DexLabIdoApi.getWhiteListWinners(wallet.publicKey.toBase58())
      if (response) {
        if (!_.isEmpty(response)) {
          // notify({
          //   message: `There is ${_.size(response)} Whitelist IDO Sale that you can participate in.`,
          //   type: 'info',
          // })
          const results = response.map((r, idx) => {
            return {
              key: idx,
              name: {
                externalId: r.market.externalId,
                logoUrl: r.market.logoUrl,
                name: r.market.name,
                quote: r.market.quote,
              },
              pair: { base: r.market.base, quote: r.market.quote },
              // isKycCertification: r.isKycCertification,
              purchaseAvailableQuantity: {
                purchaseAvailableQuantity: r.purchaseAvailableQuantity,
                base: r.market.base,
              },
              minQuantity: r.market.minQuantity,
              maxQuantity: r.market.maxQuantity,
              startDate: r.market.startDate,
              endDate: r.market.endDate,
              marketId: r.market.externalId,
              stage: r.stage,
            }
          })
          setMyWhitelistWinners(results)
        }
      }
    }
    setIsLoading(false)
  }

  async function refreshData() {
    getMyOrders()
    getMyApplyWhitelist()
    getWhiteListWinners()
  }

  if (!connected) {
    return (
      <Row
        justify="center"
        style={{
          marginTop: '10%',
        }}
      >
        <WalletConnectGuide />
      </Row>
    )
  }

  return (
    // @ts-ignore
    <FloatingElement style={{ flex: 1, paddingTop: 6 }}>
      <div className="col-xl-12 col-lg-12 col-md-12 col-xxl-12" style={{ marginBottom: '6px', textAlign: 'center' }}>
        <Alert
          type="success"
          message={
            <>
              Dexlab platform is open! This page service is available in Dexlab platform.{' '}
              <a href="https://www.dexlab.space/my-ido" target="_blank" rel="noopener noreferrer">
                Go to Dexlab platform
              </a>
            </>
          }
        />
      </div>
      <Button onClick={refreshData} loading={isLoading}>
        Refresh
      </Button>
      {!_.isEmpty(myOrders) ? (
        <>
          <div style={{ height: '60px', textAlign: 'center', fontSize: '25px' }}>
            <Divider>My Orders</Divider>
          </div>
          <Table loading={isLoading} columns={myIdocolumns} dataSource={myOrders.reverse()} />
          <Tag color={'lime'}>{trText('ido_order_status_paid_complete')}</Tag>:{' '}
          {trText('ido_order_status_paid_complete_info')}
          <br />
          <Tag color={'blue'}>{trText('ido_order_status_distribution_complete')}</Tag>:{' '}
          {trText('ido_order_status_distribution_complete_info')}
          <br />
          <Tag color={'red'}>{trText('ido_order_status_error')}</Tag>: {trText('ido_order_status_error_info')}
          <br />
        </>
      ) : null}

      <div
        style={{
          height: '60px',
          textAlign: 'center',
          marginTop: !_.isEmpty(myOrders) ? '100px' : '',
          fontSize: '25px',
        }}
      >
        <Divider>
          🏆️ Whitelist winner 🏆️
          <br />
          <span style={{ fontSize: '13px', color: '#646464' }}>Winners within the last 1 month are displayed.</span>
        </Divider>
      </div>

      {!_.isEmpty(myWhitelistWinners) ? (
        <Table loading={isLoading} columns={myWhiteListcolumns} dataSource={myWhitelistWinners.reverse()} />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '30px' }}>There is no winning history.</div>
      )}

      <div
        style={{
          height: '60px',
          textAlign: 'center',
          marginTop: !_.isEmpty(myOrders) ? '100px' : '',
          fontSize: '25px',
        }}
      >
        <Divider>
          🚀 Whitelist Participation History 🚀
          <br />
          <span style={{ fontSize: '13px', color: '#646464' }}>Participation history within 1 month is inquired.</span>
        </Divider>
      </div>

      {!_.isEmpty(myApplyWhitelist) ? (
        <Table
          loading={isLoading}
          columns={myApplyWhiteListColumns}
          dataSource={myApplyWhitelist.map((item, idx) => {
            return {
              key: idx,
              ...item,
            }
          })}
        />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '30px' }}>There is no participation history.</div>
      )}

      {connected && wallet && selectOrderId && selectOrderInfo && (
        <IdoOrderDetailModal
          orderInfo={selectOrderInfo}
          orderDetailModal={orderDetailModal}
          setOrderDetailModal={setOrderDetailModal}
        />
      )}
      {/* {selectMyApplyWhitelist && dxlSnapshotHistoryModal && (
        <DxlSnapshotHistoryModal myWhitelistApplyInfo={selectMyApplyWhitelist} onClose={onCloseDxlSnapshotModal} />
      )} */}
    </FloatingElement>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import DataTable from '../layout/DataTable'
import _ from 'lodash'
import styled from 'styled-components'
import { Button, Col, Row, Tag } from 'antd'
import { cancelOrder } from '../../utils/send'
import { useWallet } from '../../utils/wallet'
import { useDexlabRpcSendConnection } from '../../utils/connection'
import { notify } from '../../utils/notifications'
import { DeleteOutlined } from '@ant-design/icons'
import { OrderWithMarketAndMarketName } from '../../utils/types'
import { useTranslation } from 'react-i18next'
import NotConnectView from '../../componentsv2/element/NotConnectView'
import { eventCancel } from '../../utils/client/dexlabEventTrackingService'

const CancelButton = styled(Button)`
  color: #f23b69;
  border: 1px solid #f23b69;
`

export default function OpenOrderTable({
  openOrders,
  onCancelSuccess,
  pageSize,
  loading,
  marketFilter,
}: {
  openOrders: OrderWithMarketAndMarketName[] | null | undefined
  onCancelSuccess?: () => void
  pageSize?: number
  loading?: boolean
  marketFilter?: boolean
}) {
  const { t: trText } = useTranslation()

  let { wallet, connected } = useWallet()
  let connection = useDexlabRpcSendConnection()

  const [cancelId, setCancelId] = useState(null)
  const [myOrders, setMyOrders] = useState<any[]>([])
  useEffect(() => {
    if (openOrders && !_.isEmpty(openOrders)) {
      let isUpdate = false
      if (_.isEmpty(myOrders)) {
        isUpdate = true
      } else {
        // 변동사항 체크
        if (_.size(myOrders) !== _.size(openOrders)) {
          isUpdate = true
        }
      }
      if (isUpdate) {
        const dataSource: any[] = []
        openOrders?.forEach((order) => {
          const findOrder = dataSource.find((f) => f.orderId.toString() === order.orderId.toString())
          if (!findOrder) {
            dataSource.push({
              ...order,
              key: order.orderId.toString(),
            })
          }
        })
        setMyOrders(dataSource)
      }
    }
  }, [openOrders])

  async function cancel(order) {
    setCancelId(order?.orderId)
    try {
      const cancelResultTxID = await cancelOrder({
        order,
        market: order.market,
        connection,
        wallet: wallet!!,
      })
      eventCancel({
        x: cancelResultTxID, // txId
        o: order?.orderId,
        w: wallet!!.publicKey.toBase58(),
        p: order?.price ?? 0,
        s: order?.size ?? 0,
        t: order?.side ?? '-',
        m: order?.market?.address.toBase58() ?? '-',
      })
    } catch (e: any) {
      notify({
        message: 'Error cancelling order',
        description: e.message,
        type: 'error',
      })
      return
    } finally {
      setCancelId(null)
    }
    onCancelSuccess && onCancelSuccess()
  }

  const marketFilters = [...(openOrders ?? []).map((orderInfos) => orderInfos.marketName)].map((marketName) => {
    return { text: marketName, value: marketName }
  })

  const columns = [
    {
      title: trText('market'),
      dataIndex: 'marketName',
      key: 'marketName',
      filters: marketFilter ? marketFilters : undefined,
      onFilter: (value, record) => record.marketName.indexOf(value) === 0,
    },
    {
      title: trText('order_side'),
      dataIndex: 'side',
      key: 'side',
      render: (side) => (
        <Tag color={side === 'buy' ? '#26A69A' : '#F6465D'} style={{ fontWeight: 700 }}>
          {side === 'buy' ? trText('order_buy') : trText('order_sell')}
        </Tag>
      ),
      sorter: (a, b) => {
        if (a.side === b.side) {
          return 0
        } else if (a.side === 'buy') {
          return 1
        } else {
          return -1
        }
      },
      showSorterTooltip: false,
    },
    {
      title: trText('order_size'),
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => b.size - a.size,
      showSorterTooltip: false,
    },
    {
      title: trText('order_price'),
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => b.price - a.price,
      showSorterTooltip: false,
    },
    {
      key: 'orderId',
      render: (order) => (
        <div style={{ textAlign: 'right' }}>
          <CancelButton
            icon={<DeleteOutlined />}
            onClick={() => cancel(order)}
            loading={cancelId + '' === order?.orderId + ''}
          >
            {trText('order_cancel')}
          </CancelButton>
        </div>
      ),
    },
  ]

  return (
    <Row>
      <Col span={24}>
        {connected ? (
          <>
            <DataTable
              emptyLabel={trText('empty_open_order')}
              dataSource={myOrders}
              columns={columns}
              pagination={true}
              pageSize={pageSize ? pageSize : 5}
              loading={loading !== undefined && loading}
            />
          </>
        ) : (
          <NotConnectView />
        )}
      </Col>
    </Row>
  )
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Button, Modal, Steps, Result } from 'antd'
import {
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
  FileProtectOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { TradeEntry } from './trade'
import { DEXLAB_TELEGRAM, DEXLAB_TWITTER } from '../utils/dexlab-utils'

const { Step } = Steps

enum PROCESS_STEP {
  NONE = 0,
  STEP_1 = 1,
  STEP_2 = 2,
  DONE = 4,
  ERROR = 9,
}

export default function BuyIdoTokenModal({
  saleMarketInfo,
  onIdoSubmit,
  onClose,
  visible,
  loadingStep,
  errorMessage,
  whiteListWinner,
  isLoading,
}) {
  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(visible)
  const isResult = loadingStep === PROCESS_STEP.DONE || loadingStep === PROCESS_STEP.ERROR
  useEffect(() => {
    setModalVisible(visible)
  }, [visible])

  let resultTitle = ''
  let resultSubTitle = ''
  let extraButtons: any[] = []

  if (isResult) {
    const error: any = (
      <>
        <br />
        <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}>{errorMessage}</span>
        <br />
        <br />
        If the problem persists, please contact Dexlab
      </>
    )

    resultTitle = loadingStep === PROCESS_STEP.DONE ? `${saleMarketInfo.base} token purchase is complete!` : 'Oops!'
    resultSubTitle =
      loadingStep === PROCESS_STEP.DONE ? (
        <>Please refer to the token information and community notice for the token payment schedule.</>
      ) : (
        error
      )

    if (loadingStep === PROCESS_STEP.DONE) {
      extraButtons = [
        <Button
          key="telegram"
          onClick={() => {
            window.open(DEXLAB_TELEGRAM)
          }}
        >
          Telegram
        </Button>,
        <Button
          key="twitter"
          onClick={() => {
            window.open(DEXLAB_TWITTER)
          }}
        >
          Twitter
        </Button>,
        <Button
          type="primary"
          key="my-token-sale"
          onClick={() => {
            history.push(`/v2/my/ido`)
          }}
        >
          My Token Sale
        </Button>,
      ]
    } else {
      extraButtons = [
        <Button
          type="primary"
          key="telegram"
          onClick={() => {
            window.open(DEXLAB_TELEGRAM)
          }}
        >
          Contact Dexlab Community
        </Button>,
      ]
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        visible={visible}
        onCancel={() => onClose()}
        footer={[
          <Button key="close" onClick={onClose}>
            Cancel
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <Card
            className="exchange-card"
            style={{
              maxWidth: '500px',
              minWidth: '450px',
              flex: 1,
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '30px',
              marginTop: '30px',
            }}
            headStyle={{ padding: 0 }}
            bodyStyle={{ position: 'relative' }}
          >
            {isResult ? (
              <Result
                style={{ marginTop: '35px' }}
                status={loadingStep === PROCESS_STEP.DONE ? 'success' : 'error'}
                title={resultTitle}
                subTitle={resultSubTitle}
                extra={extraButtons}
              />
            ) : (
              modalVisible && (
                <TradeEntry
                  saleMarketInfo={saleMarketInfo}
                  purchaseAvailableQuantity={whiteListWinner.purchaseAvailableQuantity}
                  isLoading={isLoading}
                  onSubmit={onIdoSubmit}
                  onClose={onClose}
                />
              )
            )}

            {loadingStep > PROCESS_STEP.NONE ? (
              <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                <div style={{ width: '90%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                  <Steps>
                    <Step
                      status={
                        loadingStep === PROCESS_STEP.STEP_1
                          ? 'process'
                          : loadingStep > PROCESS_STEP.STEP_1
                          ? 'finish'
                          : 'wait'
                      }
                      title="Confirm"
                      icon={loadingStep === PROCESS_STEP.STEP_1 ? <LoadingOutlined /> : <SolutionOutlined />}
                    />
                    <Step
                      status={
                        loadingStep === PROCESS_STEP.STEP_2
                          ? 'process'
                          : loadingStep > PROCESS_STEP.STEP_2
                          ? 'finish'
                          : 'wait'
                      }
                      title="Valid"
                      icon={loadingStep === PROCESS_STEP.STEP_2 ? <LoadingOutlined /> : <FileProtectOutlined />}
                    />
                    <Step
                      status={loadingStep === PROCESS_STEP.DONE ? 'finish' : 'wait'}
                      title="Done"
                      icon={<SmileOutlined />}
                    />
                    {loadingStep === PROCESS_STEP.ERROR ? (
                      <Step status="error" title="Error" icon={<WarningOutlined />} />
                    ) : null}
                  </Steps>
                </div>
              </div>
            ) : null}
          </Card>
        </div>
      </Modal>
    </>
  )
}

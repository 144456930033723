import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { MarketProvider, getTradePageUrl } from '../utils/dex-markets'
import { DexLabMarketV2Info } from '../utils/types'
import DexlabMarketAPI from '../utils/client/dexlabMarketApiConnector'
import ConvertForm from '../componentsv2/ConvertForm'
import { Row, Col } from 'antd'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`

export default function ConvertPage() {
  const { marketAddress } = useParams()

  const [allV2Markets, setAllV2Markets] = useState<DexLabMarketV2Info[]>([])

  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress))
    }
  }, [marketAddress])
  const history = useHistory()
  function setMarketAddress(address) {
    history.push(getTradePageUrl(address))
  }

  useEffect(() => {
    async function getAllMarkets() {
      const response = await DexlabMarketAPI.getAllMarketsV2()
      if (response) {
        setAllV2Markets(response)
      } else {
        history.push(`/error`)
      }
    }
    getAllMarkets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper style={{ flex: 1, paddingTop: 10 }}>
      <Row justify="center">
        <Col>
          {!_.isEmpty(allV2Markets) && (
            <MarketProvider
              allV2Markets={allV2Markets}
              marketAddress={marketAddress}
              setMarketAddress={setMarketAddress}
            >
              <ConvertForm />
            </MarketProvider>
          )}
        </Col>
      </Row>
    </Wrapper>
  )
}

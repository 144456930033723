/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useContext } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { Menu, Select, Popover, Button } from 'antd'
import { DownOutlined, MenuOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { getTradePageUrl, useWalletSolBalance } from '../../utils/dex-markets'
import { useWallet } from '../../utils/wallet'
import WalletConnect from '../WalletConnect'
import logo from '../../assets/dexlab_symbol.svg'
// import darkModeIcon from '../../assets/icon_dark_mode.svg'
// import lightModeIcon from '../../assets/icon_light_mode.svg'
import { useTranslation } from 'react-i18next'
// import { usePreferences } from '../../utils/preferences'
import { ThemeContext, isDark } from '../../utils/Contexts/ThemeContext'
import LinkAddress from '../../componentsv2/LinkAddress'
// import { CURRENCY_KEY } from '../../utils/types'

export const LANGUAGES = [
  // { name: '한국어', value: 'ko', icon: 'flag-icon-kr' },
  // { name: 'English', value: 'en', icon: 'flag-icon-us' },
  { name: '한국어', value: 'ko', icon: undefined },
  { name: 'English', value: 'en', icon: undefined },
  { name: 'Spanish', value: 'es', icon: undefined },
  { name: '简体中文', value: 'zh_cn', icon: undefined },
  { name: '繁體中文(台灣)', value: 'zh_tw', icon: undefined },
]

// export const CURRENCYS = [
//   { name: 'Currency', value: 'USD' },
//   { name: 'KRW', value: 'KRW' },
// ]

const HideMobileDiv = styled.div`
  @media only screen and (max-width: 1280px) {
    display: none;
  }
`

const ShowMobileDiv = styled.div`
  display: none;
  @media only screen and (max-width: 1280px) {
    display: block;
  }
`
const MenuToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    <div>
      <MenuOutlined style={{ fontSize: '20px' }} />
    </div>
  </div>
))

const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    <div className="profile_log">
      <div className="user">
        <span className="thumb">
          <i className="mdi mdi-account" />
        </span>
        <span className="arrow">
          <i className="la la-angle-down" />
        </span>
      </div>
    </div>
  </div>
))

function TradeHeader() {
  const [theme] = useContext(ThemeContext)
  const { t: trText, i18n } = useTranslation()
  const [selectLanguage, setSelectLanguage] = useState(i18n.language)
  // const [selectCurrency, setSelectCurrency] = useState(localStorage.getItem(CURRENCY_KEY) ?? 'Currency')

  const solBalance = useWalletSolBalance()
  const { connected, wallet, providerName, providerUrl } = useWallet()

  const walletPublicKey = wallet?.publicKey?.toBase58()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setSelectLanguage(localStorage.getItem('language') ?? i18n.language)
  }, [])

  useEffect(() => {
    i18n.changeLanguage(selectLanguage)
    localStorage.setItem('language', selectLanguage)
  }, [i18n, selectLanguage])

  // useEffect(() => {
  //   if (localStorage.getItem(CURRENCY_KEY) !== selectCurrency) {
  //     localStorage.setItem(CURRENCY_KEY, selectCurrency)
  //     history.go(0)
  //   }
  // }, [selectCurrency])

  const handleClick = useCallback(
    (e) => {
      history.push(e.key)
    },
    [history],
  )

  const tradePageUrl = location.pathname.startsWith('/market/') ? location.pathname : getTradePageUrl()

  // function depositFtxForSOL() {
  //   if (wallet && connected) {
  //     const depositCoin = 'SOL'
  //     const popUrl = `https://ftx.com/pay/request?coin=${depositCoin}&address=${wallet.publicKey.toString()}&tag=&wallet=sol&memoIsRequired=true&memo=Deposit via Dexlab site&fixedWidth=true`
  //     const popOption = 'width=450, height=700, resizable=no, scrollbars=yes, status=no'
  //     window.open(popUrl, '', popOption)
  //   }
  // }

  return (
    <>
      <div className="header dashboard" style={{ height: '90px' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <nav
                style={{ height: '100%' }}
                className="navbar navbar-expand-lg navbar-light px-0 justify-content-start"
              >
                <ShowMobileDiv>
                  <Dropdown className="profile_log" style={{ paddingRight: '20px' }}>
                    <Dropdown.Toggle as={MenuToggle} />
                    <Dropdown.Menu title="" style={{ marginTop: '15px', minWidth: '100vw' }}>
                      <div>
                        <div className="row">
                          <div className="col-12">
                            <Link
                              to={tradePageUrl}
                              className="dropdown-item"
                              style={{ fontSize: '16px', color: '#ffffff' }}
                            >
                              OpenBook
                            </Link>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {/* <Link to={'/ido'} className="dropdown-item" style={{ fontSize: '16px', color: '#ffffff' }}>
                              {trText('token_sale')}
                            </Link> */}
                            <a
                              href={'https://www.dexlab.space/launchpad'}
                              rel="noopener noreferrer"
                              className="dropdown-item"
                              style={{ fontSize: '16px', color: '#ffffff' }}
                            >
                              {trText('token_sale')}
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <a
                              href={'https://www.dexlab.space/swap'}
                              rel="noopener noreferrer"
                              className="dropdown-item"
                              style={{ fontSize: '16px', color: '#ffffff' }}
                            >
                              {trText('swap')}
                            </a>
                            {/* <Link to={'/swap'} className="dropdown-item" style={{ fontSize: '16px', color: '#ffffff' }}>
                              {trText('swap')}
                            </Link> */}
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-12">
                            <a
                              href={'https://www.dexlab.space/staking'}
                              rel="noopener noreferrer"
                              className="dropdown-item"
                              style={{ fontSize: '16px', color: '#ffffff' }}
                            >
                              Staking
                            </a>
                          </div>
                        </div> */}
                        <div style={{ borderTop: '1px solid rgb(0,0,0)' }}></div>
                        <div className="row">
                          <div className="col-6">
                            <Link
                              to={'/minting-lab/markets'}
                              className="dropdown-item"
                              style={{ fontSize: '16px', color: '#ffffff' }}
                            >
                              List Your Market
                            </Link>
                          </div>
                          <div className="col-6">
                            <a
                              href={'https://minting.dexlab.space'}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-item"
                              style={{ fontSize: '16px', color: '#ffffff' }}
                            >
                              Minting Lab
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <a
                              href={'https://help.dexlab.space'}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-item"
                              style={{ fontSize: '16px', color: '#ffffff' }}
                            >
                              {trText('help')}
                            </a>
                          </div>
                        </div>
                        <div style={{ borderTop: '1px solid rgb(0,0,0)' }}></div>
                        <div className="row">
                          <div className="col-6">
                            <div
                              className="language"
                              style={{ padding: '5px 20px', color: isDark(theme) ? '' : '#22223e' }}
                            >
                              <Select
                                style={{ color: isDark(theme) ? '' : '#22223e', width: '100%' }}
                                suffixIcon={<DownOutlined style={{ color: isDark(theme) ? '#FFF' : '#000' }} />}
                                onSelect={setSelectLanguage}
                                value={selectLanguage}
                              >
                                {LANGUAGES.map(({ name, value, icon }) => (
                                  <Select.Option value={value} key={value}>
                                    {icon && <i className={`flag-icon ${icon}`} />} {name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </ShowMobileDiv>
                <span
                  style={{ cursor: 'pointer' }}
                  className="navbar-brand"
                  onClick={() => {
                    window.location.href = 'https://www.dexlab.space'
                  }}
                >
                  <img style={{ width: '150px', height: '65px' }} src={logo} alt="Dexlab OpenBook DEX" />
                </span>
                <HideMobileDiv style={{ flex: '1 1' }}>
                  <Menu
                    mode="horizontal"
                    onClick={handleClick}
                    selectedKeys={[location.pathname]}
                    style={{
                      color: isDark(theme) ? '#FFFFFF' : '#22223e',
                      borderBottom: 'none',
                      backgroundColor: 'transparent',
                      display: 'flex',
                      alignItems: 'flex-end',
                      flex: 1,
                    }}
                  >
                    <Menu.Item key={tradePageUrl}>{trText('trade')}</Menu.Item>
                    {/* <Menu.Item key="/ido">{trText('token_sale')}</Menu.Item> */}
                    <Menu.Item key={'/ido'}>
                      <a href="https://www.dexlab.space/launchpad" rel="noopener noreferrer">
                        {trText('token_sale')}
                      </a>
                    </Menu.Item>
                    <Menu.Item key={'/'}>
                      <a href="https://www.dexlab.space/swap" rel="noopener noreferrer">
                        {trText('swap')}
                      </a>
                    </Menu.Item>
                    {connected && (
                      <Menu.Item key="/help-06">
                        <a href="https://www.dexlab.space/my/balances" rel="noopener noreferrer">
                          {trText('tab_balances')}
                        </a>
                      </Menu.Item>
                    )}
                    <Menu.Item key="/markets">
                      <a href="https://www.dexlab.space/markets" rel="noopener noreferrer">
                        Markets
                      </a>
                    </Menu.Item>
                    <Menu.Item key="/mintinglab/spl-token">
                      <a href="https://www.dexlab.space/mintinglab/spl-token" rel="noopener noreferrer">
                        Minting
                      </a>
                    </Menu.Item>
                    {/* <Menu.Item key="/stake">
                      <a href="https://www.dexlab.space/staking" rel="noopener noreferrer">
                        Staking
                      </a>
                    </Menu.Item> */}
                    <Menu.Item key={'/minting-lab/markets'}>List Your Market</Menu.Item>
                    {/* <Menu.SubMenu
                      key="sub1"
                      title={
                        <>
                          Services <DownOutlined style={{ fontSize: '10px' }} />
                        </>
                      }
                    >
                      <Menu.Item key={'/minting-lab/markets'} icon={<PlusCircleOutlined />}>
                        List Your Market
                      </Menu.Item>
                      <Menu.Item key={'/minting-lab/mintinglab'} icon={<CodeSandboxOutlined />}>
                        <a href="https://minting.dexlab.space" target="_blank" rel="noopener noreferrer">
                          Minting Lab
                        </a>
                      </Menu.Item>
                      <Menu.Item key="/help-06" icon={<PieChartOutlined />}>
                        <a href={'https://www.dexlab.space/analytics'} target="_blank" rel="noopener noreferrer">
                          Analytics
                        </a>
                      </Menu.Item>
                      <Menu.Item key="/help-07" icon={<PieChartOutlined />}>
                        <a
                          href={'https://docs.dexlab.space/api-documentation/rest-api'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          API
                        </a>
                      </Menu.Item>
                    </Menu.SubMenu> */}
                    {/* <Menu.SubMenu
                      key="sub2"
                      title={
                        <>
                          Help <DownOutlined style={{ fontSize: '10px' }} />
                        </>
                      }
                    >
                      <Menu.Item key="/help">
                        <a href={'https://help.dexlab.space'} target="_blank" rel="noopener noreferrer">
                          {trText('help')}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="/help-01">
                        <a href={'https://docs.dexlab.space'} target="_blank" rel="noopener noreferrer">
                          Document
                        </a>
                      </Menu.Item>
                      <Menu.Item key="/help-02">
                        <a
                          href={'https://help.dexlab.space/hc/en-us/sections/4410302317721-DEX-Trade'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Trade FAQ
                        </a>
                      </Menu.Item>
                      <Menu.Item key="/help-03">
                        <a
                          href={'https://help.dexlab.space/hc/en-us/sections/4410677981593-MintingLab'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MintingLab FAQ
                        </a>
                      </Menu.Item>
                      <Menu.Item key="/help-04">
                        <a
                          href={'https://help.dexlab.space/hc/en-us/sections/4413952185625-Staking'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Stake FAQ
                        </a>
                      </Menu.Item>
                      <Menu.Item key="/help-05">
                        <a
                          href={'https://help.dexlab.space/hc/en-us/sections/4410688445977-IDO-Airdrop'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          IDO {'&'} Airdrop FAQ
                        </a>
                      </Menu.Item>
                    </Menu.SubMenu> */}
                  </Menu>
                </HideMobileDiv>
                <div
                  className="header-right d-flex my-2 align-items-center"
                  style={{ position: 'absolute', right: '0px' }}
                >
                  <HideMobileDiv>
                    <div style={{ display: 'flex' }}>
                      <div className="language" style={{ marginRight: '3px', color: isDark(theme) ? '' : '#22223e' }}>
                        <Select
                          style={{ color: isDark(theme) ? '' : '#22223e' }}
                          suffixIcon={<DownOutlined style={{ color: isDark(theme) ? '#FFF' : '#000' }} />}
                          onSelect={setSelectLanguage}
                          value={selectLanguage}
                        >
                          {LANGUAGES.map(({ name, value, icon }) => (
                            <Select.Option value={value} key={value}>
                              {icon && <i className={`flag-icon ${icon}`} />} {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      {/* <div className="currency" style={{ color: isDark(theme) ? '' : '#22223e' }}>
                        <Select
                          style={{ color: isDark(theme) ? '' : '#22223e' }}
                          suffixIcon={<DownOutlined style={{ color: isDark(theme) ? '#FFF' : '#000' }} />}
                          onSelect={setSelectCurrency}
                          value={selectCurrency}
                        >
                          {CURRENCYS.map(({ name, value }) => (
                            <Select.Option value={value} key={value}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </div> */}
                    </div>
                  </HideMobileDiv>
                  <div className="dashboard_log" style={{ marginLeft: '10px' }}>
                    {connected ? (
                      <Dropdown className="profile_log">
                        <Dropdown.Toggle as={ProfileToggle} />
                        <Dropdown.Menu size="sm" title="">
                          <div className="user-email" style={{ width: '350px' }}>
                            <div className="user">
                              <span className="thumb">
                                <i className="mdi mdi-account" />
                              </span>
                              <div className="user-info">
                                <h6>
                                  <Link external="true" to={providerUrl}>
                                    {providerName}
                                  </Link>{' '}
                                  wallet{' '}
                                </h6>
                                <Popover
                                  style={{ paddingBottom: '10px' }}
                                  content={<LinkAddress address={walletPublicKey} />}
                                  placement="bottomRight"
                                  title={trText('wallet_public_key_title')}
                                  trigger="click"
                                >
                                  {trText('wallet_public_key_title')}
                                </Popover>
                              </div>
                            </div>
                          </div>

                          {/* <div className="user-balance">
                            {formattedBalances.map(([currency, balances, baseOrQuote], index) => (
                              <div className="available" key={index}>
                                {currency}
                                <p>{balances && balances.wallet ? `${balances.wallet}(${baseOrQuote})` : '-'}</p>
                              </div>
                            ))}
                          </div> */}
                          {connected ? (
                            <div
                              style={{
                                marginLeft: '45px',
                                marginRight: '10px',
                                marginBottom: '20px',
                                marginTop: '30px',
                              }}
                            >
                              {/* <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                                <Switch
                                  disabled={!wallet?.autoApprove}
                                  checked={wallet?.autoApprove && autoSettleEnabled}
                                  onChange={setAutoSettleEnabled}
                                />{' '}
                                <span>{trText('setting_auto_settle')}</span>
                                <b >
                                  {!wallet?.autoApprove ? `(${trText('setting_not_auto_settle_msg')})` : ''}
                                </b>
                                {!wallet?.autoApprove && (
                                  <Paragraph style={{ marginTop: '5px' }}>
                                    {trText('setting_not_wallet_auto_settle')}
                                  </Paragraph>
                                )}
                              </div> */}
                              <div style={{ textAlign: 'center' }}>
                                {/* <Button
                                  style={{ height: '40px' }}
                                  type="primary"
                                  shape="round"
                                  onClick={depositFtxForSOL}
                                >
                                  Deposit from FTX
                                </Button> */}
                                <div style={{ marginTop: '3px' }}>
                                  <span>
                                    SOL Balance: <b>{solBalance.toFixed(4)}SOL</b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {/* <Link to={'/minting-lab/markets'} className="dropdown-item">
                            <i className="mdi mdi-coin" />
                            <b>List Your Market</b>
                          </Link> */}
                          <a
                            href={`https://www.dexlab.space/my-ido`}
                            className="dropdown-item"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-coin" />
                            <b>{trText('tab_my_token_sale')}</b>
                          </a>
                          {/* <a
                            href={`https://www.dexlab.space/my/open-orders`}
                            className="dropdown-item"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-cart" />
                            <b>{trText('tab_open_order')}</b>
                          </a> */}
                          {/* <Link to={'/v2/my/orders'} className="dropdown-item">
                            <i className="mdi mdi-cart" />
                            <b>{trText('tab_open_order')}</b>
                          </Link> */}
                          {/* <a
                            href={`https://www.dexlab.space/my/balances`}
                            className="dropdown-item"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-wallet" />
                            <b>{trText('tab_balances')}</b>
                          </a> */}
                          {/* <Link to={'/v2/my/balances'} className="dropdown-item">
                            <i className="mdi mdi-wallet" />
                            <b>{trText('tab_balances')}</b>
                          </Link> */}
                          <a
                            href={providerUrl.replaceAll('/extension', '')}
                            className="dropdown-item"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-lock" />
                            <b>{providerName}</b> wallet
                          </a>
                          <div
                            className="dropdown-item logout"
                            // onClick={connected ? wallet.disconnect : wallet.connect}
                            onClick={() => {
                              window.location.reload()
                            }}
                          >
                            <i className="mdi mdi-logout" /> Logout
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <WalletConnect />
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TradeHeader

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { SOLANA_EXPLORER_URL } from '../application'
import { useParams } from 'react-router-dom'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import DexLabIdoApi from '../utils/client/dexlabIdoApiConnector'
import { useWallet } from '../utils/wallet'
import { notify } from '../utils/notifications'
import { PageHeader, Tag, Typography, Row } from 'antd'
import AirdropTimelineView from '../componentsv2/AirdropTimelineView'
import CoingeckoApi from '../utils/client/coingeckoConnector'

import { DexlabIdoMarketDetail, MyIdoWhitelistResponse } from '../utils/types'

const { Paragraph } = Typography

export default function AirdropEventPage() {
  document.title = `Airdrop Event - The best DEX platform on SOLANA.`

  const { marketId } = useParams()
  const { wallet, connected } = useWallet()

  const [saleMarketInfo, setSaleMarketInfo] = useState<DexlabIdoMarketDetail | undefined>(undefined)
  const [whiteListWinner, setWhiteListWinner] = useState<MyIdoWhitelistResponse | undefined>(undefined)
  const [homepageUrl, setHomepageUrl] = useState<string | undefined | null>(undefined)
  const [whitepaperUrl, setWhitepaperUrl] = useState<string | undefined | null>(undefined)
  const [telegramUrl, setTelegramUrl] = useState<string | undefined | null>(undefined)
  const [currentPrice, setCurrentPrice] = useState('')

  useEffect(() => {
    getIdoMarketInfo() // IDO 정보
  }, [])

  useEffect(() => {
    if (wallet && wallet?.connected) {
      getIdoMarketInfo() // IDO 정보 재조회
      getWhiteListChackWinner() // 당첨여부 확인
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.connected])

  async function getCoinPrice(coingeckoId) {
    if (coingeckoId) {
      const response = await CoingeckoApi.getTokenPrice(coingeckoId)
      if (response) {
        setCurrentPrice(response.market_data.current_price.usd)
      }
    }
  }

  async function refreshAllData() {
    getWhiteListChackWinner()
    getIdoMarketInfo()
    notify({
      message: 'Refresh successful!',
      type: 'success',
    })
  }

  async function getWhiteListChackWinner() {
    const publicKey = connected && wallet ? wallet.publicKey.toBase58() : undefined
    if (publicKey) {
      const response = await DexLabIdoApi.checkWhiteListWinner(marketId, publicKey)
      if (response) {
        setWhiteListWinner(response)
      }
    }
  }

  async function getIdoMarketInfo() {
    const publicKey = connected && wallet ? wallet.publicKey.toBase58() : undefined
    const response = await DexLabIdoApi.getIdoMarket(marketId, publicKey)
    try {
      if (response) {
        setSaleMarketInfo(response)
        getCoinPrice(response.coingeckoId)

        const findHomepage = response.community?.find((f) => f.type === 'WEBSITE')
        setHomepageUrl(findHomepage.value)
        const findWhitepaper = response.community?.find((f) => f.type === 'WHITE_PAPER')
        setWhitepaperUrl(findWhitepaper.value)
        const findTelegram = response.community?.find((f) => f.type === 'TELEGRAM')
        setTelegramUrl(findTelegram.value)
      } else {
        notify({
          message: 'Temporarily unable to load market information. Please try again in a few minutes',
          type: 'error',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const IconLink = ({ src, text, link }) => (
    <a style={{ marginRight: '6px', color: '#FFFFFF' }} target="_blank" href={link}>
      <img src={src} alt={text} />
      {text}
    </a>
  )

  const content = (
    <>
      {currentPrice && (
        <Paragraph>
          <span color="default" style={{ fontSize: '25px', color: '#26a69a' }}>
            ${currentPrice}
            <a
              style={{ fontSize: '12px', color: '#646464' }}
              href={`https://www.coingecko.com/en/coins/${saleMarketInfo?.coingeckoId}`}
              target="_blank"
            >
              {' '}
              (Coingecko)
            </a>
          </span>
        </Paragraph>
      )}
      <Paragraph>
        Dexlab hosts <b>{saleMarketInfo?.base ?? ''} Token</b> Airdrop Event for Dexlab stakers!
        <br />
        To participate in the airdrop event, you must submit the application.
        <br />
        For those who staked DXL and submitted the application, tokens will be airdropped proportionally according to
        your ‘pool token.’
      </Paragraph>
      <div>
        {homepageUrl && (
          <IconLink
            src="https://gw.alipayobjects.com/zos/rmsportal/NbuDUAuBlIApFuDvWiND.svg"
            link={homepageUrl}
            text=" Homepage"
          />
        )}
        {telegramUrl && (
          <IconLink
            src="https://gw.alipayobjects.com/zos/rmsportal/MjEImQtenlyueSmVEfUD.svg"
            link={telegramUrl}
            text=" Telegram"
          />
        )}
        {whitepaperUrl && (
          <IconLink
            src="https://gw.alipayobjects.com/zos/rmsportal/ohOEPSYdDTNnyMbGuyLb.svg"
            link={whitepaperUrl}
            text=" Whitepaper"
          />
        )}
      </div>
    </>
  )

  const Content = ({ children, extraContent }) => (
    <Row>
      <div style={{ flex: 1 }}>{children}</div>
      <div className="image">{extraContent}</div>
    </Row>
  )

  const getAidropStatus = () => {
    if (!saleMarketInfo || !saleMarketInfo.whitelistInfo) {
      return 'Soon'
    }

    const todayDate = moment().utc()
    const whitelistStartDate = moment(saleMarketInfo.whitelistInfo.startDate).utc(true)
    const whitelistEndDate = moment(saleMarketInfo.whitelistInfo.endDate).utc(true)

    // 화이트리스트 시작전인지? ( 시작전 )
    if (todayDate.isBefore(whitelistStartDate)) {
      return 'Soon'
    }

    // 화이트리스트 진행중인지? ( 신청중 )
    if (todayDate.isBetween(whitelistStartDate, whitelistEndDate)) {
      if (wallet && connected && !saleMarketInfo.whitelistInfo.isWhitelistStaked) {
        return 'Condition not met'
      }
      return 'Proceeding'
    }

    // 화이트리스트 신청 날짜가 지났는지? ( 종료 )
    if (todayDate.isAfter(whitelistEndDate)) {
      return 'Ended'
    }
  }

  return (
    <FloatingElement
      // @ts-ignore
      style={{ flex: 1, paddingTop: 60 }}
    >
      {saleMarketInfo ? (
        <>
          <PageHeader
            title={saleMarketInfo.name}
            className="site-page-header"
            subTitle={
              <>
                <a
                  style={{ color: '#646464' }}
                  href={`${SOLANA_EXPLORER_URL}/address/${saleMarketInfo.baseTokenMint}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {saleMarketInfo.baseTokenMint}
                </a>
              </>
            }
            tags={
              <Tag
                color={
                  getAidropStatus() === 'Proceeding' ? 'green' : getAidropStatus() === 'Ended' ? 'default' : 'blue'
                }
              >
                {getAidropStatus()}
              </Tag>
            }
            avatar={{ src: saleMarketInfo.logoUrl }}
          >
            <Content extraContent={<img src={saleMarketInfo.image} alt="content" width="350px" />}>{content}</Content>
          </PageHeader>
          <div>
            {getAidropStatus() !== 'Ended' ? (
              <AirdropTimelineView
                saleMarketInfo={saleMarketInfo}
                whiteListWinner={whiteListWinner}
                refreshAllData={refreshAllData}
                reloadMarketInfo={getIdoMarketInfo}
              />
            ) : (
              <div style={{ textAlign: 'center' }}>
                Thank you everyone!
                <p style={{ fontSize: '25px', color: 'white' }}>The airdrop event has ended.</p>
              </div>
            )}
          </div>
          {getAidropStatus() !== 'Ended' && (
            <div>
              <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>
                Airdrop Information
              </div>
              <div dangerouslySetInnerHTML={{ __html: saleMarketInfo.desc ?? '' }}></div>
            </div>
          )}
        </>
      ) : null}
    </FloatingElement>
  )
}

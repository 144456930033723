/* eslint-disable */
import React, { useContext, useMemo } from 'react'
import { TokenInfo } from '@solana/spl-token-registry'
import { convertDexlabMarketToTokenInfo } from '../../utils/market-list'

type TokenListContext = {
  tokenMap: Map<string, TokenInfo>
  wormholeMap: Map<string, TokenInfo>
  solletMap: Map<string, TokenInfo>
  extraMap: Map<string, TokenInfo>
  swappableTokens: TokenInfo[]
  swappableTokensDexlab: TokenInfo[]
  swappableTokensWormhole: TokenInfo[]
  swappableTokensDexlabExtra: TokenInfo[]
}
const _TokenListContext = React.createContext<null | TokenListContext>(null)

// Tag in the spl-token-registry for sollet wrapped tokens.
// export const SPL_REGISTRY_SOLLET_TAG = 'wrapped-sollet'
export const SPL_REGISTRY_DEXLAB_TAG = 'dexlab'

export const SPL_REGISTRY_DEXLAB_EXTRA_TAG = 'dexlab_extra'

// Tag in the spl-token-registry for wormhole wrapped tokens.
export const SPL_REGISTRY_WORM_TAG = 'wormhole'

export function TokenListContextProvider(props: any) {
  const tokenList = useMemo(() => {
    return convertDexlabMarketToTokenInfo(props.dexlabMarkets)
  }, [props.tokenList])

  // Token map for quick lookup.
  // @ts-ignore
  const tokenMap = useMemo(() => {
    const tokenMap = new Map()
    tokenList.forEach((t: TokenInfo) => {
      tokenMap.set(t.address, t)
    })
    return tokenMap
  }, [tokenList])

  // Tokens with USD(x) quoted markets.
  const swappableTokens = useMemo(() => {
    const tokens = tokenList
    tokens.sort((a: TokenInfo, b: TokenInfo) => (a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0))
    return tokens
  }, [tokenList, tokenMap])

  // Extra market token
  const [swappableTokensDexlabExtra, extraMap] = useMemo(() => {
    const tokens = tokenList.filter((t: TokenInfo) => {
      const isExtra = t.tags?.includes(SPL_REGISTRY_DEXLAB_EXTRA_TAG)
      return isExtra
    })
    tokens.sort((a: TokenInfo, b: TokenInfo) => (a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0))
    return [tokens, new Map<string, TokenInfo>(tokens.map((t: TokenInfo) => [t.address, t]))]
  }, [tokenList])

  // Sollet wrapped tokens.
  const [swappableTokensDexlab, solletMap] = useMemo(() => {
    const tokens = tokenList.filter((t: TokenInfo) => {
      const isSollet = t.tags?.includes(SPL_REGISTRY_DEXLAB_TAG)
      return isSollet
    })
    tokens.sort((a: TokenInfo, b: TokenInfo) => (a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0))
    return [tokens, new Map<string, TokenInfo>(tokens.map((t: TokenInfo) => [t.address, t]))]
  }, [tokenList])

  // Wormhole wrapped tokens.
  const [swappableTokensWormhole, wormholeMap] = useMemo(() => {
    const tokens = tokenList.filter((t: TokenInfo) => {
      const isSollet = t.tags?.includes(SPL_REGISTRY_WORM_TAG)
      return isSollet
    })
    tokens.sort((a: TokenInfo, b: TokenInfo) => (a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0))
    return [tokens, new Map<string, TokenInfo>(tokens.map((t: TokenInfo) => [t.address, t]))]
  }, [tokenList])

  return (
    <_TokenListContext.Provider
      value={{
        tokenMap,
        wormholeMap,
        solletMap,
        extraMap,
        swappableTokens,
        swappableTokensWormhole,
        swappableTokensDexlab,
        swappableTokensDexlabExtra,
      }}
    >
      {props.children}
    </_TokenListContext.Provider>
  )
}

export function useTokenListContext(): TokenListContext {
  const ctx = useContext(_TokenListContext)
  if (ctx === null) {
    throw new Error('Context not available')
  }
  return ctx
}

export function useTokenMap(): Map<string, TokenInfo> {
  const { tokenMap } = useTokenListContext()
  return tokenMap
}

export function useSwappableTokens() {
  const { swappableTokens, swappableTokensWormhole, swappableTokensDexlab, swappableTokensDexlabExtra } =
    useTokenListContext()
  return { swappableTokens, swappableTokensWormhole, swappableTokensDexlab, swappableTokensDexlabExtra }
}

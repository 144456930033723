import _ from 'lodash'
import { DexLabMarketV2Info, DexLabRecentPrice, DexlabMarketLpPoonInfo, DexlabMarketVolumeSummary } from './../types'
import { DEXLAB_MARKLET_API_HOST } from '../../application'

async function getMarketsOrCahce(isCache = true): Promise<DexLabMarketV2Info[] | null> {
  // const markets = await DexLaMarketbApi.get(`v2/markets?summary=Y`)
  const markets = await DexLaMarketbApi.get(`v2/markets`)
  if (isCache) {
    localStorage.setItem(
      'dex_markets_v2',
      JSON.stringify({
        time: new Date().getTime(),
        markets: markets,
      }),
    )
  } else {
    localStorage.removeItem('dex_markets_v2')
  }
  return markets
}

export default class DexLaMarketbApi {
  static URL: string = `${DEXLAB_MARKLET_API_HOST}/`

  static async get(path: string, baseUrl: string = this.URL) {
    try {
      const response = await fetch(baseUrl + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error fetching from DexLaMarketbApi API ${path}: ${err}`)
    }
    return null
  }

  static async getBody(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error fetching from DexLaMarketbApi API ${path}: ${err}`)
    }
    return null
  }

  static async getAllMarketsV2(): Promise<DexLabMarketV2Info[] | null> {
    return []
    // try {
    //   if (localStorage.getItem('dex_markets') || !_.isEmpty(localStorage.getItem('dex_markets'))) {
    //     localStorage.removeItem('dex_markets')
    //   }

    //   const currentCacheMarket = localStorage.getItem('dex_markets_v2')
    //   if (currentCacheMarket) {
    //     const parseData = JSON.parse(currentCacheMarket)

    //     const nowDate = new Date().getTime()
    //     const cacheTime = new Date(parseData.time).getTime()
    //     const chackCacheTime = (nowDate - cacheTime) / 1000

    //     if (!parseData || !parseData.markets || _.isEmpty(parseData.markets) || chackCacheTime > 60) {
    //       return getMarketsOrCahce(true)
    //     }
    //     return parseData.markets
    //   } else {
    //     return getMarketsOrCahce(true)
    //   }
    // } catch (e) {
    //   return getMarketsOrCahce(false)
    // }
  }

  static async getMarketLpPool(marketAddress): Promise<DexlabMarketLpPoonInfo | null> {
    // return DexLaMarketbApi.get(`v2/markets/${marketAddress}/pool`)
    return null
  }

  static async getExtraMarketList(): Promise<DexLabMarketV2Info[] | null> {
    return []
    // const currentCacheMarket = localStorage.getItem('dex_markets')
    // if (currentCacheMarket) {
    //   const parseData = JSON.parse(currentCacheMarket)
    //   const markets = parseData.markets as DexLabMarketV2Info[]
    //   return markets.filter((f) => f.source === 'UNCERTIFIED' || f.source === 'NOT_LISTING')
    // }
    // return DexLaMarketbApi.get(`v2/markets?type=UNCERTIFIED,NOT_LISTING`)
  }

  static async getStarAtlasMarketList(): Promise<DexLabMarketV2Info[] | null> {
    return []
    // return DexLaMarketbApi.get(`v2/markets?type=STAR_ATLAS`)
  }

  static async getMarketSummary(type): Promise<DexlabMarketVolumeSummary[] | null> {
    return []
    // return DexLaMarketbApi.get(`v2/markets/volume?type=${type}`)
  }

  static async getAllMarketV2ByBaseTokenIds(ids): Promise<DexLabMarketV2Info[] | null> {
    return []
    // return DexLaMarketbApi.getBody(`v2/markets/base`, ids)
  }

  static async getAllMarketCurrent24hPriceAndLastPrice(): Promise<DexLabRecentPrice[] | null> {
    return []
    // return DexLaMarketbApi.get(`v2/markets/recent`)
  }
}

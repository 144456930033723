import { DEXLAB_API_HOST } from '../../application'

export interface ExchangeEventRequest {
  x?: string // TXID
  w: string // 월렛 주소
  p: number // 구매금액
  s: number // 구매 사이즈
  t: string // type = buy, sell
  m: string // market address
  o: string // order type( ioc, post, limit )
  b: string // base account
  q: string // quote account
  ref?: string
}

export interface CancelEventRequest {
  x?: string // TXID
  o?: string // 주문번호
  w: string // 월렛 주소
  p: number // 구매금액
  s: number // 구매 사이즈
  t: string // type = buy, sell
  m: string // market address
  ref?: string
}

export interface SettlementEventRequest {
  x?: string // TXID
  w: string // 월렛 주소
  a: number // baseTokenFree
  b: number // baseTokenTotal
  c: number // quoteTokenFree
  d: number // quoteTokenTotal
  z: string // baseMintAddress
  f: string // quoteMintAddress
  m: string // marketAddress
  i: boolean // supportsReferralFees
  r?: string | null // ref address
  ref?: string
  initialized: boolean
  market: boolean
  openOrders: boolean
  requestQueue: boolean
  eventQueue: boolean
  bids: boolean
  asks: boolean
}

export default class DexLabLoggingApi {
  static URL: string = `${DEXLAB_API_HOST}/`

  static async post(path: string, body: any) {
    try {
      await fetch(this.URL + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
    } catch (err: any) {
      // no action
    }
  }

  // 주문 이벤트 트래킹
  static async eventExchange(request: ExchangeEventRequest) {
    return DexLabLoggingApi.post(`v1/event-logging/exchange`, request)
  }

  static async eventSettlement(request: SettlementEventRequest) {
    return DexLabLoggingApi.post(`v1/event-logging/settlement`, request)
  }

  static async eventCancel(request: CancelEventRequest) {
    return DexLabLoggingApi.post(`v1/event-logging/cancel`, request)
  }
}

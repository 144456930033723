import { Layout } from 'antd'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import TradeHeader from '../componentsv2/layout/trade-header'
import Footer from '../componentsv2/layout/footer'
import { useReferrer } from '../utils/referrer'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { notify } from '../utils/notifications'

const { Content } = Layout

const MainCoinHeader = styled.div`
  margin-top: 90px;

  //@media only screen and (max-width: 768px) {
  //  margin-top: 165px;
  //}
`

export default function BasicLayout({ children, isFooter = true }) {
  const { refCode, setRefCode, allowRefLink } = useReferrer()
  const { search } = useLocation()
  const parsed = queryString.parse(search)

  useEffect(() => {
    if (!!parsed.refCode && parsed.refCode !== refCode && allowRefLink) {
      notify({ message: `New referrer ${parsed.refCode} added` })
      setRefCode(parsed.refCode)
    }
  }, [parsed, refCode, setRefCode, allowRefLink])

  return (
    <React.Fragment>
      <Layout style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        <TradeHeader />
        <Content style={{ flex: 1 }}>
          <MainCoinHeader>{children}</MainCoinHeader>
        </Content>
        {isFooter ? <Footer /> : null}
      </Layout>
    </React.Fragment>
  )
}

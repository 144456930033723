import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEn from './lang/translation.en.json'
import translationKo from './lang/translation.ko.json'
import translationEs from './lang/translation.es.json'
import translationZhCn from './lang/translation.zh_cn.json'
import translationZhTw from './lang/translation.zh_tw.json'

const resources = {
  ko: {
    translation: translationKo,
  },
  en: {
    translation: translationEn,
  },
  es: {
    translation: translationEs,
  },
  zh_cn: {
    translation: translationZhCn,
  },
  zh_tw: {
    translation: translationZhTw,
  },
}
i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})
export default i18n

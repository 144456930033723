import React, { useContext, useState } from 'react'
import _ from 'lodash'
import { sleep, useLocalStorageState } from './utils'
import { useInterval } from './useInterval'
import { useDexlabRpcConnection } from './connection'
import { useWallet } from './wallet'
import { useTokenAccounts } from './dex-markets'
import { settleAllFunds } from './send'
import { PreferencesContextValues, DexLabMarketV2Info } from './types'
import { Market } from '@project-serum/serum'
// import DexlabMarketApi from '../utils/client/dexlabMarketApiConnector'
// import DexlabApi from '../utils/client/dexlabApiConnector'
import { useEffect } from 'react'
import { PublicKey } from '@solana/web3.js'
import { MARKETS_TO_STRING } from './data/markets'

export const AUTO_SETTLE_DISABLED_OVERRIDE = true

const PreferencesContext = React.createContext<PreferencesContextValues | null>(null)

export function PreferencesProvider({ children }) {
  const [allV2Markets, setAllV2Markets] = useState<DexLabMarketV2Info[]>([])
  async function getAllMarkets() {
    // const response = await DexlabMarketApi.getAllMarketsV2()
    // if (response) {
    //   setAllV2Markets(response)
    // } else {
    //   // retry sub api
    //   const subApiResponse = await DexlabApi.retryGetAllMarketsV2()
    //   if (subApiResponse) {
    //     setAllV2Markets(subApiResponse)
    //   }
    // }
    setAllV2Markets(JSON.parse(MARKETS_TO_STRING))
  }

  useEffect(() => {
    getAllMarkets()
  }, [])

  return !_.isEmpty(allV2Markets) && <PreferencesInnerPage children={children} allV2Markets={allV2Markets} />
}

function PreferencesInnerPage({ children, allV2Markets }) {
  const [autoSettleEnabled, setAutoSettleEnabled] = useLocalStorageState('autoSettleEnabledV2', false) // TODO 정상동작 하지 않아 비활성

  const [tokenAccounts] = useTokenAccounts()
  const { connected, wallet } = useWallet()
  // const marketInfoList = useMarketInfos()
  const [currentlyFetchingMarkets, setCurrentlyFetchingMarkets] = useState<boolean>(false)
  const [markets, setMarkets] = useState<Map<string, Market>>(new Map())
  const addToMarketsMap = (marketId, market) => {
    setMarkets((prev) => new Map(prev).set(marketId, market))
  }
  const connection = useDexlabRpcConnection()

  useInterval(() => {
    const autoSettle = async () => {
      if (AUTO_SETTLE_DISABLED_OVERRIDE) {
        return
      }
      if (!wallet) {
        return
      }
      try {
        // console.log('Settling funds...')
        await settleAllFunds({
          connection,
          wallet,
          tokenAccounts: tokenAccounts || [],
          markets: [...markets.values()],
        })
      } catch (e: any) {
        console.log('Error auto settling funds: ' + e.message)
        return
      }
      // console.log('Finished settling funds.')
    }
    connected && wallet?.autoApprove && autoSettleEnabled && autoSettle()
  }, 20000)

  // warms up the market and open orders cache for auto-settlement
  useInterval(() => {
    const fetchMarkets = async () => {
      if (!wallet) {
        // only need these markets for auto-settlement, so don't fetch unless we are connected.
        return
      }
      setCurrentlyFetchingMarkets(true)
      for (const marketInfo of allV2Markets) {
        if (markets.has(marketInfo.address.toString())) {
          continue
        }
        try {
          const market = await Market.load(
            connection,
            new PublicKey(marketInfo.address),
            {},
            new PublicKey(marketInfo.programId),
          )
          addToMarketsMap(marketInfo.address.toString(), market)
          await sleep(1000)
        } catch (e: any) {
          console.log('Error fetching market: ' + e.message)
        }
      }
      setCurrentlyFetchingMarkets(false)
    }
    connected && wallet?.autoApprove && autoSettleEnabled && !currentlyFetchingMarkets && fetchMarkets()
  }, 60000)

  return (
    <PreferencesContext.Provider
      value={{
        autoSettleEnabled,
        setAutoSettleEnabled,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  )
}

export function usePreferences() {
  const context = useContext(PreferencesContext)
  if (!context) {
    throw new Error('Missing preferences context')
  }
  return {
    autoSettleEnabled: context.autoSettleEnabled,
    setAutoSettleEnabled: context.setAutoSettleEnabled,
  }
}

import { Button, Input, Switch, Slider, Statistic, Row, Col } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  useSelectedBaseCurrencyBalances,
  useSelectedQuoteCurrencyBalances,
  useMarket,
  useMarkPrice,
  useSelectedOpenOrdersAccount,
  useSelectedBaseCurrencyAccount,
  useSelectedQuoteCurrencyAccount,
  useLocallyStoredFeeDiscountKey,
} from '../utils/dex-markets'
import { useWallet } from '../utils/wallet'
import { notify } from '../utils/notifications'
import { getDecimalCount, roundToDecimal, floorToDecimal } from '../utils/utils'
import { useDexlabRpcConnection, useDexlabRpcSendConnection } from '../utils/connection'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import { placeOrder } from '../utils/send'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import { refreshCache } from '../utils/fetch-loop'
import tuple from 'immutable-tuple'
import { useTranslation } from 'react-i18next'

// event
import { eventExchange } from '../utils/client/dexlabEventTrackingService'

const SellButton = styled(Button)`
  margin: 20px 0px 0px 0px;
  background: #f6465d;
  border-color: #f6465d;
`

const BuyButton = styled(Button)`
  margin: 20px 0px 0px 0px;
  background: #26a69a;
  border-color: #26a69a;
`

const sliderMarks = {
  0: '0%',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%',
}

export default function TradeForm({
  style,
  tradeType,
  setChangeOrderRef,
}: {
  style?: any
  tradeType: 'buy' | 'sell'
  setChangeOrderRef?: (ref: ({ size, price }: { size?: number; price?: number }) => void) => void
}) {
  const { t: trText } = useTranslation()

  const [side, setSide] = useState<'buy' | 'sell'>(tradeType)
  const { baseCurrency, quoteCurrency, market } = useMarket()
  const baseCurrencyBalances = useSelectedBaseCurrencyBalances()
  const quoteCurrencyBalances = useSelectedQuoteCurrencyBalances()
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount()
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount()
  const openOrdersAccount = useSelectedOpenOrdersAccount(true)
  const { connected, wallet, select, disconnect } = useWallet()
  // const sendConnection = useConnection()
  const sendDexlabRpcConnection = useDexlabRpcConnection()
  const sendDexlabSendRpcConnection = useDexlabRpcSendConnection()
  const { markPrice } = useMarkPrice()
  const { storedFeeDiscountKey: feeDiscountKey } = useLocallyStoredFeeDiscountKey()
  // const solBalance = useWalletSolBalance()

  const [postOnly, setPostOnly] = useState(false)
  const [ioc, setIoc] = useState(false)
  const [baseSize, setBaseSize] = useState<number | undefined>(undefined)
  const [quoteSize, setQuoteSize] = useState<number | undefined>(undefined)
  const [price, setPrice] = useState<number | undefined>(undefined)
  const [orderButtonErrorMessage, setOrderButtonErrorMessage] = useState<string | undefined>(undefined)
  const [submitting, setSubmitting] = useState(false)
  const [sizeFraction, setSizeFraction] = useState(0)

  const availableQuote = openOrdersAccount && market ? market.quoteSplSizeToNumber(openOrdersAccount.quoteTokenFree) : 0

  let quoteBalance = (quoteCurrencyBalances || 0) + (availableQuote || 0)
  let baseBalance = baseCurrencyBalances || 0
  let sizeDecimalCount = market?.minOrderSize && getDecimalCount(market.minOrderSize)
  let priceDecimalCount = market?.tickSize && getDecimalCount(market.tickSize)
  let minOrderSize = 0

  if (market) {
    const mintOrderSizeSplit = `${market.minOrderSize}`.split('.')
    if (mintOrderSizeSplit && mintOrderSizeSplit.length > 1) {
      minOrderSize = mintOrderSizeSplit[1].length
    }
  }

  const publicKey = wallet?.publicKey

  useEffect(() => {
    setSide(tradeType)
  }, [tradeType])

  useEffect(() => {
    setChangeOrderRef && setChangeOrderRef(doChangeOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChangeOrderRef])

  useEffect(() => {
    baseSize && price && onSliderChange(sizeFraction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [side])

  useEffect(() => {
    updateSizeFraction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, baseSize])

  useEffect(() => {
    const warmUpCache = async () => {
      if (!wallet || !publicKey || !market) {
        // console.log(`Skipping refreshing accounts`)
        return
      }
      // const startTime = getUnixTs()
      // console.log(`Refreshing accounts for ${market.address}`)
      await market?.findOpenOrdersAccountsForOwner(sendDexlabRpcConnection, publicKey)
      await market?.findBestFeeDiscountKey(sendDexlabRpcConnection, publicKey)

      // const endTime = getUnixTs()
      // console.log(`Finished refreshing accounts for ${market.address} after ${endTime - startTime}`)
    }
    if (connected && wallet && publicKey && market) {
      warmUpCache()
      const id = setInterval(warmUpCache, 30_000)
      return () => clearInterval(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market, sendDexlabRpcConnection, wallet, publicKey])

  const onSetBaseSize = (baseSize: number | undefined) => {
    setBaseSize(baseSize ? Number(baseSize.toFixed(minOrderSize)) : undefined)
    if (!baseSize) {
      setQuoteSize(undefined)
      return
    }

    if (market && baseSize < market.minOrderSize) {
      setOrderButtonErrorMessage(`Minimum order size of ${market.minOrderSize} or more`)
      return
    }
    setOrderButtonErrorMessage(undefined)

    let usePrice = price || markPrice
    if (!usePrice) {
      setQuoteSize(undefined)
      return
    }
    const rawQuoteSize = baseSize * usePrice
    const quoteSize = baseSize && roundToDecimal(rawQuoteSize, sizeDecimalCount)
    setQuoteSize(quoteSize ? parseFloat(quoteSize.toFixed(6)) : quoteSize)
  }

  const onSetQuoteSize = (quoteSize: number | undefined) => {
    setQuoteSize(quoteSize ? parseFloat(quoteSize.toFixed(6)) : quoteSize)
    if (!quoteSize) {
      setBaseSize(undefined)
      return
    }
    let usePrice = price || markPrice
    if (!usePrice) {
      setBaseSize(undefined)
      return
    }
    const rawBaseSize = quoteSize / usePrice
    const baseSize = quoteSize && roundToDecimal(rawBaseSize, sizeDecimalCount)
    setBaseSize(Number(baseSize.toFixed(minOrderSize)))
  }

  const doChangeOrder = ({ size, price }: { size?: number; price?: number }) => {
    const formattedSize = size && roundToDecimal(size, sizeDecimalCount)
    const formattedPrice = price && roundToDecimal(price, priceDecimalCount)

    if (formattedSize) {
      onSetBaseSize(formattedSize)
    }
    if (formattedPrice) {
      setPrice(formattedPrice)
    }
  }

  const updateSizeFraction = () => {
    const rawMaxSize = side === 'buy' ? quoteBalance / (price || markPrice || 1) : baseBalance
    const maxSize = floorToDecimal(rawMaxSize, sizeDecimalCount)
    const sizeFraction = Math.min(((baseSize || 0) / maxSize) * 100, 100)
    setSizeFraction(sizeFraction)
  }

  const onSliderChange = (value) => {
    if (!price && markPrice) {
      let formattedMarkPrice: number | string = priceDecimalCount ? markPrice.toFixed(priceDecimalCount) : markPrice
      setPrice(typeof formattedMarkPrice === 'number' ? formattedMarkPrice : parseFloat(formattedMarkPrice))
    }

    let newSize
    if (side === 'buy') {
      if (price || markPrice) {
        newSize = ((quoteBalance / (price || markPrice || 1)) * value) / 100
      }
    } else {
      newSize = (baseBalance * value) / 100
    }

    // round down to minOrderSize increment
    let formatted = floorToDecimal(newSize, sizeDecimalCount)

    onSetBaseSize(formatted)
  }

  const postOnChange: SwitchChangeEventHandler = (checked) => {
    if (checked) {
      setIoc(false)
    }
    setPostOnly(checked)
  }
  const iocOnChange: SwitchChangeEventHandler = (checked) => {
    if (checked) {
      setPostOnly(false)
    }
    setIoc(checked)
  }

  async function onSubmit() {
    // 보유 SOL검증
    // if (solBalance) {
    //   if (solBalance < 0.03) {
    //     console.warn(`insufficient sol ${solBalance}, need 0.03`)
    //     notify({
    //       message: `You must have at least 0.03 SOL to trade.( Transaction Fee )\nYour SOL: ${solBalance}`,
    //       type: 'error',
    //     })
    //     return
    //   }
    // }

    if (!price) {
      console.warn('Missing price')
      notify({
        message: 'Missing price',
        type: 'error',
      })
      return
    } else if (!baseSize) {
      console.warn('Missing size')
      notify({
        message: 'Missing size',
        type: 'error',
      })
      return
    }

    setSubmitting(true)
    try {
      if (!wallet) {
        return null
      }

      const order = await placeOrder({
        side,
        price,
        size: baseSize,
        orderType: ioc ? 'ioc' : postOnly ? 'postOnly' : 'limit',
        market,
        connection: sendDexlabSendRpcConnection,
        wallet,
        baseCurrencyAccount: baseCurrencyAccount?.pubkey,
        quoteCurrencyAccount: quoteCurrencyAccount?.pubkey,
        feeDiscountPubkey: feeDiscountKey,
      })

      eventExchange({
        x: order, // txId
        w: wallet.publicKey.toBase58(),
        p: price,
        s: baseSize,
        t: side,
        m: market?.address.toBase58() ?? '-',
        o: ioc ? 'ioc' : postOnly ? 'postOnly' : 'limit',
        b: baseCurrencyAccount?.pubkey.toBase58() ?? '-',
        q: quoteCurrencyAccount?.pubkey.toBase58() ?? '-',
      })

      refreshCache(tuple('getTokenAccounts', wallet, connected))
      setPrice(undefined)
      onSetBaseSize(undefined)
    } catch (e: any) {
      console.warn(e)
      notify({
        message: 'Error placing order',
        description: e.message,
        type: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <FloatingElement style={{ display: 'flex', backgroundColor: '#00000000', flexDirection: 'column', ...style }}>
      <div style={{ flex: 1 }}>
        {/* <Radio.Group
          onChange={(e) => setSide(e.target.value)}
          value={side}
          buttonStyle="solid"
          style={{
            marginBottom: 8,
            width: '100%',
          }}
        >
          <Radio.Button
            value="buy"
            style={{
              width: '50%',
              textAlign: 'center',
              background: side === 'buy' ? '#26A69A' : '',
              borderColor: side === 'buy' ? '#26A69A' : '',
            }}
          >
            {trText('order_buy')}
          </Radio.Button>
          <Radio.Button
            value="sell"
            style={{
              width: '50%',
              textAlign: 'center',
              background: side === 'sell' ? '#F6465D' : '',
              borderColor: side === 'sell' ? '#F6465D' : '',
            }}
          >
            {trText('order_sell')}
          </Radio.Button>
        </Radio.Group> */}
        <Input
          style={{ textAlign: 'right', paddingBottom: 8 }}
          addonBefore={<div style={{ width: '30px' }}>{trText('order_price')}</div>}
          suffix={<span style={{ fontSize: 10, opacity: 0.5 }}>{quoteCurrency}</span>}
          value={price ?? 0}
          type="number"
          step={market?.tickSize || 1}
          onChange={(e) => setPrice(parseFloat(e.target.value))}
        />
        <Input.Group compact style={{ paddingBottom: 8 }}>
          <Input
            style={{ width: 'calc(50% + 30px)', textAlign: 'right' }}
            addonBefore={<div style={{ width: '30px' }}>{trText('order_size')}</div>}
            suffix={<span style={{ fontSize: 10, opacity: 0.5 }}>{baseCurrency}</span>}
            value={baseSize ?? 0}
            type="number"
            min={market?.minOrderSize || 1}
            step={market?.minOrderSize || 1}
            onChange={(e) => onSetBaseSize(parseFloat(e.target.value))}
          />
          <Input
            style={{ width: 'calc(50% - 30px)', textAlign: 'right' }}
            suffix={<span style={{ fontSize: 10, opacity: 0.5 }}>{quoteCurrency}</span>}
            value={quoteSize ?? 0}
            type="number"
            step={market?.minOrderSize || 1}
            onChange={(e) => onSetQuoteSize(parseFloat(e.target.value))}
          />
        </Input.Group>
        <Slider
          value={sizeFraction}
          tipFormatter={(value) => `${value}%`}
          marks={sliderMarks}
          onChange={onSliderChange}
        />
        {connected && wallet && baseCurrency && quoteCurrency && (
          <div style={{ marginTop: '50px' }}>
            <Row gutter={16}>
              <Col span={12} style={{ textAlign: 'center' }}>
                <Statistic
                  title={`${baseCurrency} BALANCE`}
                  value={parseFloat((baseCurrencyBalances ?? 0).toFixed(4))}
                />
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <Statistic
                  title={`${quoteCurrency} BALANCE`}
                  value={parseFloat((quoteCurrencyBalances ?? 0).toFixed(4))}
                />
              </Col>
            </Row>
          </div>
        )}
        <div style={{ marginTop: '35px', textAlign: 'center' }}>
          {'POST '}
          <Switch checked={postOnly} onChange={postOnChange} style={{ marginRight: 40 }} />
          {'IOC '}
          <Switch checked={ioc} onChange={iocOnChange} />
        </div>
      </div>
      {connected ? (
        baseCurrency !== 'OLDDXL*' ? (
          side === 'buy' ? (
            <BuyButton
              disabled={!price || !baseSize || orderButtonErrorMessage}
              onClick={onSubmit}
              block
              type="primary"
              size="large"
              loading={submitting}
            >
              {!orderButtonErrorMessage
                ? `${trText('order_buy')} [ ${quoteCurrency} -> ${baseCurrency} ]`
                : orderButtonErrorMessage}
            </BuyButton>
          ) : (
            <SellButton
              disabled={!price || !baseSize || orderButtonErrorMessage}
              onClick={onSubmit}
              block
              type="primary"
              size="large"
              loading={submitting}
            >
              {!orderButtonErrorMessage
                ? `${trText('order_sell')} [ ${baseCurrency} -> ${quoteCurrency} ]`
                : orderButtonErrorMessage}
            </SellButton>
          )
        ) : (
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            This market is deprecated. Please cancel all orders.
            <br />
            <a href="https://trade-pro.dexlab.space/#/market/DYfigimKWc5VhavR4moPBibx9sMcWYVSjVdWvPztBPTa">
              Go to New Market
            </a>
          </div>
        )
      ) : (
        <SellButton block type="primary" size="large" onClick={connected ? disconnect : select}>
          {trText('toast_message_not_connect')}
        </SellButton>
      )}
      <div style={{ textAlign: 'center', marginTop: '15px', fontSize: '12px' }}>
        You must have a minimum of <b>0.03 SOL</b> to place an order.
      </div>
    </FloatingElement>
  )
}

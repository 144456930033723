import { PublicKey } from '@solana/web3.js'

export const DEX_PID = new PublicKey('srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX')

export const SRM_MINT = new PublicKey('SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt')

export const DXL_MINT = new PublicKey('GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z')

export const USDC_MINT = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v')

export const USDT_MINT = new PublicKey('Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB')

// Arbitrary mint to represent SOL (not wrapped SOL).
export const SOL_MINT = new PublicKey('Ejmc1UB4EsES5oAaRN63SpoxMJidt3ZGBrqrZk49vjTZ')

export const WRAPPED_SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112')

export const WORM_MARKET_BASE = new PublicKey('6a9wpsZpZGxGhFVSQBpcTNjNjytdbSA1iUw1A5KNDxPw')

export const WORM_USDC_MINT = new PublicKey('FVsXUnbhifqJ4LiXQEbpUtXVdB8T5ADLKqSs5t1oc54F')

export const WORM_USDC_MARKET = new PublicKey('6nGMps9VfDjkKEwYjdSNqN1ToXkLae4VsN49fzBiDFBd')

export const WORM_USDT_MINT = new PublicKey('9w97GdWUYYaamGwdKMKZgGzPduZJkiFizq4rz5CPXRv2')

export const WORM_USDT_MARKET = new PublicKey('4v6e6vNXAaEunrvbqkYnKwbaWfck8a2KVR4uRAVXxVwC')

import React from 'react'
import moment from 'moment'
import { notification } from 'antd'
import Link from '../componentsv2/Link'
import { SOLANA_EXPLORER_URL } from '../application'

export function notify({
  message,
  description,
  txid,
  type = 'info',
  placement = 'bottomLeft',
}: {
  message: string
  description?: string | JSX.Element
  txid?: string
  type?: string
  placement?: string
}) {
  if (txid) {
    const currentHistory = JSON.parse(localStorage.getItem('tx_history') ?? '[]')
    const duplicateCheck = currentHistory.find((f) => f.TX_ID === txid)
    if (!duplicateCheck) {
      const newHistory = [
        ...currentHistory,
        {
          TX_ID: txid,
          DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      ]
      localStorage.setItem('tx_history', JSON.stringify(newHistory))
    }
    description = (
      <Link external to={`${SOLANA_EXPLORER_URL}/tx/${txid}`} style={{ color: '#0000ff' }}>
        View transaction {txid.slice(0, 8)}...{txid.slice(txid.length - 8)}
      </Link>
    )
  }
  notification[type]({
    message: <span style={{ color: 'black' }}>{message}</span>,
    description: <span style={{ color: 'black', opacity: 0.5 }}>{description}</span>,
    placement,
    style: {
      backgroundColor: 'white',
    },
  })
}

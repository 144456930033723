import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row, Select } from 'antd'
import styled from 'styled-components'
import { Orderbook } from '@project-serum/serum'
import {
  getExpectedFillPrice,
  getMarketDetails,
  getMarketInfos,
  getMarketOrderPrice,
  getSelectedTokenAccountForMint,
  useBalances,
  MarketProvider,
  useCustomMarkets,
  useTokenAccounts,
  useMarket,
  useLocallyStoredFeeDiscountKey,
} from '../utils/markets'
import { notify } from '../utils/notifications'
import { useWallet } from '../utils/wallet'
import { useConnection, useSendConnection } from '../utils/connection'
import { placeOrder } from '../utils/send'
import { floorToDecimal, getDecimalCount } from '../utils/utils'
import WalletConnectGuide from './WalletConnectGuide'
import { SwapOutlined } from '@ant-design/icons'
import { CustomMarketInfo } from '../utils/types'
// import Wallet from '@project-serum/sol-wallet-adapter'
import { WalletAdapter } from '../wallet-adapters'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const ActionButton = styled(Button)`
  border-color: #fafafa;
  color: #1652f0;
  border-width: 0px;
`

const ConvertButton = styled(Button)`
  background: #02bf76;
  border-color: #02bf76;
`

export default function ConvertForm() {
  const { t: trText } = useTranslation()

  const { connected, wallet } = useWallet()
  const { customMarkets } = useCustomMarkets()
  const marketInfos = getMarketInfos(customMarkets)
  const [marketAddress, setMarketAddress] = useState<string | null>(null)

  const [fromToken, setFromToken] = useState<string | undefined>(undefined)
  const [toToken, setToToken] = useState<string | undefined>(undefined)
  const [size, setSize] = useState<number | undefined>(undefined)

  const marketInfosbyName = Object.fromEntries(marketInfos.map((market) => [market.symbol, market]))

  const tokenConvertMap: Map<string, Set<string>> = new Map()
  Object.keys(marketInfosbyName).forEach((market) => {
    let [base, quote] = market.split('/')
    !tokenConvertMap.has(base)
      ? tokenConvertMap.set(base, new Set([quote]))
      : tokenConvertMap.set(base, new Set([...(tokenConvertMap.get(base) || []), quote]))
    !tokenConvertMap.has(quote)
      ? tokenConvertMap.set(quote, new Set([base]))
      : tokenConvertMap.set(quote, new Set([...(tokenConvertMap.get(quote) || []), base]))
  })

  const setMarket = (toToken) => {
    const marketInfo = marketInfos
      .filter((marketInfo) => !marketInfo.deprecated)
      .find(
        (marketInfo) =>
          marketInfo.symbol === `${fromToken}/${toToken}` || marketInfo.symbol === `${toToken}/${fromToken}`,
      )
    if (!marketInfo) {
      console.warn(`Could not find market info for market names ${fromToken}/${toToken} or ${toToken}/${fromToken}`)
      notify({
        message: 'Invalid market',
        type: 'error',
      })
      return
    }
    setMarketAddress(marketInfo.address.toBase58())
    setToToken(toToken)
  }

  return (
    <React.Fragment>
      <div className="intro" id="home">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="intro-content">
                <h1>
                  {trText('exchange_title')} <br />
                  with <strong className="text-primary">{trText('main_exchange_advantages_3')}.</strong>
                </h1>
                <p>{trText('exchange_description')}</p>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-12">
              <div className="intro-form-exchange">
                {!connected && (
                  <Row justify="center">
                    <Col>
                      <WalletConnectGuide />
                    </Col>
                  </Row>
                )}
                {tokenConvertMap && connected && (
                  <>
                    <Row style={{ marginBottom: 8, color: '#22223e' }}>
                      <Col>
                        <Select
                          style={{ minWidth: 300 }}
                          placeholder={trText('exchange_select_befor_token_placeholder')}
                          value={fromToken}
                          onChange={(token) => {
                            setFromToken(token)
                            setToToken(undefined)
                          }}
                        >
                          {Array.from(tokenConvertMap.keys()).map((token) => (
                            <Option value={token} key={token}>
                              {token}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    {fromToken && (
                      <Row style={{ marginBottom: 8, color: '#22223e' }}>
                        <Col>
                          <Select
                            style={{ minWidth: 300 }}
                            value={toToken}
                            onChange={setMarket}
                            placeholder={trText('exchange_select_after_token_placeholder')}
                          >
                            {[...(tokenConvertMap.get(fromToken) || [])].map((token) => (
                              <Option value={token} key={token}>
                                {token}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                    )}
                    {fromToken && toToken && (
                      <MarketProvider marketAddress={marketAddress} setMarketAddress={setMarketAddress}>
                        <ConvertFormSubmit
                          size={size}
                          setSize={setSize}
                          fromToken={fromToken}
                          toToken={toToken}
                          wallet={wallet}
                          customMarkets={customMarkets}
                        />
                      </MarketProvider>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

function ConvertFormSubmit({
  size,
  setSize,
  fromToken,
  toToken,
  wallet,
  customMarkets,
}: {
  size: number | null | undefined
  setSize: (newSize: number | undefined) => void
  fromToken: string
  toToken: string
  wallet?: WalletAdapter
  customMarkets: CustomMarketInfo[]
}) {
  const { market } = useMarket()
  const [accounts] = useTokenAccounts()
  const balances = useBalances()
  const [fromAmount, setFromAmount] = useState<number | undefined>()
  const [toAmount, setToAmount] = useState<number | undefined>()
  const { storedFeeDiscountKey: feeDiscountKey } = useLocallyStoredFeeDiscountKey()

  const connection = useConnection()
  const sendConnection = useSendConnection()

  const [isConverting, setIsConverting] = useState(false)

  const isFromTokenBaseOfMarket = (market) => {
    const { marketName } = getMarketDetails(market, customMarkets)
    if (!marketName) {
      throw Error('Cannot determine if coin is quote or base because marketName is missing')
    }
    const [base] = marketName.split('/')
    return fromToken === base
  }

  const onConvert = async () => {
    if (!market) {
      console.warn('Market is null when attempting convert.')
      notify({
        message: 'Invalid market',
        type: 'error',
      })
      return
    }
    // get accounts
    const baseCurrencyAccount = getSelectedTokenAccountForMint(accounts, market?.baseMintAddress)
    const quoteCurrencyAccount = getSelectedTokenAccountForMint(accounts, market?.quoteMintAddress)

    // get approximate price
    let side
    try {
      side = isFromTokenBaseOfMarket(market) ? 'sell' : 'buy'
    } catch (e: any) {
      console.warn(e)
      notify({
        message: 'Error placing order',
        description: e.message,
        type: 'error',
      })
      return
    }

    const sidedOrderbookAccount =
      // @ts-ignore
      side === 'buy' ? market._decoded.asks : market._decoded.bids
    const orderbookData = await connection.getAccountInfo(sidedOrderbookAccount)
    if (!orderbookData?.data) {
      notify({ message: 'Invalid orderbook data', type: 'error' })
      return
    }
    const decodedOrderbookData = Orderbook.decode(market, orderbookData.data)
    const [bbo] = decodedOrderbookData && decodedOrderbookData.getL2(1).map(([price]) => price)
    if (!bbo) {
      notify({ message: 'No best price found', type: 'error' })
      return
    }
    if (!size) {
      notify({ message: 'Size not specified', type: 'error' })
      return
    }

    const tickSizeDecimals = getDecimalCount(market.tickSize)
    const parsedPrice = getMarketOrderPrice(decodedOrderbookData, size, tickSizeDecimals)

    // round size
    const sizeDecimalCount = getDecimalCount(market.minOrderSize)
    const nativeSize = side === 'sell' ? size : size / parsedPrice
    const parsedSize = floorToDecimal(nativeSize, sizeDecimalCount)

    setIsConverting(true)
    try {
      if (!wallet) {
        return null
      }

      await placeOrder({
        side,
        price: parsedPrice,
        size: parsedSize,
        orderType: 'ioc',
        market,
        connection: sendConnection,
        wallet,
        baseCurrencyAccount: baseCurrencyAccount?.pubkey,
        quoteCurrencyAccount: quoteCurrencyAccount?.pubkey,
        feeDiscountPubkey: feeDiscountKey,
      })
    } catch (e: any) {
      console.warn(e)
      notify({
        message: 'Error placing order',
        description: e.message,
        type: 'error',
      })
    } finally {
      setIsConverting(false)
    }
  }

  const getPrice = async () => {
    try {
      const side = isFromTokenBaseOfMarket(market) ? 'sell' : 'buy'
      const sidedOrderbookAccount =
        // @ts-ignore
        side === 'buy' ? market._decoded.asks : market._decoded.bids
      const orderbookData = await connection.getAccountInfo(sidedOrderbookAccount)
      if (!orderbookData?.data || !market) {
        return [null, null]
      }
      const decodedOrderbookData = Orderbook.decode(market, orderbookData.data)
      const [bbo] = decodedOrderbookData && decodedOrderbookData.getL2(1).map(([price]) => price)
      if (!bbo || !size) {
        return [null, null]
      }
      const tickSizeDecimals = getDecimalCount(market.tickSize)
      const expectedPrice = getExpectedFillPrice(decodedOrderbookData, size, tickSizeDecimals)
      if (side === 'buy') {
        return [expectedPrice.toFixed(6), 1]
      } else {
        return [1, expectedPrice.toFixed(6)]
      }
    } catch (e: any) {
      console.log(`Got error ${e}`)
      return [null, null]
    }
  }

  useEffect(
    () => {
      getPrice().then(([fromAmount, toAmount]) => {
        setFromAmount(fromAmount || undefined)
        setToAmount(toAmount || undefined)
      })
    },
    // eslint-disable-next-line
    [market?.address.toBase58(), size],
  )

  const canConvert = market && size && size > 0
  const balance = balances.find((coinBalance) => coinBalance.coin === fromToken)
  const availableBalance = ((balance?.unsettled || 0) + (balance?.wallet || 0)) * 0.99
  const { t: trText } = useTranslation()
  return (
    <React.Fragment>
      <Row style={{ marginBottom: 8, color: '#22223e' }}>
        <Col>
          <Input
            style={{ minWidth: 300 }}
            addonBefore={`${trText('order_size')} (${fromToken})`}
            placeholder={trText('order_size')}
            value={size === null ? undefined : size}
            type="number"
            onChange={(e) => {
              setSize(parseFloat(e.target.value) || undefined)
            }}
          />
        </Col>
      </Row>
      <Row gutter={12} style={{ marginBottom: 8 }}>
        <Col span={12}>
          <ActionButton block size="large" onClick={() => setSize(floorToDecimal(availableBalance, 4))}>
            {trText('exchange_select_max_price')}: {availableBalance.toFixed(4)}
          </ActionButton>
        </Col>
        <Col span={12}>
          <ConvertButton
            block
            type="primary"
            size="large"
            loading={isConverting}
            onClick={onConvert}
            disabled={!canConvert}
          >
            {trText('exchange_convert')}
          </ConvertButton>
        </Col>
      </Row>
      {canConvert && (
        <Row align="middle" justify="center">
          <Col>
            {fromAmount} {fromToken}
          </Col>
          <Col offset={1}>
            <SwapOutlined />
          </Col>
          <Col offset={1}>
            {toAmount} {toToken}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

import { Button, Col, Divider, Popover, Row } from 'antd'
import React, { useState } from 'react'
import FloatingElement from './layout/FloatingElement'
import styled from 'styled-components'
import {
  SUPPORT_FTX_TOKENS,
  useBalances,
  useMarket,
  useSelectedBaseCurrencyAccount,
  useSelectedOpenOrdersAccount,
  useSelectedQuoteCurrencyAccount,
  useTokenAccounts,
} from '../utils/dex-markets'
import DepositDialog from './DepositDialog'
import { useWallet } from '../utils/wallet'
import Link from './Link'
import { settleFunds } from '../utils/send'
import { useDexlabRpcConnection, useDexlabRpcSendConnection } from '../utils/connection'
import { notify } from '../utils/notifications'
import { Balances } from '../utils/types'
import StandaloneTokenAccountsSelect from './StandaloneTokenAccountSelect'
import LinkAddress from './LinkAddress'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useInterval } from '../utils/useInterval'
import { useLocalStorageState } from '../utils/utils'
import { AUTO_SETTLE_DISABLED_OVERRIDE } from '../utils/preferences'
import { useReferrer } from '../utils/referrer'

const RowBox = styled(Row)`
  padding-bottom: 20px;
`

const Tip = styled.p`
  font-size: 12px;
  padding-top: 6px;
`

export default function StandaloneBalancesDisplay() {
  const { baseCurrency, quoteCurrency, market } = useMarket()
  const balances = useBalances()
  const openOrdersAccount = useSelectedOpenOrdersAccount(true)
  const connection = useDexlabRpcConnection()
  const dexlabSendRpcConnection = useDexlabRpcSendConnection()
  const { providerUrl, providerName, wallet, connected } = useWallet()
  const [baseOrQuote, setBaseOrQuote] = useState('')
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount()
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount()
  const [tokenAccounts] = useTokenAccounts()
  const baseCurrencyBalances = balances && balances.find((b) => b.coin === baseCurrency)
  const quoteCurrencyBalances = balances && balances.find((b) => b.coin === quoteCurrency)
  const [autoSettleEnabled] = useLocalStorageState('autoSettleEnabled', true)
  const [lastSettledAt, setLastSettledAt] = useState<number>(0)
  const { usdcRef, usdtRef } = useReferrer()

  async function onSettleFunds() {
    if (!wallet) {
      notify({
        message: 'Wallet not connected',
        description: 'wallet is undefined',
        type: 'error',
      })
      return
    }

    if (!market) {
      notify({
        message: 'Error settling funds',
        description: 'market is undefined',
        type: 'error',
      })
      return
    }
    if (!openOrdersAccount) {
      notify({
        message: 'No settlement amount',
        description: <>There is no amount that can be settled. Check the total remaining settlement amount</>,
        type: 'info',
      })
      return
    }
    if (!baseCurrencyAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Failed to get wallet information. Please refresh or reconnect your wallet.',
        type: 'error',
      })
      return
    }
    if (!quoteCurrencyAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Failed to get wallet information. Please refresh or reconnect your wallet.',
        type: 'error',
      })
      return
    }

    try {
      await settleFunds({
        market,
        openOrders: openOrdersAccount,
        connection: dexlabSendRpcConnection,
        wallet,
        baseCurrencyAccount,
        quoteCurrencyAccount,
        usdcRef,
        usdtRef,
      })
    } catch (e: any) {
      notify({
        message: 'Error settling funds',
        description: e.message,
        type: 'error',
      })
    }
  }

  useInterval(() => {
    const autoSettle = async () => {
      if (
        AUTO_SETTLE_DISABLED_OVERRIDE ||
        !wallet ||
        !market ||
        !openOrdersAccount ||
        !baseCurrencyAccount ||
        !quoteCurrencyAccount ||
        !autoSettleEnabled
      ) {
        return
      }
      if (!baseCurrencyBalances?.unsettled && !quoteCurrencyBalances?.unsettled) {
        return
      }
      if (Date.now() - lastSettledAt < 15000) {
        return
      }
      try {
        // console.log('Settling funds...')
        setLastSettledAt(Date.now())
        await settleFunds({
          market,
          openOrders: openOrdersAccount,
          connection,
          wallet,
          baseCurrencyAccount,
          quoteCurrencyAccount,
          usdcRef,
          usdtRef,
        })
      } catch (e: any) {
        console.log('Error auto settling funds: ' + e.message)
        return
      }
      // console.log('Finished settling funds.')
    }
    connected && wallet?.autoApprove && autoSettleEnabled && autoSettle()
  }, 1000)

  const formattedBalances: [string | undefined, Balances | undefined, string, string | undefined][] = [
    [baseCurrency, baseCurrencyBalances, 'base', market?.baseMintAddress.toBase58()],
    [quoteCurrency, quoteCurrencyBalances, 'quote', market?.quoteMintAddress.toBase58()],
  ]

  // function depositFtxForSOL(depositCoin, address) {
  //   if (wallet && connected) {
  //     const popUrl = `https://ftx.com/pay/request?coin=${depositCoin}&address=${address}&tag=&wallet=sol&memoIsRequired=true&memo=Deposit via Dexlab site&fixedWidth=true`
  //     const popOption = 'width=450, height=700, resizable=no, scrollbars=yes, status=no'
  //     window.open(popUrl, '', popOption)
  //   }
  // }

  function DepositButton({ baseOrQuote }) {
    if (baseOrQuote === 'base') {
      // if (SUPPORT_FTX_TOKENS.includes(baseCurrency ?? '')) {
      //   return (
      //     <Button
      //       style={{ height: '40px' }}
      //       shape="round"
      //       onClick={() => {
      //         if (baseCurrency) {
      //           if (baseCurrencyAccount) {
      //             depositFtxForSOL(baseCurrency, baseCurrencyAccount.pubkey.toBase58())
      //           } else {
      //             notify({
      //               message: 'Info',
      //               description: `Create a ${baseCurrency} wallet.`,
      //               type: 'info',
      //             })
      //           }
      //         }
      //       }}
      //     >
      //       Deposit {baseCurrency} with FTX
      //     </Button>
      //   )
      // } else {
      return (
        <Button shape="round" block size="large" onClick={() => setBaseOrQuote(baseOrQuote)}>
          Deposit
        </Button>
      )
      // }
    } else if (baseOrQuote === 'quote') {
      // if (SUPPORT_FTX_TOKENS.includes(quoteCurrency ?? '')) {
      //   return (
      //     <Button
      //       style={{ height: '40px' }}
      //       shape="round"
      //       onClick={() => {
      //         if (quoteCurrency) {
      //           if (quoteCurrencyAccount) {
      //             depositFtxForSOL(quoteCurrency, quoteCurrencyAccount.pubkey.toBase58())
      //           } else {
      //             notify({
      //               message: 'Info',
      //               description: `Create a ${quoteCurrency} wallet.`,
      //               type: 'info',
      //             })
      //           }
      //         }
      //       }}
      //     >
      //       Deposit {quoteCurrency} with FTX
      //     </Button>
      //   )
      // } else {
      return (
        <Button shape="round" block size="large" onClick={() => setBaseOrQuote(baseOrQuote)}>
          Deposit
        </Button>
      )
      // }
    }
    return null
  }

  return (
    // @ts-ignore
    <FloatingElement style={{ flex: 1, paddingTop: 10 }}>
      {formattedBalances.map(([currency, balances, baseOrQuote, mint], index) => (
        <React.Fragment key={index}>
          <Divider style={{ borderColor: 'white' }}>
            {currency}{' '}
            {mint && (
              <Popover
                content={<LinkAddress address={mint} />}
                placement="bottomRight"
                title="Token mint"
                trigger="hover"
              >
                <InfoCircleOutlined style={{ color: '#2abdd2' }} />
              </Popover>
            )}
          </Divider>
          {connected && (
            <RowBox align="middle" style={{ paddingBottom: 10 }}>
              <StandaloneTokenAccountsSelect
                accounts={tokenAccounts?.filter((account) => account.effectiveMint.toBase58() === mint)}
                mint={mint}
                label
              />
            </RowBox>
          )}
          <RowBox align="middle" justify="space-between" style={{ paddingBottom: 12 }}>
            <Col>Wallet balance:</Col>
            <Col>{balances && balances.wallet}</Col>
          </RowBox>
          <RowBox align="middle" justify="space-between" style={{ paddingBottom: 12 }}>
            <Col>Unsettled balance:</Col>
            <Col>{balances && balances.unsettled}</Col>
          </RowBox>
          <RowBox align="middle" justify="space-around">
            <Col style={{ width: 150 }}>
              <DepositButton baseOrQuote={baseOrQuote} />
            </Col>
            <Col style={{ width: 150 }}>
              <Button shape="round" block size="large" onClick={onSettleFunds}>
                Settle
              </Button>
            </Col>
          </RowBox>
          {baseOrQuote === 'base' ? (
            <Tip>
              All deposits go to your{' '}
              <Link external to={providerUrl}>
                {providerName}
              </Link>{' '}
              wallet
            </Tip>
          ) : (
            ''
            // <Tip>
            //   Learn about{' '}
            //   <Link
            //     external
            //     to={'https://docs.dexlab.space/products/getting-started/wallet-tutorial/deposit-with-ftx-pay'}
            //   >
            //     FTX Pay
            //   </Link>
            // </Tip>
          )}
        </React.Fragment>
      ))}
      <DepositDialog baseOrQuote={baseOrQuote} onClose={() => setBaseOrQuote('')} />
    </FloatingElement>
  )
}

import { Button } from 'antd'
import React, { useContext } from 'react'
import { useTokenAccounts, getSelectedTokenAccountForMint } from '../../utils/dex-markets'
import DataTable from '../layout/DataTable'
import { useSendConnection } from '../../utils/connection'
import { useWallet } from '../../utils/wallet'
import { settleFunds } from '../../utils/send'
import { notify } from '../../utils/notifications'
import { ThemeContext, isDark } from '../../utils/Contexts/ThemeContext'
import { useTranslation } from 'react-i18next'
import NotConnectView from '../../componentsv2/element/NotConnectView'
import { useReferrer } from '../../utils/referrer'

export default function BalancesTable({
  balances,
  showMarket,
  hideWalletBalance,
  onSettleSuccess,
}: {
  balances: any[]
  showMarket?: any
  hideWalletBalance?: any
  onSettleSuccess?: () => void
}) {
  const [theme] = useContext(ThemeContext)
  const { t: trText } = useTranslation()

  const [accounts] = useTokenAccounts()
  const connection = useSendConnection()
  const { wallet, connected } = useWallet()
  const { usdcRef, usdtRef } = useReferrer()

  async function onSettleFunds(market, openOrders) {
    try {
      await settleFunds({
        market,
        openOrders,
        connection,
        wallet: wallet!!,
        baseCurrencyAccount: getSelectedTokenAccountForMint(accounts, market?.baseMintAddress)!!,
        quoteCurrencyAccount: getSelectedTokenAccountForMint(accounts, market?.quoteMintAddress)!!,
        usdcRef,
        usdtRef,
      })
    } catch (e: any) {
      notify({
        message: trText('toast_message_wallet_settle_error'),
        description: e.message,
        type: 'error',
      })
      return
    }
    onSettleSuccess && onSettleSuccess()
  }

  const columns = [
    showMarket
      ? {
          title: trText('market'),
          dataIndex: 'marketName',
          key: 'marketName',
        }
      : null,
    {
      title: trText('my_wallet_balance_coin'),
      dataIndex: 'coin',
      key: 'coin',
    },
    hideWalletBalance
      ? null
      : {
          title: trText('my_wallet_balance'),
          dataIndex: 'wallet',
          key: 'wallet',
        },
    {
      title: trText('orders'),
      dataIndex: 'orders',
      key: 'orders',
    },
    {
      title: trText('standalone_balances_unsettled_balance'),
      dataIndex: 'unsettled',
      key: 'unsettled',
    },
    {
      key: 'action',
      render: ({ market, openOrders, marketName }) => (
        <div style={{ textAlign: 'right' }}>
          <Button
            ghost
            style={{ marginRight: 12, color: isDark(theme) ? '#FFFFFF' : '#22223e', border: '1px solid #22223e' }}
            onClick={() => onSettleFunds(market, openOrders)}
          >
            {trText('standalone_balances_settle')} {marketName}
          </Button>
        </div>
      ),
    },
  ].filter((x) => x)
  return connected ? (
    <DataTable emptyLabel={trText('my_wallet_no_balance')} dataSource={balances} columns={columns} pagination={false} />
  ) : (
    <NotConnectView />
  )
}

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { SOLANA_EXPLORER_URL } from '../application'
import { Avatar, Descriptions, Row, Col, Button, Tabs, Collapse, Table, Tooltip, Alert, Spin, Skeleton } from 'antd'
import { HelpOutline } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useConnection } from '../utils/connection'
import { useWallet } from '../utils/wallet'
import { numberWithCommasNormal } from '../utils/dexlab-utils'
import { useWalletBalanceByAccount } from '../utils/dex-markets'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import { getTokenSymbolImageUrl } from '../utils/tokens-v2'
import { useSendTransaction } from '../utils/tokens/notifications'
import { notify } from '../utils/notifications'
import DexLabIdoApi from '../utils/client/dexlabIdoApiConnector'
import DexlabWhitelistApi from '../utils/client/dexlabWhitelistApiConnector'
import {
  DexlabIdoMarketDetail,
  EventSaleStatus,
  withdrawTypeText,
  DexlabIdoFaqResponse,
  MyIdoWhitelistResponse,
  DexlabWhitelistStakeRankResponse,
} from '../utils/types'
import BuyIdoTokenModal from '../componentsv2/BuyIdoTokenModal'
import IdoTimelineV2View, { getStepCurrent, isWhitelist } from '../componentsv2/IdoTimelineV2View'

const { Panel } = Collapse
const { TabPane } = Tabs

enum PROCESS_STEP {
  NONE = 0,
  STEP_1 = 1,
  STEP_2 = 2,
  DONE = 4,
  ERROR = 9,
}

export default function IdoPageV3() {
  document.title = `IDO Token Sale - The best DEX platform on SOLANA.`

  const { i18n } = useTranslation()
  const { marketId } = useParams()
  const { wallet, connected, select } = useWallet()

  const [saleMarketInfo, setSaleMarketInfo] = useState<DexlabIdoMarketDetail | undefined>(undefined)
  const [idoFaqList, setIdoFaqList] = useState<DexlabIdoFaqResponse[]>([])
  const [whiteListWinner, setWhiteListWinner] = useState<MyIdoWhitelistResponse | undefined>(undefined)
  const [whiteListRank, setWhiteListRank] = useState<DexlabWhitelistStakeRankResponse | undefined | null>(undefined)
  const [idoStep, setIdoStep] = useState(0)

  let is1SolMarket = false
  if (marketId === '65328b57-e795-4afe-acdb-fbaf5e12caa9') {
    is1SolMarket = true
  }

  // 자동 새로고침 등록
  useEffect(() => {
    getIdoMarketInfo() // IDO 정보
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getIdoFaqList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  useEffect(() => {
    if (wallet && wallet?.connected) {
      getIdoMarketInfo() // IDO 정보 재조회
      getWhiteListChackWinner() // 당첨여부 확인
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.connected])

  useEffect(() => {
    if (saleMarketInfo) {
      const currentStep = getStepCurrent(saleMarketInfo)
      setIdoStep(currentStep)
      if (getStepCurrent(saleMarketInfo) === 0) {
        getWhiteListRank() // 화리 랭킹
      }
    }
  }, [saleMarketInfo])

  async function getIdoFaqList() {
    const response = await DexLabIdoApi.getIdoFaqList(i18n.language)
    if (response) {
      setIdoFaqList(response)
    }
  }

  async function refreshAllData() {
    getWhiteListRank()
    getWhiteListChackWinner()
    getIdoMarketInfo()
    notify({
      message: 'Refresh successful!',
      type: 'success',
    })
  }

  // 화리 랭킹 조회
  async function getWhiteListRank() {
    try {
      const response = await DexlabWhitelistApi.getWhitelistApplyStakeRank(
        saleMarketInfo!!.whitelistInfo?.whitelistId ?? '',
      )
      if (response) {
        setWhiteListRank(response)
      }
    } catch (e: any) {
      console.log(e.message)
    }
  }

  async function getWhiteListChackWinner() {
    const publicKey = connected && wallet ? wallet.publicKey.toBase58() : undefined
    if (publicKey) {
      const response = await DexLabIdoApi.checkWhiteListWinner(marketId, publicKey)
      if (response) {
        setWhiteListWinner(response)
      }
    }
  }

  async function getIdoMarketInfo() {
    const publicKey = connected && wallet ? wallet.publicKey.toBase58() : undefined
    const response = await DexLabIdoApi.getIdoMarket(marketId, publicKey)
    try {
      if (response) {
        if (response.version === 'V1') {
          window.location.href = `/#/ido/${response.id}`
        } else if (response.version === 'V2') {
          window.location.href = `/#/v2/ido/${response.id}`
        } else {
          setSaleMarketInfo(response)
        }
      } else {
        notify({
          message: 'Temporarily unable to load market information. Please try again in a few minutes',
          type: 'error',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <FloatingElement
      // @ts-ignore
      style={{ flex: 1, paddingTop: 30 }}
    >
      {!_.isEmpty(saleMarketInfo) && (
        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
          {is1SolMarket && (
            <Alert
              type="success"
              message={
                <div style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Purchased tokens will be airdropped by the 1Sol team on TGE.
                  </span>
                  <br />
                  Check out the 1Sol community.
                </div>
              }
            />
          )}
          <SaleDescription
            connected={connected}
            connect={select}
            idoFaqList={idoFaqList}
            saleMarketInfo={saleMarketInfo}
            whiteListWinner={whiteListWinner}
            whiteListRank={whiteListRank}
            idoStep={idoStep}
            walletAddress={connected && wallet ? wallet.publicKey.toBase58() : undefined}
            reloadMarketInfo={getIdoMarketInfo}
            refreshAllData={refreshAllData}
          />
        </div>
      )}
    </FloatingElement>
  )
}

function IdoPageInner({
  marketId,
  saleMarketInfo,
  buyModalVisible,
  reloadMarketInfo,
  whiteListWinner,
  setBuyModalVisible,
}) {
  const { wallet, connected } = useWallet()
  const connection = useConnection()

  const [loadingStep, setLoadingStep] = useState<PROCESS_STEP>(PROCESS_STEP.NONE)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(true)
  const [sendTransaction, sending] = useSendTransaction()

  useEffect(() => {
    if (loadingStep === PROCESS_STEP.NONE || loadingStep === PROCESS_STEP.DONE || loadingStep === PROCESS_STEP.ERROR) {
      // saleMarketInfo reload
      reloadMarketInfo()
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [loadingStep])

  let currentIdoWallet: any[] = [] // IDO 토큰 지갑

  // 예를들어 DXL 토큰지갑이 있는지 확인한다.
  if (saleMarketInfo.baseTokenMint) {
    const baseTokenWalletInfo = useWalletBalanceByAccount(
      saleMarketInfo.baseTokenMint,
      saleMarketInfo.baseTokenDecimals,
    )
    currentIdoWallet = baseTokenWalletInfo ? [baseTokenWalletInfo] : []
  }

  function onBuyModalClose() {
    setLoadingStep(PROCESS_STEP.NONE)
    setBuyModalVisible(false)
  }

  function onCreateTxId({ orderId, preOrder }) {
    // console.log(`update txid ${orderId}`)
    // console.log(`quoteUsdtPrice: ${preOrder.quoteUsdtPrice}`)
    setLoadingStep(PROCESS_STEP.STEP_1)
  }

  function onSuccess({ ownerPublicKey, signature, preOrder }) {
    notify({ message: 'Confirming transaction...', type: 'info' })
    setTimeout(() => {
      setLoadingStep(PROCESS_STEP.STEP_2)
      // 참여완료 처리(실패시 1초 단위로 재시도)
      DexLabIdoApi.orderConfirm(preOrder.orderId, {
        marketId,
        txId: signature,
        ownerPublicKey: ownerPublicKey.toBase58(),
      })
        .then((response) => {
          if (response) {
            notify({
              message: `Participation is complete. Your OrderID: ${response.orderId}`,
              type: 'success',
            })
            setLoadingStep(PROCESS_STEP.DONE)
          } else {
            notify({
              message: `An error has occurred. Please contact DEXLAB support. Your OrderID: ${preOrder.orderid}`,
              type: 'error',
            })
            setLoadingStep(PROCESS_STEP.ERROR)
          }
        })
        .catch((e) => {
          notify({
            message: `An error has occurred. Please contact DEXLAB support. Your OrderID: ${preOrder.orderid}`,
            type: 'error',
          })
          setLoadingStep(PROCESS_STEP.ERROR)
        })
    }, 1000)
  }

  function onError({ ownerPublicKey, error }) {
    setLoadingStep(PROCESS_STEP.ERROR)
    console.log(error)
    console.log(`onError!!! ${ownerPublicKey}, e: ${error.message}`)
    setErrorMessage(error.message)
    notify({
      message: `${error.message}`,
      type: 'error',
    })
  }

  async function onIdoSubmit({ fromPubkey, baseAmount, quoteAmount }) {
    setLoadingStep(PROCESS_STEP.NONE)
    if (!connected) {
      notify({ message: 'Wallet connection required.', type: 'error' })
      return
    }

    if (!saleMarketInfo) {
      notify({ message: 'Market is Not found.', type: 'error' })
      return
    }

    if (!baseAmount || Number(baseAmount) === Number(0)) {
      notify({ message: 'Enter the purchase quantity.', type: 'error' })
      return
    }

    console.log(`Sending: ${sending}`)
    setLoadingStep(PROCESS_STEP.STEP_1)
    return sendTransaction(
      { wallet, fromPubkey, connection, currentIdoWallet, saleMarketInfo },
      {
        ownerPublicKey: wallet?.publicKey,
        amount: parseFloat(quoteAmount),
      },
      { onCreateTxId, onSuccess, onError },
    )
  }

  return (
    // @ts-ignore
    <FloatingElement style={{ flex: 1, paddingTop: 10 }}>
      {saleMarketInfo && (
        <BuyIdoTokenModal
          visible={buyModalVisible}
          saleMarketInfo={saleMarketInfo}
          whiteListWinner={whiteListWinner}
          errorMessage={errorMessage}
          loadingStep={loadingStep}
          isLoading={isLoading}
          onClose={onBuyModalClose}
          onIdoSubmit={onIdoSubmit}
        />
      )}
    </FloatingElement>
  )
}

function SaleDescription({
  saleMarketInfo,
  idoFaqList,
  connected,
  connect,
  idoStep,
  reloadMarketInfo,
  walletAddress,
  whiteListWinner,
  whiteListRank,
  refreshAllData,
}) {
  const { t: trText } = useTranslation()
  const [buyModalVisible, setBuyModalVisible] = useState(false)

  const sDate = saleMarketInfo.startDate ? moment(saleMarketInfo.startDate).utc(false) : undefined
  const eDate = saleMarketInfo.endDate ? moment(saleMarketInfo.endDate).utc(false) : undefined

  function BuyBotton() {
    if (!connected && saleMarketInfo.saleStatus === EventSaleStatus.ON_SALE) {
      return (
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={() => {
            connect()
          }}
        >
          {trText('ido_connect_wallet')}
        </Button>
      )
    } else if (saleMarketInfo.saleStatus === EventSaleStatus.UP_COMING) {
      return saleMarketInfo.whitelistInfo && saleMarketInfo.whitelistInfo.progress ? (
        walletAddress ? (
          <Button
            type="primary"
            shape="round"
            size={'large'}
            onClick={() => {
              if (!saleMarketInfo.whitelistInfo.participation) {
                const whitelistUrl = `https://apply-whitelist.dexlab.space/#/survey?id=${saleMarketInfo.whitelistInfo.whitelistId}&walletAddress=${walletAddress}`
                window.open(whitelistUrl)
              } else {
                notify({
                  message: `Participation has been completed :)`,
                  type: 'success',
                })
              }
            }}
          >
            {saleMarketInfo.whitelistInfo.participation
              ? 'Participated!'
              : saleMarketInfo.saleType === 'WHITELIST_FCFS'
              ? 'Apply FCFS'
              : 'Apply Whitelist'}
          </Button>
        ) : (
          <Button
            type="primary"
            shape="round"
            size={'large'}
            onClick={() => {
              connect()
            }}
          >
            {trText('ido_connect_wallet')}
          </Button>
        )
      ) : (
        <Button disabled={true} type="primary" shape="round" size={'large'}>
          {trText('ido_coming_sale')}
        </Button>
      )
    } else if (saleMarketInfo.saleStatus === EventSaleStatus.END_SALE || idoStep === 3) {
      return (
        <Button disabled={true} type="primary" shape="round" size={'large'}>
          {trText('ido_end_of_sale')}
        </Button>
      )
    } else {
      let isBottonDisable = saleMarketInfo.saleStatus !== 'ON_SALE' || (connected && !whiteListWinner) || idoStep !== 2

      if (saleMarketInfo.saleStatus === EventSaleStatus.ON_SALE && idoStep === 2) {
        let btnText = ''
        if (!whiteListWinner) {
          // if (!isKycLoading) {
          btnText = `Didn't win the lottery`
          // }
        } else {
          if (saleMarketInfo.saleStatus === EventSaleStatus.ON_SALE) {
            // 잔여수량 검증
            if (Number(saleMarketInfo.availableQuantity) <= 0) {
              btnText = `Sold Out`
              isBottonDisable = true
            } else {
              if (
                whiteListWinner &&
                Number(whiteListWinner.purchaseAvailableQuantity) - Number(saleMarketInfo.purchaseQuantity) > Number(0)
              ) {
                btnText = `Buy ${saleMarketInfo.base}`
              } else {
                isBottonDisable = true
                btnText = `Purchase Complete`
              }
            }
          }
        }
        return (
          <Button
            disabled={isBottonDisable}
            type="primary"
            shape="round"
            size={'large'}
            onClick={() => {
              setBuyModalVisible(true)
            }}
          >
            {btnText}
          </Button>
        )
      } else {
        return (
          <Button disabled={isBottonDisable} type="primary" shape="round" size={'large'}>
            {trText('ido_to_be_sold')}
          </Button>
        )
      }
    }
  }

  const holderRankTableColumns = [
    {
      title: 'Ranking',
      dataIndex: 'rankNo',
      key: 'rankNo',
      render: (rankNo) => {
        let rankText = rankNo
        if (rankNo <= 3) {
          if (rankNo === 1) {
            rankText = <span style={{ fontSize: '40px' }}>🥇</span>
          } else if (rankNo === 2) {
            rankText = <span style={{ fontSize: '35px' }}>🥈</span>
          } else if (rankNo === 3) {
            rankText = <span style={{ fontSize: '30px' }}>🥉</span>
          }
        }
        return rankText
      },
    },
    {
      title: 'Address',
      dataIndex: 'displayWalletAddress',
      key: 'displayWalletAddress',
    },
    {
      title: 'Staked DXL',
      dataIndex: 'stakedBalance',
      key: 'stakedBalance',
      render: (stakedBalance) => {
        return (
          <>
            <span>{numberWithCommasNormal(stakedBalance)}</span>
            <span style={{ fontSize: '10px', color: '#646464' }}>DXL</span>
          </>
        )
      },
    },
    {
      title: 'Winning Quantity',
      render: (item) => {
        if (item.quotaQuantity === 0) {
          return (
            <span style={{ textAlign: 'center' }}>
              Lottery target{' '}
              <Tooltip
                title={
                  <>
                    The lottery conditions have been met.
                    <br />
                    You can win by lottery.
                  </>
                }
              >
                <HelpOutline style={{ fontSize: '20px' }} />
              </Tooltip>
            </span>
          )
        } else {
          return (
            <span style={{ textAlign: 'center' }}>
              {numberWithCommasNormal(item.quotaQuantity)}
              <span style={{ fontSize: '10px', color: '#646464' }}>{saleMarketInfo.base}</span>
              <div style={{ textAlign: 'left', fontSize: '12px' }}>
                <span style={{ fontSize: '10px', color: '#646464' }}>
                  {numberWithCommasNormal(
                    parseFloat((item.quotaQuantity * parseFloat(saleMarketInfo.unitSalePriceUsd)).toFixed(6)),
                  )}{' '}
                  USDC
                </span>
              </div>
            </span>
          )
        }
      },
    },
  ]

  let myRank = 0
  if (whiteListRank && !_.isEmpty(whiteListRank.ranks) && walletAddress) {
    const findMyRank = whiteListRank.ranks.find((rf) => rf.orgWalletAddress === walletAddress)
    if (findMyRank) {
      myRank = findMyRank.rankNo
    }
  }

  function isRankView() {
    if (saleMarketInfo && saleMarketInfo.whitelistInfo) {
      const todayDate = moment().utc()
      const whitelistStartDate = moment(saleMarketInfo.whitelistInfo.startDate).utc(true)
      const whitelistEndDate = moment(saleMarketInfo.whitelistInfo.endDate).utc(true)

      const whitelistWinnerAnnouncementDate = moment(saleMarketInfo.winnerAnnouncementDate).utc(false)

      const tokenSaleStartDate = moment(saleMarketInfo.startDate).utc(false)
      const tokenSaleEndDate = moment(saleMarketInfo.endDate).utc(false)

      if (todayDate.isBetween(whitelistStartDate, whitelistEndDate)) {
        return true
      }

      if (todayDate.isBetween(whitelistWinnerAnnouncementDate, tokenSaleStartDate)) {
        return true
      }

      if (todayDate.isBetween(tokenSaleStartDate, tokenSaleEndDate)) {
        return true
      }

      if (saleMarketInfo.withdrawDate) {
        const tokenWithdrawDate = moment(saleMarketInfo.withdrawDate).utc(false)
        if (todayDate.isBetween(tokenSaleEndDate, tokenWithdrawDate) || todayDate.isAfter(tokenWithdrawDate)) {
          return true
        }
      } else {
        return true
      }

      if (whiteListRank && !_.isEmpty(whiteListRank.ranks)) {
        return true
      }
    }
    return false
  }

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Row justify="center" style={{ marginTop: '30px' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Row style={{ textAlign: 'center' }}>
              <Col span={24}>
                <Avatar.Group>
                  <Avatar size={50} style={{ marginRight: '5px', marginBottom: '5px' }} src={saleMarketInfo.logoUrl} />
                  <Avatar
                    size={50}
                    style={{ marginRight: '5px', marginBottom: '5px' }}
                    src={getTokenSymbolImageUrl(saleMarketInfo.quote)}
                  />
                </Avatar.Group>
              </Col>
              <Col span={24}>
                <span style={{ fontSize: '28px', fontWeight: 'bold' }}>{`${saleMarketInfo.name}`}</span>
              </Col>
              <Col span={24}>
                {saleMarketInfo.whitelistInfo && saleMarketInfo.whitelistInfo.startDate && eDate ? (
                  <>
                    {saleMarketInfo.whitelistInfo.startDate}(UTC) ~ {eDate.format('YYYY-MM-DD HH:mm:ss')}(UTC)
                  </>
                ) : null}
              </Col>
              <Col span={24}>
                <span style={{ color: '#76cbff' }}>
                  {saleMarketInfo.baseTokenMint ? saleMarketInfo.baseTokenMint : ''}
                </span>
              </Col>
              <Col span={24} style={{ marginTop: '30px' }}>
                <BuyBotton />
                <Button
                  style={{ marginLeft: '5px' }}
                  shape="round"
                  size={'large'}
                  onClick={() => {
                    if (saleMarketInfo.baseTokenMint) {
                      window.open(`${SOLANA_EXPLORER_URL}/address/${saleMarketInfo.baseTokenMint}`)
                    } else {
                      window.alert(`TBA`)
                    }
                  }}
                >
                  {trText('ido_view_explorer')}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {saleMarketInfo && saleMarketInfo.whitelistInfo ? (
              <IdoTimelineV2View
                saleMarketInfo={saleMarketInfo}
                reloadMarketInfo={reloadMarketInfo}
                whiteListWinner={whiteListWinner}
                refreshAllData={refreshAllData}
              />
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={12}>
            {saleMarketInfo.saleType === 'WHITELIST_RANK' ? (
              <div style={{ marginTop: '30px', textAlign: 'center' }}>
                {idoStep === 0 && <h3>{trText('ido_v2_rank_title')}</h3>}
                {isRankView() ? (
                  <>
                    {idoStep === 0 && (
                      <Alert
                        type={isWhitelist(saleMarketInfo) ? 'success' : 'info'}
                        message={
                          !isWhitelist(saleMarketInfo) ? (
                            <>
                              Those people who are not rankers, they will automatically be assigned to the lottery pool
                            </>
                          ) : (
                            <>
                              We will validate the mission and announce the final winner soon.{' '}
                              <Spin style={{ marginLeft: '6px' }} />
                            </>
                          )
                        }
                      />
                    )}
                    <span style={{ color: '#646464', fontSize: '12px', textAlign: 'center' }}>
                      {whiteListRank && !_.isEmpty(whiteListRank.ranks) && `It is updated at specific intervals.`}
                    </span>
                    <div>
                      {whiteListRank && !_.isEmpty(whiteListRank.ranks) && idoStep === 0 ? (
                        <>
                          {walletAddress && (
                            <div>
                              <span style={{ fontSize: '15px' }}>
                                {myRank !== 0 ? (
                                  <>
                                    It is ranked <span style={{ fontWeight: 'bold' }}>{myRank}</span>
                                    th out of a total of{' '}
                                    <span style={{ color: '#646464' }}>
                                      {numberWithCommasNormal(whiteListRank.total)}
                                    </span>{' '}
                                    applicants
                                  </>
                                ) : null}
                              </span>
                            </div>
                          )}
                          <div style={{ textAlign: 'center', padding: '15px' }}>
                            <Table
                              className="table-striped-rows"
                              columns={holderRankTableColumns}
                              dataSource={whiteListRank.ranks.map((rank, idx) => {
                                return {
                                  key: idx,
                                  ...rank,
                                }
                              })}
                              pagination={{ pageSize: 13 }}
                            />
                          </div>
                        </>
                      ) : (
                        idoStep === 0 && (
                          <div style={{ marginTop: '30px' }}>
                            <Spin />
                          </div>
                        )
                      )}
                      {whiteListWinner && connected && idoStep > 0 ? (
                        <>
                          <span style={{ fontSize: '20px', marginTop: '10px' }}>
                            <b>🎉 Congratulations! 🎉</b>
                            <br />
                            You have won the IDO sale.
                          </span>
                          <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <span style={{ fontWeight: 'bold' }}>Allocation quantity</span>
                            <div>
                              {numberWithCommasNormal(parseFloat(`${whiteListWinner.purchaseAvailableQuantity}`))}{' '}
                              {saleMarketInfo.base}
                            </div>
                          </div>
                          <Skeleton />
                        </>
                      ) : !whiteListWinner && connected && idoStep > 0 ? (
                        <span style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '10px' }}>
                          Didn't Win. 😞
                          <Skeleton />
                        </span>
                      ) : idoStep === 3 ? (
                        <span style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '10px' }}>
                          Please check the token distribution schedule.
                          <Skeleton />
                        </span>
                      ) : (
                        idoStep > 0 && (
                          <Alert
                            style={{ marginTop: '10px' }}
                            type={'success'}
                            message={
                              <>
                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                  The winners have been announced.
                                </span>
                                <br />
                                <br /> Rankers who do not meet the mission conditions will be excluded from IDO
                                participation.
                                <br />
                                Please check the winning result by connecting your wallet.
                              </>
                            }
                          />
                        )
                      )}
                    </div>
                  </>
                ) : whiteListRank && !_.isEmpty(whiteListRank.ranks) ? (
                  <Alert message={<>Rankers and lottery winners will be confirmed when the winner is announced.</>} />
                ) : (
                  <Alert type="success" message={<>Ranking is being prepared.</>} />
                )}
              </div>
            ) : (
              <div style={{ marginTop: '230px', textAlign: 'center' }}>
                <h3>
                  🍀 {saleMarketInfo.saleType === 'WHITELIST_FCFS' ? 'First-Come-First-Served pool' : 'Standard Pool'}{' '}
                  🍀
                </h3>
                <span style={{ color: '#646464' }}>
                  {saleMarketInfo.saleType === 'WHITELIST_FCFS' ? (
                    <>You can purchase on a first-come, first-served basis until the remaining stock is exhausted.</>
                  ) : (
                    <>It is possible to participate in duplicate with the Rank Pool.</>
                  )}
                </span>
              </div>
            )}
          </Col>
          <Col xs={24} sm={24} md={24}>
            {saleMarketInfo && (
              <Tabs style={{ minHeight: '600px' }} defaultActiveKey="about">
                <TabPane tab={<span>{trText('ido_about_project')}</span>} key="about">
                  <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>
                    {trText('ido_about_project')}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: saleMarketInfo.desc }}></div>
                  <div style={{ marginBottom: '100px', marginTop: '30px', textAlign: 'center' }}>
                    {saleMarketInfo.community &&
                      saleMarketInfo.community.map(({ type, value }, idx) => {
                        return (
                          <>
                            <Button
                              key={idx}
                              style={{ marginLeft: '5px' }}
                              shape="round"
                              size="middle"
                              onClick={() => {
                                window.open(value)
                              }}
                            >
                              {type.replace('_', ' ')}
                            </Button>
                          </>
                        )
                      })}
                  </div>
                </TabPane>
                <TabPane tab={<span>{trText('ido_sale_detail')}</span>} key="market_info">
                  <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>
                    {trText('ido_sale_detail')}
                  </div>
                  <Descriptions bordered>
                    <Descriptions.Item label={trText('ido_token_info_name')}>{saleMarketInfo.name}</Descriptions.Item>
                    <Descriptions.Item label={trText('ido_token_info_type')}>Solana(SPL)</Descriptions.Item>
                    <Descriptions.Item label={`Participate`}>
                      {getSaleTypeText(saleMarketInfo.saleType)}
                    </Descriptions.Item>
                    <Descriptions.Item label={trText('ido_token_info_withdraw_type')}>
                      {withdrawTypeText(saleMarketInfo.withdrawType)}
                    </Descriptions.Item>

                    <Descriptions.Item label={`Whitelist Start`}>
                      {saleMarketInfo.whitelistInfo && saleMarketInfo.whitelistInfo.startDate ? (
                        <>{saleMarketInfo.whitelistInfo.startDate}(UTC)</>
                      ) : (
                        <>TBA</>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Whitelist End'}>
                      {saleMarketInfo.whitelistInfo && saleMarketInfo.whitelistInfo.endDate ? (
                        <>{saleMarketInfo.whitelistInfo.endDate}(UTC)</>
                      ) : (
                        <>TBA</>
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item label={`Sale Start`}>
                      {sDate ? <>{sDate.format('YYYY-MM-DD HH:mm:ss')}(UTC)</> : 'TBA'}
                    </Descriptions.Item>
                    <Descriptions.Item label={`Sale End`}>
                      {eDate ? <>{eDate.format('YYYY-MM-DD HH:mm:ss')}(UTC)</> : 'TBA'}
                    </Descriptions.Item>
                    <Descriptions.Item label={`Distribution`}>
                      {saleMarketInfo.withdrawDate
                        ? `${moment(saleMarketInfo.withdrawDate).utc(false).format('YYYY-MM-DD HH:mm:ss')}(UTC) ~ `
                        : 'TBA'}
                    </Descriptions.Item>
                    <Descriptions.Item span={6} label={trText('ido_token_info_sale_token')}>
                      <span>
                        {saleMarketInfo.base} - {saleMarketInfo.quote}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item span={6} label={trText('ido_token_info_unit_price')}>
                      <span>
                        {Number(saleMarketInfo.unitSalePriceUsd) > 0
                          ? `$${parseFloat(
                              Number(saleMarketInfo.unitSalePriceUsd).toFixed(saleMarketInfo.baseTokenDecimals),
                            )}`
                          : 'TBA'}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item span={12} label={trText('ido_token_info_sale_size')}>
                      <span>
                        {saleMarketInfo.minQuantity !== 0
                          ? saleMarketInfo.minQuantity !== saleMarketInfo.maxQuantity
                            ? `${numberWithCommasNormal(saleMarketInfo.minQuantity)} ~ ${numberWithCommasNormal(
                                saleMarketInfo.maxQuantity,
                              )} ${saleMarketInfo.base}`
                            : `${numberWithCommasNormal(saleMarketInfo.minQuantity)} ${saleMarketInfo.base}`
                          : 'Individual hard cap'}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item span={12} label={trText('ido_token_info_sale_quantity')}>
                      <span>
                        {saleMarketInfo.saleType === 'WHITELIST_FCFS'
                          ? 'FCFS'
                          : saleMarketInfo.totalAvailableQuantity > 0
                          ? `${numberWithCommasNormal(saleMarketInfo.totalAvailableQuantity)} ${saleMarketInfo.base}`
                          : 'TBA'}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                  <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '30px', marginBottom: '15px' }}>
                    LINK
                  </div>
                  <div style={{ marginBottom: '100px' }}>
                    {saleMarketInfo.community &&
                      saleMarketInfo.community.map(({ type, value }, idx) => {
                        return (
                          <>
                            <Button
                              key={idx}
                              style={{ marginLeft: '5px' }}
                              shape="round"
                              size="middle"
                              onClick={() => {
                                window.open(value)
                              }}
                            >
                              {type.replace('_', ' ')}
                            </Button>
                          </>
                        )
                      })}
                  </div>
                </TabPane>
                <TabPane tab={<span>{trText('ido_token_info')}</span>} key="token_info">
                  <div style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px', marginBottom: '15px' }}>
                    {trText('ido_token_info')}
                  </div>
                  <Descriptions bordered>
                    <Descriptions.Item span={12} label={`Symbol`}>
                      {saleMarketInfo.base}
                    </Descriptions.Item>
                    <Descriptions.Item span={12} label={`Decimals`}>
                      {saleMarketInfo.baseTokenDecimals}
                    </Descriptions.Item>
                    <Descriptions.Item span={12} label={`Supply`}>
                      {numberWithCommasNormal(saleMarketInfo.baseMintSupply)}
                      {saleMarketInfo.base}
                    </Descriptions.Item>
                    <Descriptions.Item span={12} label={`Network`}>
                      SOLANA
                    </Descriptions.Item>
                  </Descriptions>
                </TabPane>
                <TabPane tab={<span>FAQ</span>} key="faq">
                  <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>
                    FAQ
                  </div>
                  <Collapse style={{ marginTop: '5px' }} defaultActiveKey={[0]}>
                    {idoFaqList &&
                      idoFaqList.map((faq, idx) => {
                        return (
                          <Panel header={faq.question} key={idx}>
                            <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                          </Panel>
                        )
                      })}
                  </Collapse>
                </TabPane>
              </Tabs>
            )}
            {connected && (
              <IdoPageInner
                marketId={saleMarketInfo.id}
                saleMarketInfo={saleMarketInfo}
                whiteListWinner={whiteListWinner}
                buyModalVisible={buyModalVisible}
                reloadMarketInfo={reloadMarketInfo}
                setBuyModalVisible={setBuyModalVisible}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

function getSaleTypeText(type) {
  if (type === 'WHITELIST' || type === 'WHITELIST_LOTTERY') {
    return 'Lottery'
  } else if (type === 'WHITELIST_RANK') {
    return 'Rank/Lottery'
  } else if (type === 'WHITELIST_FCFS') {
    return 'Everyone FCFS'
  } else {
    return 'Everyone'
  }
}

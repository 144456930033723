import React, { useState } from 'react'
import _ from 'lodash'
import { Row, Col, Tag } from 'antd'
import { useFills, useMarket } from '../../utils/dex-markets'
import DataTable from '../layout/DataTable'
import { useTranslation } from 'react-i18next'
import { useWallet } from '../../utils/wallet'
import NotConnectView from '../../componentsv2/element/NotConnectView'
import { useEffect } from 'react'

export default function FillsTable() {
  const { t: trText } = useTranslation()

  const fills = useFills()

  const { connected } = useWallet()
  const { quoteCurrency } = useMarket()

  const [orderHistory, setOrderHistory] = useState([])
  useEffect(() => {
    if (fills && !_.isEmpty(fills)) {
      let isUpdate = false
      if (_.isEmpty(orderHistory)) {
        isUpdate = true
      } else {
        fills.forEach((f) => {
          const findCurrentHistory = orderHistory.find((c) => c.orderId.toString() === f.orderId.toString())
          if (!findCurrentHistory) {
            isUpdate = true
          }
        })
      }
      if (isUpdate) {
        setOrderHistory(
          (fills || []).map((fill) => ({
            ...fill,
            key: `${fill.orderId}${fill.side}`,
            liquidity: fill.eventFlags.maker ? 'Maker' : 'Taker',
          })),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fills])

  const columns = [
    {
      title: trText('market'),
      dataIndex: 'marketName',
      key: 'marketName',
    },
    {
      title: trText('order_side'),
      dataIndex: 'side',
      key: 'side',
      render: (side) => (
        <Tag color={side === 'buy' ? '#26A69A' : '#F6465D'} style={{ fontWeight: 700 }}>
          {side.charAt(0).toUpperCase() + side.slice(1)}
        </Tag>
      ),
    },
    {
      title: trText('order_size'),
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: trText('order_price'),
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: trText('liquidity'),
      dataIndex: 'liquidity',
      key: 'liquidity',
    },
    {
      title: quoteCurrency ? `${trText('fee_discount_taker')} (${quoteCurrency})` : trText('fee_discount_taker'),
      dataIndex: 'feeCost',
      key: 'feeCost',
    },
  ]

  // const dataSource = (fills || []).map((fill) => ({
  //   ...fill,
  //   key: `${fill.orderId}${fill.side}`,
  //   liquidity: fill.eventFlags.maker ? 'Maker' : 'Taker',
  // }))

  return (
    <>
      <Row>
        <Col span={24}>
          {connected ? (
            <DataTable
              dataSource={orderHistory}
              columns={columns}
              pagination={true}
              pageSize={5}
              emptyLabel={trText('empty_fills')}
            />
          ) : (
            <NotConnectView />
          )}
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            Trade History is only kept for when that browser session is live.
          </div>
        </Col>
      </Row>
    </>
  )
}

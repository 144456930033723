import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useMarket } from '../utils/dex-markets'
import { getDecimalCount } from '../utils/utils'
import { numberWithCommas, calCurrencyPrice } from '../utils/dexlab-utils'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import { TradeHistory } from '../utils/types'
import { useTranslation } from 'react-i18next'

const SizeTitle = styled(Row)`
  padding: 0 0 14px;
  color: #2b2b2b;
`

export default function TradesTable({ smallScreen, currency, tradeHistory, tradeHistoryLoaded }) {
  const { t: trText } = useTranslation()
  const { baseCurrency, quoteCurrency, market } = useMarket()
  // const [trades, loaded] = useTradeHistory()

  const tickSize = currency.standardType === 'USD' ? market?.tickSize && getDecimalCount(market.tickSize) : 0
  const selectQuoteCurrency = currency.standardType === 'USD' ? quoteCurrency : currency.standardType

  return (
    <FloatingElement
      style={
        smallScreen
          ? { flex: 1, backgroundColor: '#00000000' }
          : {
              minHeight: '450px',
              maxHeight: 'calc(100vh - 700px)',
              backgroundColor: '#00000000',
            }
      }
    >
      <SizeTitle>
        <Col span={8}>
          {trText('order_price')} ({selectQuoteCurrency}){' '}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          {trText('order_size')} ({baseCurrency})
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          {trText('order_time')}
        </Col>
      </SizeTitle>
      {!!tradeHistory && tradeHistoryLoaded && (
        <div
          style={{
            marginRight: '-20px',
            paddingRight: '5px',
            overflowY: 'scroll',
            minHeight: '620px',
            maxHeight: smallScreen ? 'calc(100% - 75px)' : 'calc(100vh - 800px)',
          }}
        >
          {tradeHistory.map((trade: TradeHistory, i: number) => (
            <Row key={i} style={{ marginBottom: 4 }}>
              <Col
                span={8}
                style={{
                  color: trade.side === 'buy' ? '#26A69A' : '#F6465D',
                }}
              >
                {trade.side === 'buy' ? (
                  <img style={{ marginRight: '3px' }} src={require('../images/trade_buy.png')} alt={'Buy'}></img>
                ) : (
                  <img style={{ marginRight: '3px' }} src={require('../images/trade_sell.png')} alt={'Sell'}></img>
                )}
                {numberWithCommas(
                  !isNaN(trade.price)
                    ? Number(calCurrencyPrice(trade.price, currency.lowPrice)).toFixed(tickSize)
                    : '-',
                  currency,
                )}
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                {market?.minOrderSize && !isNaN(trade.size)
                  ? Number(trade.size).toFixed(getDecimalCount(market.minOrderSize))
                  : trade.size}
              </Col>
              <Col span={8} style={{ textAlign: 'right', color: '#434a59', paddingRight: '10px' }}>
                {trade.time && new Date(trade.time).toLocaleTimeString()}
              </Col>
            </Row>
          ))}
        </div>
      )}
    </FloatingElement>
  )
}

/* eslint-disable */
import React, { useMemo, useState } from 'react'
import { PublicKey } from '@solana/web3.js'
import { TokenInfo } from '@solana/spl-token-registry'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  Typography,
  DialogActions,
  Tabs,
  Tab,
} from '@material-ui/core'
import { TokenIcon } from './Swap'
import { useSwappableTokens } from '../context/TokenList'
import { useMediaQuery } from '@material-ui/core'
// import { DexLabMarketV2Info } from '../../utils/types'
import debounce from 'lodash/debounce'
import { FixedSizeList } from 'react-window'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
  },
  textField: {
    marginBottom: '8px',
  },
  tab: {
    minWidth: '134px',
  },
  tabSelected: {
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
  tabIndicator: {
    opacity: 0,
  },
  input: {
    color: '#FFFFFF',
  },
}))

export default function TokenDialog({
  open,
  onClose,
  setMint,
}: {
  open: boolean
  onClose: () => void
  setMint: (mint: PublicKey) => void
}) {
  const [tabSelection, setTabSelection] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [tokenFilter, setTokenFilter] = useState('')
  const filter = tokenFilter.toLowerCase()
  const styles = useStyles()
  const { swappableTokensWormhole, swappableTokensDexlab, swappableTokensDexlabExtra } = useSwappableTokens()
  const displayTabs = !useMediaQuery('(max-width:450px)')
  const adjustListSize = useMediaQuery('(max-height:600px')

  const changeHandler = (e) => {
    setSearchValue(e.target.value)
    debounceTokenFilter(e.target.value)
  }
  const debounceTokenFilter = useMemo(
    () =>
      debounce((e) => {
        setTokenFilter(e)
      }, 500),
    [],
  )

  let selectedTokens: any = swappableTokensDexlab
  if (tabSelection === 0) {
    // DEXLAB
    selectedTokens = swappableTokensDexlab
  } else if (tabSelection === 1) {
    // Wormhole
    selectedTokens = swappableTokensWormhole
  } else if (tabSelection === 2) {
    // EXTRA
    selectedTokens = swappableTokensDexlabExtra
  }

  let tokens =
    tokenFilter === ''
      ? selectedTokens
      : selectedTokens.filter(
          (t) =>
            t.symbol.toLowerCase().startsWith(filter) ||
            t.name.toLowerCase().startsWith(filter) ||
            t.address.toLowerCase().startsWith(filter),
        )

  const TokenList = ({ index, style }) => {
    const mint = new PublicKey(tokens[index].address)
    const tokenInfo = tokens[index]
    return (
      <ListItem button onClick={() => setMint(mint)} style={{ padding: '10px 20px', ...style }}>
        <TokenIcon mint={mint} style={{ width: '30px', borderRadius: '15px' }} />
        <TokenName tokenInfo={tokenInfo} />
      </ListItem>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      PaperProps={{
        style: {
          backgroundColor: '#131722',
          color: '#ffffff',
          borderRadius: '10px',
          width: '420px',
        },
      }}
    >
      <DialogTitle style={{ fontWeight: 'bold' }}>
        <Typography component={'span'} style={{ paddingBottom: '16px', fontSize: '1.2rem' }}>
          Select a token
        </Typography>
        <TextField
          className={styles.textField}
          placeholder={'Search name'}
          value={searchValue}
          fullWidth
          variant="outlined"
          InputProps={{
            classes: {
              input: styles.input,
            },
          }}
          onChange={changeHandler}
        />
      </DialogTitle>
      <DialogContent className={styles.dialogContent} dividers={true}>
        <List disablePadding>
          <FixedSizeList
            itemSize={adjustListSize ? 40 : 60}
            height={adjustListSize ? 120 : 480}
            itemCount={tokens.length}
            width="100%"
          >
            {TokenList}
          </FixedSizeList>
        </List>
      </DialogContent>
      <DialogActions>
        <Tabs
          value={tabSelection}
          onChange={(e, v) => setTabSelection(v)}
          classes={{
            indicator: styles.tabIndicator,
          }}
        >
          <Tab value={0} className={styles.tab} classes={{ selected: styles.tabSelected }} label="Dexlab" />
          <Tab value={1} className={styles.tab} classes={{ selected: styles.tabSelected }} label="Wormhole" />\
          <Tab value={2} className={styles.tab} classes={{ selected: styles.tabSelected }} label="Extra" />
        </Tabs>
      </DialogActions>
    </Dialog>
  )
}

function TokenName({ tokenInfo }: { tokenInfo: TokenInfo }) {
  return (
    <div style={{ marginLeft: '16px' }}>
      <Typography style={{ fontWeight: 'bold' }}>{tokenInfo?.symbol}</Typography>
      <Typography style={{ fontSize: '14px' }}>{tokenInfo?.name}</Typography>
    </div>
  )
}

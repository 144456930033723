import React from 'react'
import { Modal } from 'antd'
import { useSelectedBaseCurrencyAccount, useMarket, useSelectedQuoteCurrencyAccount } from '../utils/dex-markets'
import { useWallet } from '../utils/wallet'
import Link from '../componentsv2/Link'

export default function DepositDialog({ onClose, baseOrQuote }) {
  const { market, baseCurrency, quoteCurrency } = useMarket()

  const { providerName, providerUrl } = useWallet()
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount()
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount()
  let coinMint
  let account
  let depositCoin
  if (baseOrQuote === 'base') {
    coinMint = market?.baseMintAddress
    account = baseCurrencyAccount
    depositCoin = baseCurrency
  } else if (baseOrQuote === 'quote') {
    coinMint = market?.quoteMintAddress
    account = quoteCurrencyAccount
    depositCoin = quoteCurrency
  } else {
    account = null
  }
  if (!coinMint) {
    return null
  }
  return (
    <Modal title={depositCoin} visible={!!coinMint} onOk={onClose} onCancel={onClose}>
      <div style={{ paddingTop: '20px' }}>
        <p style={{ color: '#22223e' }}>Mint address:</p>
        <p style={{ color: '#666666' }}>{coinMint.toBase58()}</p>
        <div>
          <p style={{ color: '#22223e' }}>SPL Deposit address:</p>
          <p style={{ color: '#666666' }}>
            {account ? (
              account.pubkey.toBase58()
            ) : (
              <>
                Visit{' '}
                <Link external to={providerUrl}>
                  {providerName}
                </Link>{' '}
                to create an account for this mint
              </>
            )}
          </p>
        </div>
      </div>
    </Modal>
  )
}

import React, { useState } from 'react'
import _ from 'lodash'
import { Typography, Button, Form, Input } from 'antd'
import { useWallet } from '../../utils/wallet'
import { useConnection } from '../../utils/connection'
import { Market } from '@project-serum/serum'
import { PublicKey } from '@solana/web3.js'

const { Title } = Typography
const DEX_OPEN_SERUM_PROGRAM = 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX'

export default function InputMarketAddress({ splTokenList, inputMarketInfomation, onCreateMarketPreview, prev }) {
  const { connected } = useWallet()
  const connection = useConnection()
  // const [submitting, setSubmitting] = useState(false)
  const [valideMarketAddress, setValideMarketAddress] = useState(false)
  const [market, setMarket] = useState<Market | undefined>(undefined)

  const [form] = Form.useForm()

  const onNext = (values) => {
    if (!market || !valideMarketAddress) {
      window.alert(`Market information not found.`)
      return
    }

    if (_.isEmpty(values.quote)) {
      window.alert(`Enter the quote token symbol.`)
      return
    }

    if (_.isEmpty(values.base)) {
      window.alert(`Enter the base token symbol.`)
      return
    }

    const resultValues = {
      address: values.address,
      base: values.base,
      baseMint: market.baseMintAddress.toBase58(),
      quote: values.quote,
      quoteMint: market.quoteMintAddress.toBase58(),
      baseLotSize: 0,
      quoteLotSize: 0,
    }
    onCreateMarketPreview(resultValues)
  }

  async function handleMarketAddress(marketAddress) {
    try {
      const market = await Market.load(
        connection,
        new PublicKey(marketAddress),
        {},
        new PublicKey(DEX_OPEN_SERUM_PROGRAM),
      )
      setMarket(market)
      if (market) {
        setValideMarketAddress(true)

        // 심볼명 자동입력
        const findBaseMarket = splTokenList.find((f) => f.address === market.baseMintAddress.toBase58())
        const findQuoteMarket = splTokenList.find((f) => f.address === market.quoteMintAddress.toBase58())
        if (findBaseMarket) {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            base: findBaseMarket.symbol,
          })
        } else {
          // window.alert(
          //   `If it is not in the solana token-list , a warning message may be exposed. Please register your token information as soon as possible.\n\n registration at https://github.com/solana-labs/token-list`,
          // )
        }
        if (findQuoteMarket) {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            quote: findQuoteMarket.symbol,
          })
        }
      } else {
        setValideMarketAddress(false)
      }
    } catch (e: any) {
      console.log(e.message)
      setMarket(undefined)
      setValideMarketAddress(false)
    }
  }

  return (
    <>
      <Title style={{ marginBottom: '30px' }} level={4}>
        Enter market address
      </Title>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        initialValues={inputMarketInfomation}
        wrapperCol={{ span: 24 }}
        layout={'vertical'}
        onFinish={onNext}
      >
        <Form.Item
          rules={[{ required: true }]}
          hasFeedback
          validateStatus={valideMarketAddress ? 'success' : 'warning'}
          name="address"
          label="Market address"
        >
          <Input
            placeholder="Enter market address"
            onChange={(e) => {
              handleMarketAddress(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item
          style={{ display: 'inline-block', width: 'calc(50%)' }}
          rules={[{ required: true }]}
          name="base"
          label="Base Symbol Name(ex. BTC)"
        >
          <Input maxLength={12} placeholder="Enter base label(ex. DXL)" />
        </Form.Item>
        <Form.Item
          style={{ display: 'inline-block', width: 'calc(50%)' }}
          rules={[{ required: true }]}
          name="quote"
          label="Quote Symbol Name(automatic input)"
        >
          <Input maxLength={12} placeholder="Enter quote label(ex. USDC)" />
        </Form.Item>
        <Form.Item>
          <ul>
            <li>* Quote token markets that are not officially registered cannot be registered.</li>
            <li>* Only SPL Tokens can be add.</li>
            <li>* SOL is required for token creation. Prepare in advance ( about 0.000005 ~ 0.0001 SOL )</li>
            <li>* Markets with problems may be delisted without notice.</li>
          </ul>
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button disabled={!connected} type="primary" htmlType="submit" loading={false}>
            Next
          </Button>
          <Button style={{ margin: '0 8px' }} htmlType="button" onClick={prev}>
            Previous
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

import React, { useState } from 'react'
import DataTable from '../layout/DataTable'
import _ from 'lodash'
import { Row, Avatar, Button } from 'antd'
import { settleAllFunds } from '../../utils/send'
import { notify } from '../../utils/notifications'
import { useDexlabRpcSendConnection } from '../../utils/connection'
import { useWallet } from '../../utils/wallet'
import { useTokenAccounts, useSelectedTokenAccounts } from '../../utils/dex-markets'
import StandaloneTokenAccountsSelect from '../../componentsv2/StandaloneTokenAccountSelect'
import { abbreviateAddress } from '../../utils/utils'
import { PublicKey } from '@solana/web3.js'
import { useTranslation } from 'react-i18next'
import NotConnectView from '../../componentsv2/element/NotConnectView'
import { DexLabMarketV2Info } from '../../utils/types'

export default function WalletBalancesTable({
  walletBalances,
  allV2Markets,
  allMarkets,
  allMarketsConnected,
}: {
  allV2Markets: DexLabMarketV2Info[]
  walletBalances: {
    coin: string
    mint: string
    walletBalance: number
    openOrdersFree: number
    openOrdersTotal: number
  }[]
  allMarkets: any[]
  allMarketsConnected: boolean
}) {
  const { t: trText } = useTranslation()
  const isLoading = _.isEmpty(allMarkets)

  const connection = useDexlabRpcSendConnection()
  const { wallet, connected } = useWallet()
  const [selectedTokenAccounts] = useSelectedTokenAccounts()
  const [tokenAccounts, tokenAccountsConnected] = useTokenAccounts()
  const [settlingFunds, setSettlingFunds] = useState(false)

  async function onSettleFunds() {
    setSettlingFunds(true)
    try {
      if (!tokenAccounts || !tokenAccountsConnected) {
        notify({
          message: trText('toast_message_wallet_settle_error'),
          description: trText('toast_message_not_connect'),
          type: 'error',
        })
        return
      }
      if (!allMarkets || !allMarketsConnected) {
        notify({
          message: trText('toast_message_wallet_settle_error'),
          description: trText('toast_message_not_market'),
          type: 'error',
        })
        return
      }
      await settleAllFunds({
        connection,
        tokenAccounts,
        selectedTokenAccounts,
        wallet: wallet!!,
        markets: allMarkets.map((marketInfo) => marketInfo.market),
      })
    } catch (e: any) {
      notify({
        message: trText('toast_message_wallet_settle_error'),
        description: e.message,
        type: 'error',
      })
    } finally {
      setSettlingFunds(false)
    }
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      width: '20%',
      render: (walletBalance) => {
        const findTokenInfo = allV2Markets
          .filter((f) => !f.nameEn.includes('[Deprecated]'))
          .find((f) => f.baseMint === walletBalance.mint)
        return (
          <>
            <Row align="middle">
              {findTokenInfo ? (
                <>
                  <Avatar src={findTokenInfo.iconUrl} size={30} />
                  <span style={{ color: '#FFFFFF' }}>{` ${findTokenInfo.nameEn ?? walletBalance.coin}`}</span>
                </>
              ) : (
                <>
                  <Avatar
                    style={{ marginRight: '3px' }}
                    src={`https://raw.githubusercontent.com/dexlab-project/assets/master/tokens/solana/${walletBalance.coin.toLowerCase()}/symbol.png?raw=true`}
                    size={30}
                  />
                  {walletBalance.coin}
                </>
              )}
            </Row>
          </>
        )
      },
    },
    {
      title: trText('my_wallet_balance_coin'),
      key: 'coin',
      width: '20%',
      render: (walletBalance) => walletBalance.coin || abbreviateAddress(new PublicKey(walletBalance.mint)),
    },
    {
      title: trText('my_wallet_balance'),
      dataIndex: 'walletBalance',
      key: 'walletBalance',
      width: '20%',
    },
    {
      title: trText('my_wallet_balance_openorder_total'),
      width: '20%',
      render: (walletBalance) => walletBalance.openOrdersTotal - walletBalance.openOrdersFree,
    },
    {
      title: trText('my_wallet_balance_unsettled_balances'),
      width: '20%',
      render: (walletBalance) => walletBalance.openOrdersFree,
    },
    {
      title: trText('my_wallet_balance_select_token_account'),
      key: 'selectTokenAccount',
      width: '20%',
      render: (walletBalance) => (
        <Row align="middle" style={{ width: '430px' }}>
          <StandaloneTokenAccountsSelect
            accounts={tokenAccounts?.filter((t) => t.effectiveMint.toBase58() === walletBalance.mint)}
            mint={walletBalance.mint}
          />
        </Row>
      ),
    },
  ]

  return (
    <React.Fragment>
      {connected ? (
        <DataTable
          emptyLabel={trText('my_wallet_no_balance')}
          dataSource={walletBalances.filter((f) => !_.isEmpty(f.coin))}
          loading={isLoading}
          columns={columns}
          pagination={false}
        />
      ) : (
        <NotConnectView />
      )}

      {connected && (
        <Button onClick={onSettleFunds} loading={settlingFunds}>
          {trText('standalone_balances_settle_all')}
        </Button>
      )}
    </React.Fragment>
  )
}

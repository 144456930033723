import _ from 'lodash'
import { NEW_DEXLAB_MARKETS } from './new_markets'

export const DEXLAB_MARKETS = [
  {
    address: 'HFZtxFAKcrEwjgUTrmCHhHWbVtDAWMV491QLQkXADBRq',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'DXL',
    baseMint: 'GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'DXL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'ETC',
    nameEn: 'Dexlab',
    nameCn: null,
    nameKr: '덱스랩',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/dxl/symbol.png?raw=true',
    nftImageUrl: null,
    community: [
      {
        url: 'https://t.me/dexlab_official',
        name: 'Telegram',
      },
      {
        url: 'https://dexlab-space.medium.com/',
        name: 'Medium',
      },
      {
        url: 'https://discord.gg/XcQDV7z2Hz',
        name: 'Discord',
      },
      {
        url: 'https://www.dexlab.space/whitepaper/DexLab_White_paper_1_1_eng.pdf',
        name: 'White Paper',
      },
      {
        url: 'https://docs.dexlab.space',
        name: 'Document',
      },
      {
        url: 'https://www.dexlab.space',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/Dexlab_official',
        name: 'TWITTER',
      },
    ],
    chartType: 'GECKO_TERMINAL',
    orderType: 'GENERAL',
    coingeckoId: 'dexlab',
    tvWidgetChartSymbol: null,
    geckoterminalChartUrl:
      'https://www.geckoterminal.com/solana/pools/asdEJnE7osjgnSyQkSZJ3e5YezbmXuDQPiyeyiBxoUm?embed=1&info=0&swaps=0',
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: '4E17F3BxtNVqzVsirxguuqkpYLtFgCR6NfTpccPh82WE',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'PYTH',
    baseMint: 'HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'PYTH/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'ETC',
    nameEn: 'Pyth Network',
    nameCn: null,
    nameKr: '피스 네트워크',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://pyth.network/token.svg',
    nftImageUrl: null,
    community: [],
    chartType: 'BYBIT',
    orderType: 'GENERAL',
    coingeckoId: 'pyth-network',
    tvWidgetChartSymbol: 'BYBIT:PYTHUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    enable: true,
  },
  {
    address: '8BnEgHoWFysVcuFFX7QztDmzuH8r5ZFvyP3sYwn1XTh6',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SOL',
    baseMint: 'So11111111111111111111111111111111111111112',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'SOL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Solana',
    nameCn: null,
    nameKr: '솔라나',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/sol/symbol.png?raw=true',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/solana',
        name: 'TWITTER',
      },
      {
        url: 'https://solana.com',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://github.com/solana-labs/',
        name: 'GitHub',
      },
      {
        url: 'https://docs.solana.com/',
        name: 'Document',
      },
      {
        url: 'https://discord.com/invite/pquxPsq',
        name: 'Discord',
      },
      {
        url: 'https://t.me/solana',
        name: 'Telegram',
      },
    ],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'solana',
    tvWidgetChartSymbol: 'SOLUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 99,
    enable: true,
  },
  {
    address: '3BAKsQd3RuhZKES2DGysMhjBdwjZYKYmxRqnSMtZ4KSN',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'WBTC',
    baseMint: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'WBTC/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Bitcoin',
    nameCn: null,
    nameKr: '비트코인',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'bitcoin',
    tvWidgetChartSymbol: 'BTCUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 98,
    enable: true,
  },
  {
    address: 'FZxi3yWkE5mMjyaZj6utmYL54QQYfMCKMcLaQZq4UwnA',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'ETH',
    baseMint: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'ETH/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Ethereum',
    nameCn: null,
    nameKr: '이더리움',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/eth/symbol.png?raw=true',
    nftImageUrl: null,
    community: [],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'ethereum',
    tvWidgetChartSymbol: null,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 98,
    enable: true,
  },
  {
    address: 'DZjbn4XC8qoHKikZqzmhemykVzmossoayV9ffbsUqxVj',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RAY',
    baseMint: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'RAY/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Raydium',
    nameCn: null,
    nameKr: '레이디움',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/ray/symbol.png?raw=true',
    nftImageUrl: null,
    community: [],
    chartType: 'BINANCE',
    orderType: 'GENERAL',
    coingeckoId: 'raydium',
    tvWidgetChartSymbol: 'RAYUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: 'B2na8Awyd7cpC59iEU43FagJAPLigr3AP3s38KM982bu',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'USDT',
    baseMint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'USDT/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Tether',
    nameCn: null,
    nameKr: '테더',
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
    nftImageUrl: null,
    community: [],
    chartType: 'KUCOIN',
    orderType: 'GENERAL',
    coingeckoId: 'tether',
    tvWidgetChartSymbol: null,
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 97,
    enable: true,
  },
  {
    address: '3NnxQvDcZXputNMxaxsGvqiKpqgPfSYXpNigZNFcknmD',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MNGO',
    baseMint: 'MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'MNGO/USDC',
    mainCategory: 'DEX',
    subCategory: 'FINANCE',
    nameEn: 'Mango',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png',
    nftImageUrl: null,
    community: [
      {
        url: 'http://mango.markets',
        name: 'HOMEPAGE',
      },
    ],
    chartType: 'GATEIO',
    orderType: 'GENERAL',
    coingeckoId: 'mango-markets',
    tvWidgetChartSymbol: 'GATEIO:MNGOUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 96,
    enable: true,
  },
  {
    address: '9Lyhks5bQQxb9EyyX55NtgKQzpM4WK7JCmeaWuQ5MoXD',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MSOL',
    baseMint: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'MSOL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'DEFI',
    nameEn: 'Marinade staked SOL',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://assets.coingecko.com/coins/images/17752/small/PJ7Scn3.png?1629175951',
    nftImageUrl: null,
    community: [
      {
        url: 'https://marinade.finance',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/MarinadeFinance',
        name: 'TWITTER',
      },
    ],
    chartType: 'GATEIO',
    orderType: 'GENERAL',
    coingeckoId: 'marinade-staked-sol',
    tvWidgetChartSymbol: 'GATEIO:MSOLUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 95,
    enable: true,
  },
  {
    address: '72h8rWaWwfPUL36PAFqyQZU8RT1V3FKG7Nc45aK89xTs',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RLB',
    baseMint: 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 2,
    quoteTokenDecimals: 6,
    symbol: 'RLB/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Rollbit Coin',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://rollbit.com/',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/rollbitcom',
        name: 'TWITTER',
      },
    ],
    chartType: 'UNISWAP3ETH',
    orderType: 'GENERAL',
    coingeckoId: 'rollbit-coin',
    tvWidgetChartSymbol: 'UNISWAP3ETH:RLBUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 94,
    enable: true,
  },
  {
    address: 'CQyhWBaaVRgtLfRegX94JjMxixxKun4Eq3r7wbDSW7LZ',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'LSTAR',
    baseMint: 'C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'LSTAR/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Learning Star',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3yigvdWq6qobhAwQL6UiSide5W9W7zcJbuVvKa3T231/logo.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://www.letmespeak.org',
        name: 'HOMEPAGE',
      },
      {
        url: 'https://twitter.com/Letmespeak_org',
        name: 'TWITTER',
      },
    ],
    chartType: 'GECKO_TERMINAL',
    orderType: 'GENERAL',
    coingeckoId: 'learning-star',
    geckoterminalChartUrl:
      'https://www.geckoterminal.com/solana/pools/FhgwZ8d7cU3y9oysZAZEdZ28P8GKr7vtvMkZbvpH7G6g?embed=1&info=0&swaps=0',
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: '5xCudgPvVetTL3M4ExtPuAFTnCr2hdQ3cte9LhGAuBee',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'ARB',
    baseMint: '9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'ARB/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'ARB Protocol',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://raw.githubusercontent.com/ARBProtocol/arbprotocol/main/IMG_3600.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/arbprotocol',
        name: 'TWITTER',
      },
    ],
    chartType: 'GECKO_TERMINAL',
    orderType: 'GENERAL',
    coingeckoId: 'arb-protocol',
    tvWidgetChartSymbol: null,
    geckoterminalChartUrl:
      'https://www.geckoterminal.com/solana/pools/FQy9XzKu2yq2XZVbfbyhvcbteySmESuXaCdWJCScmnYp?embed=1&info=0&swaps=0',
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: '8PhnCfgqpgFM7ZJvttGdBVMXHuU4Q23ACxCvWkbs1M71',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'BONK',
    baseMint: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 5,
    quoteTokenDecimals: 6,
    symbol: 'BONK/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Bonk',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/hQiPZOsRZXGXBJd_82PhVdlM_hACsT_q6wqwf5cSY7I',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/bonk_inu',
        name: 'TWITTER',
      },
    ],
    chartType: 'HUOBI',
    orderType: 'GENERAL',
    coingeckoId: 'bonk',
    tvWidgetChartSymbol: 'HUOBI:BONKUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 93,
    enable: true,
  },
  {
    address: 'iufycE4CrRMVHkgym27q28SEyCZ3ZHsN3rLxbPXA7m2',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'WOOF',
    baseMint: '9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'WOOF/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'WOOF',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'GECKO_TERMINAL',
    orderType: 'GENERAL',
    coingeckoId: 'woof',
    geckoterminalChartUrl:
      'https://www.geckoterminal.com/solana/pools/3HYhQC6ne6SAPVT5sPTKawRUxv9ZpYyLuk1ifrw8baov?embed=1&info=0&swaps=0',
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 90,
    enable: true,
  },
  {
    address: 'HE1nibjUq5cTi6Zer5679od58PDAjtPAjTetUuV5XMPn',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MOLA',
    baseMint: 'AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'MOLA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'MOONLANA',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'GECKO_TERMINAL',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    geckoterminalChartUrl:
      'https://www.geckoterminal.com/solana/pools/Ej6ShA7SYvCi4LNMnNRN6FHxnfscED7BxsdEmoiH2ZCp?embed=1&info=0&swaps=0',
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 90,
    enable: true,
  },
  {
    address: 'CFW3YFB4uN1NWFDYmcKmT9qA3iyi3L5Bz9QJNjKQAWeS',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'PRT',
    baseMint: 'PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'PRT/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Parrot Protocol',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44/logo.svg',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/gopartyparrot',
        name: 'TWITTER',
      },
    ],
    chartType: 'GATEIO',
    orderType: 'GENERAL',
    coingeckoId: 'parrot-protocol',
    tvWidgetChartSymbol: 'GATEIO:PRTUSDT',
    isChart: true,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'DEXLAB',
    priority: 1,
    enable: true,
  },
  {
    address: '4JGMRnbJY6cLTwptAtCiP7YotNWkHJcriJKfwM6VwXpm',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SHDW',
    baseMint: 'SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'SHDW/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Shadow',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y/logo.png',
    nftImageUrl: null,
    community: [
      {
        url: 'https://twitter.com/genesysgo',
        name: 'TWITTER',
      },
    ],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: 'genesysgo-shadow',
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'Epg66q6wPQbNyyegh8ZiP3iAgway4XFPS99UU3fzMaee',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'LMDA',
    baseMint: 'LMDAmLNduiDmSiMxgae1gW7ubArfEGdAfTpKohqE5gn',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'LMDA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Lamda',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/6y2eY-KEaBu4KbZLJwLCTQQUXhWVPKxjgdyW69DwI0o',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 100,
    enable: true,
  },
  {
    address: '8EkT2jzErNR7xnKkAYtWq98NF9hwiYHi9UVXtDSAtksQ',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'FLWR',
    baseMint: 'HDVR9Edy2o8uRdf5xFUDSGVLo556UjAaNR9BrwZ8QeMJ',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'FLWR/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Flowers',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/3brG4EGxdYt30nQMI-T_wkaOXl0xJQfcERzNFJpzTV0',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 2,
    enable: true,
  },
  {
    address: 'JzvJv1vQ8HRsxKapNaaTXEPKFgggqKTYg8u1GkgKDRt',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'BBONK',
    baseMint: '8NFa3QXg3kKqvdmJNjUhzqwvAnUYA9F5R4ymyjJfHcVB',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'BBONK/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Baby Bonk Inu',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://shdw-drive.genesysgo.net/HaxcwvZXYbcVZoPGRkkg6GpnEmTBMA96LW853g5mRbYR/logo.jpeg',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'GRrKTLEdEBDQuk6fw9NM7smPwL7CVfUR1C1MpLPx4fM2',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'JELLY',
    baseMint: '9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'JELLY/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Jelly',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9WMwGcY6TcbSfy9XPpQymY3qNEsvEaYL3wivdwPG2fpp/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 99,
    enable: true,
  },
  {
    address: 'Gmjq4394AB5eVtL4ELTraDVgXAn62Mk4r58H3xW5aTAe',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SDOGE',
    baseMint: '8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 0,
    quoteTokenDecimals: 6,
    symbol: 'SDOGE/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'SDOGE',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: '7pQRrZMrhj6U19pj2stssAYyQdL7htYj6SGNGxwMvB6T',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'CHILI',
    baseMint: 'GPyzPHuFFGvN4yWWixt6TYUtDG49gfMdFFi2iniTmCkh',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 2,
    quoteTokenDecimals: 6,
    symbol: 'CHILI/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'CHILI',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://iili.io/H5O87JS.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },

  {
    address: 'FNr7JqUsDTySUti99ofTgy4afUh3uzCjL9MSexv3wveZ',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'Ninja',
    baseMint: 'FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'NINJA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'NINJA',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'DgqN7pf28wRTburaPkADuQffaGWTdAEp1xosYAEibeLv',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'CATO',
    baseMint: '5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'CATO/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Cato',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  {
    address: 'F5dv6tA7AA5StrBxEh8EmJy8SfCbY6VFxgLoY8gttduB',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'BIU',
    baseMint: '4D7kEovLBEY3VQUzJUQjXyGR8fAZwQWSxEcsP5Y9nZ6S',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'BIU/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Biu Coin',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://biucoin.club/img/cat.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: '7fcy5Mi4eQ8jxT1uThkpcYjC5FjjTojK9xSyKXQmHa1S',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'USDC',
    baseMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    quote: 'ISKT',
    quoteMint: 'isktkk27QaTpoRUhwwS5n9YUoYf8ydCuoTz5R2tFEKu',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 2,
    symbol: 'USDC/ISKT',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Rafkróna',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://rafmyntasjodur.github.io/iskt-metadata/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 98,
    enable: true,
  },
  // {
  //   address: 'AcaRDqMhJhyLsA9Z9gzLMJVFXH3XjL8AyRGVd8UsqVQw',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'CBEARS',
  //   baseMint: 'HghTHiEdqHoU7MY4jQ4gZnseZjnyjKTaTqZmXTdmEtGV',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'CBEARS/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Cbears',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl:
  //     'https://media.discordapp.net/attachments/1031390891279130694/1062114906884821122/NeYo_cute_carton_blue_bear_in_the_space_b971b11f-6b77-45ab-ad85-87acc1e08c76.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  {
    address: 'H6gnTgzKBMAMK9t4LXYuV2pXu2iT9jPPtDSK8YWzsTKL',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SAIL',
    baseMint: '6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'SAIL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Sail',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'HjpHS8ogxcWCqQMQwfRcE4CDEy24X9N1YgycVDtzpvmw',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MEOW',
    baseMint: 'MewzYPct11u77vWqFuozUoAMMww78XUSUPouXw35b3D',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 1,
    quoteTokenDecimals: 6,
    symbol: 'MEOW/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'MEOW',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://shdw-drive.genesysgo.net/3dZHRtuWfkriCDt91uoq6j5QE8aD6euNyx1Q37zFKT2i/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'C3Hv6qZfoB2TxDQBy8UWJ3y1q4kZhMHsp1goLq3sb7xu',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'NRA',
    baseMint: '1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'NRA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Nora',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'DSwvVLvk4TREDTRSS9hdvY7C77uGykL7imJWKmMePzkf',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RAWR',
    baseMint: 'FPLQmiko94JWnjjFZyCBCf5YTt2WpHmoUtQUeFDDVcbu',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'RAWR/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'RAWR',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/SubversiveXsol/assets/85b1bb74e4da674ba0d5603cf6ce6bd935e4bc8d/RAWR_Coin_Final.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: '67sQMpGvvDC2wqQpVF74pY9z2rcp6YbXQykoyDWadh5n',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RRR',
    baseMint: '3zE4Qmh9hrC4inHynT3aSRzHcWhnxPCHLeN9WWqvoRVz',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'RRR/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Race Raiders Rewards',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/SH106hrChudKjQ_c6e6yd0tsGUbFIScv2LL6Dp-LDiI',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'H6rrYK3SUHF2eguZCyJxnSBMJqjXhUtuaki6PHiutvum',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'DUAL',
    baseMint: 'DUALa4FC2yREwZ59PHeu1un4wis36vHRv5hWVBmzykCJ',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 6,
    quoteTokenDecimals: 6,
    symbol: 'DUAL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Dual DAO Token',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://www.dual.finance/images/token-logos/dual.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: '7UfkeQ4N7wPSPmHnmJ4meFQpA6APZ2WP3kja9v3FfuGn',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'MGT',
    baseMint: '4MRVPdN41888CwKSpY37K8HtN2FM9Bc3xTnhf9EDdcou',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'MGT/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Mofogasy Token',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4MRVPdN41888CwKSpY37K8HtN2FM9Bc3xTnhf9EDdcou/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'BrFRYGQ1dGThTiSREuQdeNdDYGjMS2nNzMgWSXc85RqB',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'AZA',
    baseMint: 'ax7EjwgRaerUacfCAptcMnkckmf8Wiee5T9KLqSzsF6',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'AZA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'Kaliza',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ax7EjwgRaerUacfCAptcMnkckmf8Wiee5T9KLqSzsF6/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'AjEbxYrVxhQAQPCz2YThfmgw9HytNF8yVnzvna9iHRDg',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'KIRA',
    baseMint: 'kiraZUmSnzgfVfhrdvNj6hxHFaPFTTUk8ioY98cbh6G',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'KIRA/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'KIRA',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://www.arweave.net/71SbFojNuoFeQ_n4cfMYKzX87xPvsQvNr59R_ZM7ucQ?ext=png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'DW9epYBbfk7K9ACg3vDRo7KRWdtfiexQAL4pB9wAuuz3',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'SOUL',
    baseMint: 'F6weWmuc1vwdL4u38Ro9jKXHEMjP9BoNdWMF5o5TvtJf',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'SOUL/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'SOUL',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://arweave.net/ipj9IdEAQAXt0LS6lOt7jQY7W4iazsVFBkN60U7M4iQ',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'A7zAySZYZPgutKEx6F6JHDukKxLqL5zWJ2mSMjv29PQ4',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'RDX',
    baseMint: '8K5KrMq19F3uQvPozEezwpqKnqxiC4q1XkZW7jqbi7rt',
    quote: 'USDC',
    quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    baseTokenDecimals: 0,
    quoteTokenDecimals: 6,
    symbol: 'RDX/USDC',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'RDX',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://raw.githubusercontent.com/RadiantX-source/logo/main/RadiantX-02.jpg',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  {
    address: 'DAmWVivkjjnwN7J6hUdEpfVXcTGY4PWawKeUL7zSf76z',
    programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
    subSymbolPrefix: null,
    base: 'NEOME',
    baseMint: '8psN3FhC8FJAD37bRPMNLAYD9PXJnA8zozBn2AfFEoih',
    quote: 'USDT',
    quoteMint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    baseTokenDecimals: 9,
    quoteTokenDecimals: 6,
    symbol: 'NEOME/USDT',
    mainCategory: 'GENERAL',
    subCategory: 'GENERAL',
    nameEn: 'NEOME',
    nameCn: null,
    nameKr: null,
    descriptionEn: null,
    descriptionCn: null,
    descriptionKr: null,
    iconUrl: 'https://neome.com/token/logo.png',
    nftImageUrl: null,
    community: [],
    chartType: 'NONE',
    orderType: 'GENERAL',
    coingeckoId: null,
    tvWidgetChartSymbol: null,
    isChart: false,
    isNew: false,
    summary: {
      volume: 0,
      highPrice: 0,
      lowPrice: 0,
    },
    owner: null,
    source: 'UNCERTIFIED',
    priority: 1,
    enable: true,
  },
  // {
  //   address: 'BboqVHSBemqqgD16RKxHPR5n2EFV3fy6djFjdeZfdaCB',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'SEC',
  //   baseMint: 'JA9AZqUjCgdAqL71D6XbSd9vpA1pecmM9UnZyUgVc4wy',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 6,
  //   quoteTokenDecimals: 6,
  //   symbol: 'SEC/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'SEC',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://raw.githubusercontent.com/saveeloncoin/assets/main/sec.jpeg',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '711Vnqp3HtJxueZqugH9vRAikXpoKn1UBwCUfLEvnmkL',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'SMILE',
  //   baseMint: 'GCkfdzfEgKRV7XTmyjFzif5xE2wTD3fbqu5g1nGy3su8',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'SMILE/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'SMILE',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/duUPc4f9aZTmp4nNpal0aBrkYz2YTdSNqxcmToIsqHI',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'FKCPb9N3F4iiQjbKx2cVG4nZG3qWDxLUhZ3u63ZhmAnU',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'CHEEMS',
  //   baseMint: '3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 4,
  //   quoteTokenDecimals: 6,
  //   symbol: 'CHEEMS/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Cheems',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl:
  //     'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc/logo.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'GECKO_TERMINAL',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   geckoterminalChartUrl:
  //     'https://www.geckoterminal.com/solana/pools/FxrvZ95LZ1MyWmSRz1P4XbrjtJp95xthj4SwdrbDXUfb?embed=1&info=0&swaps=0',
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'DEXLAB',
  //   priority: 90,
  //   enable: true,
  // },
  // {
  //   address: 'EzdmXX7u7wgAyvqupvNVHAZsAMkf78F1ko1tzs6JCwta',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'DRIPPZ',
  //   baseMint: '3qA6WmGh9chXpHURW4rSXdGFcfyx6Lenzp4bgLSdoP6Y',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 6,
  //   quoteTokenDecimals: 6,
  //   symbol: 'DRIPPZ/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'DRIPPZ',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://i.imgur.com/uZw585Y.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'DmHqrydhBDMVFz8HNYViXW5efmrq1Zzr9m4XV4p2JA7R',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'PRK',
  //   baseMint: 'HNE11LjhNpkidvQFVanGGa2pJuKqBsDhha7VtB3JDQkP',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 5,
  //   quoteTokenDecimals: 6,
  //   symbol: 'PRK/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'PRK',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/tSJSo_ymv6iTaMDRddvn7NQuKvVQZ88uOYnwpBNNp_Q',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'BYhDrjPmYaDhCeNf8y1JL9q8jkNBf9YUTukwjhuepufH',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'SenTe',
  //   baseMint: 'FpekncBMe3Vsi1LMkh6zbNq8pdM6xEbNiFsJBRcPbMDQ',
  //   quote: 'USDT',
  //   quoteMint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'SenTe/USDT',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'SenTe',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://github.com/Sentientech/Sente/blob/02532a20f1df33614466be469d8290d8d78401b1/SenTe.png?raw=true',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'CVzpqJJVDp6DwX9V5tUnMvdvbNPmrXnf1oVZcPykuFRd',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'CKKA',
  //   baseMint: '51pPuhLArFyrUTiLwFtoySBnELppjNdG13b86zPVBY9Z',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'CKKA/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'CKKA',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://raw.githubusercontent.com/baba7ji/crypto/main/cikka.png.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '2TxrAXrB13X1SrFMVaJ6tfPbjLGdgCUBRP3cufaPJoJA',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'BANGZ',
  //   baseMint: '6AjVTAvqgWi7gLCSKtqfvsmh5aFkgHFC2dqATEQpzCwV',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'BANGZ/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Baby Bangkaew',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://imgtr.ee/images/2023/02/13/Obbus.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 10,
  //   enable: true,
  // },
  // {
  //   address: 'H16HGUh46qBbYbYMsMqARbybfcPF8wwzW7tfYtoCNwjY',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'FOSA',
  //   baseMint: 'FosBSku66zanLNYmbZgyLy5yRaZKHTENpBUm5maQR8Fi',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 4,
  //   quoteTokenDecimals: 6,
  //   symbol: 'FOSA/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Fosa Token',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://shdw-drive.genesysgo.net/CYzW4SLVN4UvFx1N4NP1fpWUMyDm88FUraGWPujbCdEp/FOSA.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isDexlabChart: false,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'VyUfTXavzvjkrQV2WCWPb3C1av9ePTHzwnyeGPCvnKd',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'SOLCAINE',
  //   baseMint: '352NB9VnRPTcsjRDQ2ntKrA3FWUsvxkF7rY55x96PUVj',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'SOLCAINE/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'SOLCAINE',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/Amz0HzFtSbQH1FHQY8_xidw2b_IMxiakytuV4B-cYtw',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 98,
  //   enable: true,
  // },
  // {
  //   address: 'J27aUz91eZzxQDhHGMhdvJAqs9W6BBq6yerW9fNU9zxc',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'CUSE',
  //   baseMint: 'ExJyKJL221vuXRFHGMPpHffzvFrpSRyRgcuRaeXXnKJF',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'CUSE/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'CuseTheJuice',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://shdw-drive.genesysgo.net/HNyGeUqiPu1upPr5c3pRmGWYXN4PPRoEQJcyoFkPs5zG/image.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '4UPoJpXXjs1xX7z4xuja4ctBYZhEVQ4HDS7Fk2pkVmrv',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'CRONK',
  //   baseMint: '767JRTMNHq2ovozu5Td7s1XJoacxBYhYuvXuXiazwbca',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 5,
  //   quoteTokenDecimals: 6,
  //   symbol: 'CRONK/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Cronk Inu',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://cdn.discordapp.com/attachments/899853584047431781/1067399823285821550/ccronk_with_background.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'CUk1MEGo9ivAj8DF1hrRt57B1AZst1tMupn7tD3ZAxp2',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'DBONK',
  //   baseMint: '8XYJLZqoAodvsHZQ7YZE2Dfdjt7mog4gKMqojm9Xgsvy',
  //   quote: 'SOL',
  //   quoteMint: 'So11111111111111111111111111111111111111112',
  //   baseTokenDecimals: 5,
  //   quoteTokenDecimals: 9,
  //   symbol: 'DBONK/SOL',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'DBONK',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/baaRf0WQEM44FBCxo_K1Dt2O9RATlTwDbvAYSQgk5sY',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '4dunCkvfCPSWTrym6SheCmzyXXFYz5dMZ4saL46YL88d',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: '$DD',
  //   baseMint: '3XJe9kn4xqKpX94DB5oRz6LvLv3zfqVL59SQs7A19mcP',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: '$DD/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: '$DOODLE',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://doodledevils.weebly.com/uploads/1/4/4/5/144500268/published/45.png?1674659606',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '7BergwANqpFwuPrsvmPdoamHACRTPgq9CYoe9sZr6593',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'DNRII',
  //   baseMint: 'Bc4Xm6U4K952HX8JZm2jnMG6YsrVJ7BTcRUGrQhvDHn7',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'DNRII/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'DNRII',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/T5GqfhXY4RCbjPG0f-PXJUms4pig1U3IROvazgaV5Dk',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'ECZAns1Ph4TeHrZT7LDL6X8dp649G1KfqVSTNXMkVdp5',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'PEPEC',
  //   baseMint: '6F5mzMfKFfAa3xztYPtuBtGZvbqDCv42WsSEQQvPcbnc',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'PEPEC/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'PEPEC',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'http://www.getpepe.cash/data/bigpepe.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'EYUu98jd2nFbY2QiNaeUm9oqN9bhGK81PHKVLjph52zt',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'OLLA',
  //   baseMint: '5X3Qy97YmJH4om12aKK1cHBnJynsymx9KEHv4iMBxc1k',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 6,
  //   quoteTokenDecimals: 6,
  //   symbol: 'OLLA/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'OLLA',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://raw.githubusercontent.com/ollaexchange/assets/main/olla-token-520x520.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'F6FWz8BPu2WXLR7Y7bUUPEXLFJsop2QT4mGFZi7m3yPF',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: '$SIR',
  //   baseMint: 'LzyvxCBvgYDiMQWYrNeamC6fUz53fnzm1f9dam92oki',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 8,
  //   quoteTokenDecimals: 6,
  //   symbol: '$SIR/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: '$SIR',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://sirtoken.xyz/assets/logo.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '4ChdXfrhRuNSBT4fmykrRew7u5R2JUucEj3er5EoWmjB',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'FOUSE',
  //   baseMint: '5Ysq4aLSPfYeuEu53hjW7Ur6MfSkj1KWByv8jDnBs5BU',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 5,
  //   quoteTokenDecimals: 6,
  //   symbol: 'FOUSE/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'FOUSE',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/2Et30CAZCLWXHT4hZ7ntk7RvZhWj11HrLX60YrZB0-k',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'EwwPfa3d7FrUAjWV8vTvgyQmGpSpy2yP9xD5bzoKQuk3',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'HONK',
  //   baseMint: '7BT9CJGkVryLH5jRdBzUAqa72Mz9z6sABwBnx7a6ttiY',
  //   quote: 'SOL',
  //   quoteMint: 'So11111111111111111111111111111111111111112',
  //   baseTokenDecimals: 5,
  //   quoteTokenDecimals: 9,
  //   symbol: 'HONK/SOL',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Honk',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/ppQQB-NFUMkcL6Qhhf6eLk3ksKvAZd9MSdXM_44d9Rs',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'EzLtLpX89y5DahffwFDWHsGzq3Kg8Tx2YvNqb6cyLGQG',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'KDA',
  //   baseMint: 'CKUUGK5dxNTLy4kX4PSTnHTDYzMQFmkH3K2CCkCZPqKa',
  //   quote: 'USDT',
  //   quoteMint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'KDA/USDT',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Karda',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/WTedCh0WBqgsiJCfX-2EW_3lC3iIg9Ump6_vPSExgks',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '65EeM1SLnR4M2bUv7KDRfcA4ftQydP6TU8hxVsfDvLS2',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'RBN',
  //   baseMint: '9bnKvEK2ymzbrtKGeCSbDtn7Dr1afCQoTTH1y4fL5seK',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'RBN/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Robin',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl:
  //     'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9bnKvEK2ymzbrtKGeCSbDtn7Dr1afCQoTTH1y4fL5seK/logo.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 98,
  //   enable: true,
  // },
  // {
  //   address: '7C5SVhrmVV4sNMaP8gJnM6xUTT2uMUPo6kTHfoWZuvWe',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'BREAD',
  //   baseMint: '7A4DPNz5rUZhHrAeRQ9C5yPivmVVnedJfTayYwrxCi7i',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 2,
  //   quoteTokenDecimals: 6,
  //   symbol: 'BREAD/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Bread',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://shdw-drive.genesysgo.net/FrqjMBaQZmxBFzc6Rzt6iEJLBnZJZ2sU3ty2fpJBToaf/bread.png_small',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'D4rdTUdezgo22AYMuM4Kxr9sdg9P9qAMTyY4iRmsjZto',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'WOOP',
  //   baseMint: 'A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 5,
  //   quoteTokenDecimals: 6,
  //   symbol: 'WOOP/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Woop',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl:
  //     'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB/logo.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'HivQDHionBaQFyAAvzZuiWzkS5xTpLkANGGGJZNAHCRX',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'BRAWR',
  //   baseMint: 'GUBKEpxnvsppxKUU6o8aqxnUf7UD4zHgSsRbVApKAs7T',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'BRAWR/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Brawr',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://i.imgur.com/2I7wnUL.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'Bh7P6FALkxE7VUwbyZHqHUHRZqSX5GHz8xAhV6kSitnK',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'RAVEN',
  //   baseMint: 'GUbp7GeyTuoXXYVQj6FKevw669B69fGihCLH5zRbmy7Q',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'RAVEN/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Raven',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://i.imgur.com/QREr4bP.jpg',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'HWxL2eaLJFaqA9T4izP9tHWsNv1FtdoJvCbYTyTbN6N9',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'MOON',
  //   baseMint: 'EEzNqXxJ2z8uJQxfCy2VvCCwiXWg96mFRGrwdM4tdFfC',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 5,
  //   quoteTokenDecimals: 6,
  //   symbol: 'MOON/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'MOONRISE',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/abf9i6X7Q3OWt7ZCWmSWzrd4Aev9BM2rMLOqGJONq9I',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'GHz7mMg6xRC5kzKpc7kfPojE9yDvcDvx14vZ4guyTr6i',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'MOOH',
  //   baseMint: 'moh1tKXXKyKLS4MbPvpbehjsqqVuYk2kTpTE8npPYQJ',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 1,
  //   quoteTokenDecimals: 6,
  //   symbol: 'MOOH/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Mooh',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://shdw-drive.genesysgo.net/2fo6xzjT6iAmKF3HzSWAZZx3L55WEJT4JaWoQWKmgmy5/logo.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '7faRR4ttNz3h3VYahPsjMFSrTU3b4GhCCf9HkD5Fyw7o',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'HUSKY',
  //   baseMint: 'EdB7jdh9mCQPuw1cH3wy9djNxURZjCQuMzvoQFnP4Zxa',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'HUSKY/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'HUSKY',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://arweave.net/IwqYPYWOOSpgF2pwaMA9c44mXcnwTigxkpHVZUs2vRo',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: '3ob44r5ZAvxEmL5CJK7QfEGLcY1xUAvDnVAc8MTbh243',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'BDE',
  //   baseMint: 'H5gczCNbrtso6BqGKihF97RaWaxpUEZnFuFUKK4YX3s2',
  //   quote: 'USDC',
  //   quoteMint: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  //   baseTokenDecimals: 9,
  //   quoteTokenDecimals: 6,
  //   symbol: 'BDE/USDC',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Big Defi Energy',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://raw.githubusercontent.com/bigchickendefi/bigchicken/main/logo.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
  // {
  //   address: 'YdB6jH7MVjYy6jS28qMNMdNmVZXZ7pvJ5kXu4znxUwc',
  //   programId: 'srmqPvymJeFKQ4zGQed1GFppgkRHL9kaELCbyksJtPX',
  //   subSymbolPrefix: null,
  //   base: 'PEPO',
  //   baseMint: '3fXSotQK7sb6HEgkyChF1sxJrBvYyJiue3xAwEytwwtx',
  //   quote: 'SOL',
  //   quoteMint: 'So11111111111111111111111111111111111111112',
  //   baseTokenDecimals: 8,
  //   quoteTokenDecimals: 9,
  //   symbol: 'PEPO/SOL',
  //   mainCategory: 'GENERAL',
  //   subCategory: 'GENERAL',
  //   nameEn: 'Peepo',
  //   nameCn: null,
  //   nameKr: null,
  //   descriptionEn: null,
  //   descriptionCn: null,
  //   descriptionKr: null,
  //   iconUrl: 'https://shdw-drive.genesysgo.net/36D6SiXqNw4mvjkVtbkB7Eru5sZrACtunB8qCPfkwX3C/image.png',
  //   nftImageUrl: null,
  //   community: [],
  //   chartType: 'NONE',
  //   orderType: 'GENERAL',
  //   coingeckoId: null,
  //   tvWidgetChartSymbol: null,
  //   isChart: false,
  //   isNew: false,
  //   summary: {
  //     volume: 0,
  //     highPrice: 0,
  //     lowPrice: 0,
  //   },
  //   owner: null,
  //   source: 'UNCERTIFIED',
  //   priority: 1,
  //   enable: true,
  // },
]

export const MARKETS_TO_STRING = JSON.stringify(_.sortBy([...DEXLAB_MARKETS, ...NEW_DEXLAB_MARKETS], (a) => a.priority))

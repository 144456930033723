import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { DEXLAB_DISCORD, DEXLAB_TELEGRAM, DEXLAB_TWITTER } from '../../utils/dexlab-utils'

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="copyright">
                <p>
                  © Copyright 2022 <Link to={'#'}>Dexlab</Link> I All Rights Reserved
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="footer-social">
                <ul>
                  <li>
                    <a href={DEXLAB_TWITTER} target="_blank" rel="noreferrer noopener">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={DEXLAB_DISCORD} target="_blank" rel="noreferrer noopener">
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>
                  </li>
                  <li>
                    <a href={DEXLAB_TELEGRAM} target="_blank" rel="noreferrer noopener">
                      <i className="fa fa-telegram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer

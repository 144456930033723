import React, { useEffect } from 'react'
// import { isTablet, isBrowser } from 'react-device-detect'

const GoogleAdvertise = ({
  className = 'adsbygoogle',
  client = '',
  slot = '',
  format = '',
  responsive = '',
  layoutKey = '',
  dexlabAds = true,
}) => {
  return <></>
  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production')
  //     try {
  //       /* @ts-ignore */
  //       ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  //       console.log('Advertise is pushed')
  //     } catch (e) {
  //       console.error('AdvertiseError', e)
  //     }
  // }, [])

  // if (dexlabAds) {
  //   return isTablet || isBrowser ? (
  //     <a
  //       style={{ display: 'flex', justifyContent: 'center' }}
  //       href="https://discord.gg/walkmining-1003493865086537748"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       <img src="https://ad-banner.walkmining.com/external/dexlab/walkmining_get_code_1256.png" alt="WalkMining" />
  //     </a>
  //   ) : (
  //     <></>
  //   )
  // }

  // //production이 아닌 경우 대체 컴포넌트 표시
  // if (process.env.NODE_ENV !== 'production')
  //   return (
  //     <div
  //       style={{
  //         background: '#00000000',
  //         color: '#ffffff',
  //         fontSize: '18px',
  //         fontWeight: 'bold',
  //         textAlign: 'center',
  //         padding: '16px',
  //       }}
  //     >
  //       Ads
  //     </div>
  //   )
  // //production인 경우 구글 광고 표시
  // return (
  //   <ins
  //     className={className}
  //     style={{
  //       overflowX: 'auto',
  //       overflowY: 'hidden',
  //       display: 'block',
  //       textAlign: 'center',
  //     }}
  //     data-ad-client={client}
  //     data-ad-slot={slot}
  //     data-ad-format={format}
  //     data-full-width-responsive={responsive}
  //     data-ad-layout-key={layoutKey}
  //   />
  // )
}

export default GoogleAdvertise

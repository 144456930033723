import React, { useEffect } from 'react'
import BN from 'bn.js'
import { DEXLAB_RPC_02, SOLANA_EXPLORER_URL, WEB_SOCKET_RPC } from '../application'
import { Button, Descriptions, Avatar, Modal, Tag } from 'antd'
import TwitterContainer from '../componentsv2/TwitterContainer'
import { PROJECT_COMMUNITY_TWITTER_KEY, PROJECT_COMMUNITY_HOMEPAGE_KEY } from '../utils/types'
import { PublicKey, Connection } from '@solana/web3.js'
import { useState } from 'react'
import { numberWithCommasNormal } from '../utils/dexlab-utils'
import { WRAPPED_SOL_MINT } from '../utils/tokens/instructions'
import { divideBnToNumber, getTokenMultiplierFromDecimals } from '../utils/utils'

export default function ProjectInfoSummary({ market }) {
  const [baseTokenTotalSupply, setBaseTokenTotalSupply] = useState(0)
  const [baseTokenDecimals, setBaseTokenDecimals] = useState(0)
  const [aboutDescription, setAboutDescription] = useState(false)

  const [isTwitterLoaded, setIsTwitterLoaded] = useState(false)
  const [projectHomepage, setProjectHomepage] = useState(undefined)
  const [projectTwitter, setProjectTwitter] = useState(undefined)

  useEffect(() => {
    setIsTwitterLoaded(false)
    try {
      const connection = new Connection(DEXLAB_RPC_02, { commitment: 'recent', wsEndpoint: WEB_SOCKET_RPC })
      if (WRAPPED_SOL_MINT.toBase58() === market.baseMint) {
        connection
          .getSupply()
          .then((result) => {
            setBaseTokenTotalSupply(
              Math.floor(divideBnToNumber(new BN(`${result.value.total}`), getTokenMultiplierFromDecimals(9))),
            )
            setBaseTokenDecimals(9)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        connection
          .getTokenSupply(new PublicKey(market.baseMint))
          .then((result) => {
            setBaseTokenTotalSupply(result.value.uiAmount ?? 0)
            setBaseTokenDecimals(result.value.decimals ?? 0)
          })
          .catch((e) => {
            console.log(e)
          })
      }
      setProjectHomepage(market.community.find((f) => f.name === PROJECT_COMMUNITY_HOMEPAGE_KEY))
      setProjectTwitter(market.community.find((f) => f.name === PROJECT_COMMUNITY_TWITTER_KEY))
    } catch (e) {
      console.log(e)
    }
  }, [market])

  useEffect(() => {
    setIsTwitterLoaded(true)
  }, [projectTwitter])

  return (
    <>
      <div style={{ margin: '10px' }} className="row">
        <div className={`col-xl-${projectTwitter ? '8' : '12'} col-lg-${projectTwitter ? '8' : '12'} col-md-12`}>
          <Descriptions title="Project Infomation" layout="vertical" bordered>
            <Descriptions.Item label="Project Name">{market.nameEn}</Descriptions.Item>
            <Descriptions.Item label="Homepage">
              {projectHomepage?.url ? (
                <a style={{ color: '#ffffff' }} href={projectHomepage.url} target="_blank" rel="noopener noreferrer">
                  {projectHomepage.url}
                </a>
              ) : (
                '-'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Description" style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  setAboutDescription(true)
                }}
              >
                View Description
              </Button>
            </Descriptions.Item>
          </Descriptions>

          {baseTokenTotalSupply > 0 && (
            <Descriptions
              title={
                <>
                  Token Infomation <Tag color="blue">{market.mainCategory}</Tag>
                  <Tag color="blue">{market.subCategory}</Tag>
                </>
              }
              style={{ marginTop: '20px' }}
              layout="vertical"
              bordered
            >
              <Descriptions.Item label="Symbol">
                <Avatar style={{ marginRight: '5px' }} src={market.iconUrl} size={35} />
                <a
                  style={{ color: '#FFFFFF' }}
                  href={`${SOLANA_EXPLORER_URL}/address/${market.baseMint}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {market.base}
                </a>
              </Descriptions.Item>
              <Descriptions.Item label="Total Supply">
                {numberWithCommasNormal(Math.floor(baseTokenTotalSupply))} {market.base}
              </Descriptions.Item>
              <Descriptions.Item label="Decimals">{baseTokenDecimals}</Descriptions.Item>
            </Descriptions>
          )}
        </div>
        {isTwitterLoaded && projectTwitter && !market.nftImageUrl ? (
          <div style={{ marginTop: '35px' }} className="col-xl-4 col-lg-4 col-md-12">
            <div style={{ textAlign: 'right', marginTop: '10px', marginRight: '3px' }}>
              {isTwitterLoaded && projectTwitter && (
                <TwitterContainer projectName={market.nameEn} url={projectTwitter.url} />
              )}
            </div>
          </div>
        ) : null}

        {market.nftImageUrl && (
          <div style={{ marginTop: '35px' }} className="col-xl-4 col-lg-4 col-md-12">
            <div style={{ textAlign: 'right', marginTop: '10px', marginRight: '3px' }}>
              <a href={market.nftImageUrl} target="_blank" rel="noopener noreferrer">
                <img style={{ width: '300px', objectFit: 'cover' }} src={market.nftImageUrl} alt={market.nameEn} />
              </a>
            </div>
          </div>
        )}

        {!market.nftImageUrl && (
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div style={{ textAlign: 'center', marginTop: '30px', marginRight: '10px' }}>
              {market.community &&
                market.community.map(({ name, url }, idx) => {
                  return (
                    <Button
                      key={idx}
                      style={{ marginLeft: '5px' }}
                      shape="round"
                      size="middle"
                      onClick={() => {
                        window.open(url)
                      }}
                    >
                      {name}
                    </Button>
                  )
                })}
            </div>
          </div>
        )}
      </div>
      <Modal
        title={`About Project`}
        visible={aboutDescription}
        onCancel={() => {
          setAboutDescription(false)
        }}
        footer={[
          <Button
            key="close"
            onClick={() => {
              setAboutDescription(false)
            }}
          >
            Close
          </Button>,
        ]}
      >
        {market.descriptionEn ?? market.descriptionKr ?? market.descriptionCn ?? '-'}
      </Modal>
    </>
  )
}

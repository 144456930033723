import React from 'react'
// import { useWallet } from '../../utils/wallet'
import { useTranslation } from 'react-i18next'

export default function NotConnectView() {
  const { t: trText } = useTranslation()
  // const { wallet, connected } = useWallet()

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {trText('toast_message_not_connect')}
      {/* <p style={{ color: 'blue', cursor: 'pointer' }} onClick={wallet!!.connect}>
        {trText('connect_wallet')}
      </p> */}
    </div>
  )
}

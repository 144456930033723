import React, { Component } from 'react'
import _ from 'lodash'
import ReactApexChart from 'react-apexcharts'

class ChartItem extends Component {
  constructor(props) {
    super(props)

    const strokeColor = props.type ? (props.type === 'UP' ? '#26A69A' : '#f6465d') : '#f6465d'
    let chartData = props.data
    if (_.size(chartData) < 3) {
      chartData = [0, 0, 0, 0]
    }
    this.state = {
      series: [
        {
          name: 'Desktops',
          data: chartData ?? [],
        },
      ],
      options: {
        chart: {
          height: this.props.height ?? 100,
          type: 'area',
          zoom: {
            enabled: false,
          },

          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: [strokeColor],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
          colors: [strokeColor],
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false,
          x: {
            format: 'dd MMM yyyy',
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    }
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="area"
        height={this.props.height ?? 100}
      />
    )
  }
}

export default ChartItem

import fetchRetry from 'fetch-retry'
import {
  DexlabInitMarketRequest,
  DexlabInitMarketConfirmRequest,
  DexlabInitMarketResponse,
  DexlabInitMarketConfirmResponse,
  DexLabMarketV2Info,
} from './../types'
import { DEXLAB_API_HOST } from '../../application'

export default class DexLabMintingApi {
  static URL: string = `${DEXLAB_API_HOST}/v1/minting-lab/listing`

  static async getBoolean(path: string) {
    try {
      const response = await fetch(this.URL + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success
      }
    } catch (err: any) {
      console.log(`Error [GET] fetching from DexLabMintingApi API ${path}: ${err}`)
    }
    return false
  }

  static async get(path: string) {
    try {
      const response = await fetch(this.URL + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error [GET] fetching from DexLabMintingApi API ${path}: ${err}`)
    }
    return null
  }

  static async post(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      } else {
        const responseJson = await response.json()
        throw new Error(responseJson.message)
      }
    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  static async put(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error [PUT] fetching from DexLabMintingApi API ${path}: ${err}`)
    }
    return null
  }

  static async retryPut(path: string, body: any) {
    // 실패시 1.5초 단위로 30번까지 재시도
    try {
      const customFetch = fetchRetry(fetch)
      const response = await customFetch(this.URL + path, {
        retryOn: [400, 500, 502],
        retries: 30,
        retryDelay: 1500,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success ? responseJson.data : null
      }
    } catch (err: any) {
      console.log(`Error [PUT] fetching from DexLabMintingApi API ${path}: ${err}`)
    }
    return null
  }

  // 내 마켓리스트 조회
  static async myMarkets(ownerWalletAddress: string): Promise<DexLabMarketV2Info[] | null | undefined> {
    return DexLabMintingApi.get(`/${ownerWalletAddress}/my`)
  }

  // 마켓 생성
  static async initMarket(request: DexlabInitMarketRequest): Promise<DexlabInitMarketResponse | null | undefined> {
    return DexLabMintingApi.post(``, request)
  }

  // 마켓생성 어카운트 트랜잭션 승인 요청
  static async initMarketFirstConfirm(
    createId: string,
    request: DexlabInitMarketConfirmRequest,
  ): Promise<DexlabInitMarketConfirmResponse | null | undefined> {
    return DexLabMintingApi.retryPut(`/${createId}/confirm-account`, request)
  }

  // 마켓 최종생성 트랜잭션 승인 요청
  static async initMarketSecondConfirm(
    createId: string,
    request: DexlabInitMarketConfirmRequest,
  ): Promise<any | null | undefined> {
    return DexLabMintingApi.retryPut(`/${createId}/confirm-market`, request)
  }
}

import { DEXLAB_IDO_API_HOST } from '../../application'
import { DexlabIdoClaimResponse } from '../../utils/types'

export default class DexLabIdoClaimApi {
  static URL: string = `${DEXLAB_IDO_API_HOST}/v1/ido/distribution`

  static async post(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        return response.json()
      } else {
        const responseJson = await response.json()
        throw new Error(responseJson.errorMessage)
      }
    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  // Claim
  static async claimOrder(marketId: string, ownerPublicKey: string, orderId: string): Promise<DexlabIdoClaimResponse> {
    return DexLabIdoClaimApi.post(`/${marketId}/claim`, {
      orderId,
      ownerPublicKey,
    })
  }
}

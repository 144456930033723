import fetchRetry from 'fetch-retry'
import {
  DexlabMyApplyWhitelistResponse,
  DexlabWhitelistApplyHistoryResponse,
  DexlabWhitelistRankResponse,
  DexlabWhitelistStakeRankResponse,
  DexlabWhitelistMyInfoResponse,
} from './../types'
import { DEXLAB_WHITE_LIST_API_HOST } from '../../application'

export default class DexlabWhitelistApi {
  static URL: string = `${DEXLAB_WHITE_LIST_API_HOST}/v1/whitelist`

  static async getBoolean(path: string) {
    try {
      const response = await fetch(this.URL + path)
      if (response.ok) {
        const responseJson = await response.json()
        return responseJson.success
      }
    } catch (err: any) {
      console.log(`Error [GET] fetching from DexLabIdoApi API ${path}: ${err}`)
    }
    return false
  }

  static async get(path: string) {
    try {
      const response = await fetch(this.URL + path)
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        const responseJson = await response.json()
        throw new Error(responseJson.errorMessage)
      }
    } catch (err: any) {
      console.log(`Error [GET] fetching from DexlabWhitelistApi API ${path}: ${err}`)
      throw new Error(err.message)
    }
  }

  static async post(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        const responseJson = await response.json()
        throw new Error(responseJson.message)
      }
    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  static async put(path: string, body: any) {
    try {
      const response = await fetch(this.URL + path, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const data = await response.json()
        return data
      }
    } catch (err: any) {
      console.log(`Error [PUT] fetching from DexlabWhitelistApi API ${path}: ${err}`)
    }
    return null
  }

  static async retryPut(path: string, body: any) {
    // 실패시 1.5초 단위로 30번까지 재시도
    try {
      const customFetch = fetchRetry(fetch)
      const response = await customFetch(this.URL + path, {
        retryOn: [400, 500, 502],
        retries: 30,
        retryDelay: 1500,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const data = await response.json()
        return data
      }
    } catch (err: any) {
      console.log(`Error [PUT] fetching from DexlabWhitelistApi API ${path}: ${err}`)
    }
    return null
  }

  static async getMyApplyWhitelists(
    walletAddress: string,
  ): Promise<DexlabMyApplyWhitelistResponse[] | null | undefined> {
    return DexlabWhitelistApi.get(`/my-apply/${walletAddress}`)
  }

  static async getMyApplyWhitelist(
    whitelistId: string,
    walletAddress: string,
  ): Promise<DexlabMyApplyWhitelistResponse | null | undefined> {
    return DexlabWhitelistApi.get(`/${whitelistId}/my-apply/${walletAddress}`)
  }

  static async getWhitelistApplyHistory(
    whitelistId: string,
  ): Promise<DexlabWhitelistApplyHistoryResponse[] | null | undefined> {
    return DexlabWhitelistApi.get(`/${whitelistId}/history`)
  }

  static async getWhitelistApplyRank(whitelistId: string): Promise<DexlabWhitelistRankResponse | null | undefined> {
    return DexlabWhitelistApi.get(`/${whitelistId}/rank`)
  }

  static async getWhitelistApplyStakeRank(
    whitelistId: string,
  ): Promise<DexlabWhitelistStakeRankResponse | null | undefined> {
    return DexlabWhitelistApi.get(`/${whitelistId}/stake-rank`)
  }

  static async getMyWhitelistApplyInfo(
    whitelistId: string,
    ownerAddress: string,
  ): Promise<DexlabWhitelistMyInfoResponse | null | undefined> {
    return DexlabWhitelistApi.get(`/${whitelistId}/stake-rank/${ownerAddress}`)
  }
}

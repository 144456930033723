import _ from 'lodash'
import { MARKETS } from '@project-serum/serum'
import { DexLabMarketInfo, DexLabMarketV2Info } from '../utils/types'
import { TOKEN_NAMES } from './token-names'
import { DEXLAB_EXTRA_MARKETS } from './dexlab-market-list'
import { TokenInfo } from '@solana/spl-token-registry'
import {
  SPL_REGISTRY_DEXLAB_EXTRA_TAG,
  SPL_REGISTRY_DEXLAB_TAG,
  SPL_REGISTRY_WORM_TAG,
} from '../swap/context/TokenList'
import { SOL_MINT, WRAPPED_SOL_MINT } from '../swap/utils/pubkeys'

export const USDC_TOKEN: any = {
  chainId: 101,
  address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  name: 'USDC',
  decimals: 6,
  symbol: 'USDC',
  logoURI:
    'https://raw.githubusercontent.com/trustwallet/assets/f3ffd0b9ae2165336279ce2f8db1981a55ce30f8/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
  tags: [SPL_REGISTRY_DEXLAB_EXTRA_TAG, SPL_REGISTRY_DEXLAB_TAG, SPL_REGISTRY_WORM_TAG],
}

export const SOL_TOKEN_INFO = {
  chainId: 101,
  address: SOL_MINT.toString(),
  name: 'Solana',
  decimals: 9,
  symbol: 'SOL',
  logoURI: 'https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png',
  tags: [SPL_REGISTRY_DEXLAB_TAG],
  extensions: {
    website: 'https://solana.com/',
    serumV3Usdc: '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT',
    serumV3Usdt: 'HWHvQhFmJB3NUcu1aihKmrKegfVxBEHzwVX6yZCKEsi1',
    coingeckoId: 'solana',
    waterfallbot: 'https://t.me/SOLwaterfall',
  },
}

export function convertDexlabMarketToTokenInfo(dexlabMarkets: DexLabMarketV2Info[]): TokenInfo[] {
  const tokenList: TokenInfo[] = []
  tokenList.push(SOL_TOKEN_INFO)
  tokenList.push(USDC_TOKEN)
  dexlabMarkets
    .filter((f) => f.source !== 'ONLY_URL')
    .filter((f) => f.quote === 'USDC' || f.quote === 'USDT')
    .forEach((market: DexLabMarketV2Info) => {
      let marketSourceTag: string | null = null
      if (market.source === 'WORMHOLE') {
        marketSourceTag = SPL_REGISTRY_WORM_TAG
      } else if (market.source === 'UNCERTIFIED' || market.source === 'NOT_LISTING') {
        marketSourceTag = SPL_REGISTRY_DEXLAB_EXTRA_TAG
      } else {
        marketSourceTag = SPL_REGISTRY_DEXLAB_TAG
      }

      let extensions = {}
      if (market.quote === 'USDC') {
        extensions = {
          serumV3Usdc: market.address,
        }
      } else if (market.quote === 'USDT') {
        extensions = {
          serumV3Usdt: market.address,
        }
      }

      if (!tokenList.find((f) => f.address === market.baseMint) && market.baseMint !== WRAPPED_SOL_MINT.toBase58()) {
        tokenList.push({
          chainId: 101,
          address: market.baseMint,
          name: market.nameEn,
          decimals: market.baseTokenDecimals,
          symbol: market.base,
          logoURI: market.iconUrl,
          extensions,
          tags: marketSourceTag ? [marketSourceTag] : [],
        })
      }
    })
  return tokenList
}

export function getAllDexlabMarket(): DexLabMarketInfo[] {
  const marketResults: DexLabMarketInfo[] = []
  SERUM_MARKETS.forEach((market) => {
    const findMarket = findDexlabMarketBySymbol(market.symbol)
    if (findMarket !== undefined) {
      marketResults.push(findMarket)
    }
  })
  marketResults.push(...DEXLAB_EXTRA_MARKETS)
  return marketResults
}

export function findDexlabMarketBySymbol(symbol: string): DexLabMarketInfo | undefined {
  const serumMarket = findSerumMarketBySymbol(symbol)
  if (serumMarket !== undefined) {
    const findQuote = DEXLAB_MARKET_NAMES.find((f) => f.symbol === symbol)
    const findName = DEXLAB_MARKET_NAMES.find((f) => f.symbol.split('/')[0] === symbol.split('/')[0])
    return {
      address: serumMarket?.address,
      programId: serumMarket?.programId,
      nameEn: findName?.nameEn ?? serumMarket?.symbol,
      nameKo: findName?.nameKo ?? serumMarket?.symbol,
      symbol: serumMarket?.symbol,
      icon: findName?.icon,
      quoteLabel: serumMarket?.symbol.split('/')[0],
      baseLabel: serumMarket?.symbol.split('/')[1],
      deprecated: findQuote?.deprecated ?? serumMarket?.deprecated,
      chart: findQuote?.chart ?? false,
      chartType: findQuote?.chartType ?? undefined,
      type: findQuote?.type ?? 'GENERAL',
      isNew: findQuote?.isNew ?? false,
    } as DexLabMarketInfo
  } else {
    return undefined
  }
}

const SERUM_MARKETS: DexLabMarketInfo[] = _.uniqBy(
  MARKETS.filter((f) => !f.deprecated).map((market) => {
    return {
      address: market.address,
      nameEn: market.name.toUpperCase(),
      nameKo: market.name.toUpperCase(),
      symbol: market.name.toUpperCase(),
      icon: null,
      programId: market.programId,
      quoteLabel: market.name.split('/')[0],
      baseLabel: market.name.split('/')[1],
      deprecated: market.deprecated,
      chart: true,
      chartType: undefined,
      type: 'GENERAL',
    } as DexLabMarketInfo
  }),
  'symbol',
)

const DEXLAB_MARKET_NAMES: DexLabMarketInfo[] = TOKEN_NAMES.filter((f) => !f.deprecated).map((market) => {
  const serumMarket = SERUM_MARKETS.find((f) => f.symbol === market.symbol)
  return {
    address: serumMarket?.address,
    programId: serumMarket?.programId,
    nameEn: market.nameEn,
    nameKo: market.nameKo,
    symbol: market.symbol,
    icon: market.icon,
    quoteLabel: market.quoteLabel,
    baseLabel: market.baseLabel,
    deprecated: market.deprecated,
    chart: market.chart,
    chartType: market.chartType,
    type: market.type,
    isNew: market.isNew,
  } as DexLabMarketInfo
})

function findSerumMarketBySymbol(symbol: string): DexLabMarketInfo | undefined {
  return SERUM_MARKETS.find((f) => f.symbol === symbol) ?? undefined
}

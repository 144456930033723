import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button } from 'antd'
import { SOLANA_EXPLORER_URL } from '../../application'
import DataTable from '../layout/DataTable'
import Link from '../Link'

export default function TransactionHistoryTable({ parentHistory }) {
  const [tableData, setTableData] = useState<any[]>([])

  useEffect(() => {
    const historys = _.sortBy(
      JSON.parse(localStorage.getItem('tx_history') ?? '[]').slice(0, 50),
      (f) => f.DATE,
    ).reverse()
    const data = historys.map((h, idx) => {
      return {
        key: idx,
        txId: h.TX_ID,
        date: h.DATE,
        action: { txId: h.TX_ID },
      }
    })
    setTableData([...data])
  }, [parentHistory])

  const deleteHistory = (txId) => {
    const newSaveData = tableData.filter((f) => f.txId !== txId)
    localStorage.setItem(
      'tx_history',
      JSON.stringify(
        newSaveData.map((m) => {
          return {
            TX_ID: m.txId,
            DATE: m.date,
          }
        }),
      ),
    )
    setTableData([...newSaveData])
  }

  const columns = [
    {
      title: 'Transaction Signature',
      dataIndex: 'txId',
      key: 'txId',
      render: (txId) => (
        <>
          <Link external to={`${SOLANA_EXPLORER_URL}/tx/${txId}`} style={{ color: '#FFFFFF' }}>
            {txId.slice(0, 15)}...{txId.slice(txId.length - 15)}
          </Link>
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Action',
      key: 'action',
      render: ({ txId }) => {
        return (
          <Button
            onClick={() => {
              deleteHistory(txId)
            }}
          >
            Delete
          </Button>
        )
      },
    },
  ].filter((x) => x)
  return <DataTable emptyLabel={`No Traction`} dataSource={tableData} columns={columns} pagination={false} />
}

import React from 'react'
import { Button, Modal, Descriptions, Tooltip, Avatar } from 'antd'
import { SOLANA_EXPLORER_URL } from '../application'

export default function IdoOrderDetailModal({ orderInfo, orderDetailModal, setOrderDetailModal }) {
  return (
    <>
      <Modal
        title={orderInfo.orderId}
        centered
        visible={orderDetailModal}
        onCancel={() => {
          setOrderDetailModal(false)
        }}
        footer={[
          <Button
            key="close"
            onClick={() => {
              setOrderDetailModal(false)
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <Descriptions size="small">
            <Descriptions.Item label="" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '20px' }}>
              <Avatar size={25} style={{ marginRight: '7px', marginBottom: '5px' }} src={orderInfo.logoUrl} />
              <span>
                {orderInfo.base}-{orderInfo.quote}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Name" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              {orderInfo.name}
            </Descriptions.Item>
            <Descriptions.Item label="Base" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              {orderInfo.base}
            </Descriptions.Item>
            <Descriptions.Item label="Purchase amount" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              {orderInfo.txAmount} {orderInfo.quote}
            </Descriptions.Item>
            <Descriptions.Item label="Amount to be deposited" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              {parseFloat(orderInfo.withdrawBaseTokenAmount)} {orderInfo.base}
            </Descriptions.Item>
            {orderInfo.saleTxId && (
              <Descriptions.Item label="Purchase transaction" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <Tooltip
                  color="geekblue"
                  placement="topLeft"
                  title={
                    <>
                      <a
                        style={{ color: '#FFFFFF' }}
                        href={`${SOLANA_EXPLORER_URL}/tx/${orderInfo.saleTxId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {orderInfo.saleTxId}
                      </a>
                    </>
                  }
                >
                  <Button>View Transaction</Button>
                </Tooltip>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Method" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              {orderInfo.withdrawType}
            </Descriptions.Item>
            <Descriptions.Item label="Date" span={3} style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
              {orderInfo.createdAt}
            </Descriptions.Item>
          </Descriptions>

          {orderInfo.withdrawMessage && (
            <div>
              <div style={{ fontWeight: 'bold', marginTop: '30px' }}>Notice</div>
              <div>
                {orderInfo.withdrawDate && (
                  <>
                    <span>Due Date: {orderInfo.withdrawDate ?? 'To be announced'}</span>
                    <br />
                  </>
                )}
                {orderInfo.withdrawMessage}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

import React from 'react'
import { Button } from 'antd'
import { useWallet } from '../utils/wallet'
import { useTranslation } from 'react-i18next'

export default function WalletConnect() {
  const { t: trText } = useTranslation()

  const { connected, select, disconnect } = useWallet()
  return (
    <Button onClick={connected ? disconnect : select}>
      {connected ? trText('disconnect_wallet') : trText('connect_wallet')}
    </Button>
  )
}

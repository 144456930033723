import akropolis from '../assets/icons/akropolis.png'
import alpha from '../assets/icons/alpha.png'
import swag from '../assets/icons/swag.png'
import bitcoin from '../assets/icons/bitcoin.png'
import bonfida from '../assets/icons/bonfida.png'
import chainlink from '../assets/icons/chainlink.png'
import ethereum from '../assets/icons/ethereum.png'
import cream from '../assets/icons/cream.png'
import frontier from '../assets/icons/frontier.png'
import ftx from '../assets/icons/ftx.png'
import hedget from '../assets/icons/hedget.png'
import helium from '../assets/icons/helium.png'
import hxro from '../assets/icons/hxro.png'
import lua from '../assets/icons/lua.png'
import math from '../assets/icons/math.png'
import serum from '../assets/icons/serum.png'
import solana from '../assets/icons/solana.png'
import sushiswap from '../assets/icons/sushiswap.png'
import swipe from '../assets/icons/swipe.png'
import tomochain from '../assets/icons/tomochain.png'
import uniswap from '../assets/icons/uniswap.png'
import upbots from '../assets/icons/upbots.png'
import yearn from '../assets/icons/yearn.png'
import tether from '../assets/icons/tether.png'
import oxygen from '../assets/icons/oxygen.png'
import raydium from '../assets/icons/raydium.png'
import cope from '../assets/icons/cope.png'
import maps from '../assets/icons/maps.png'
import kin from '../assets/icons/kin.png'
import reserve from '../assets/icons/reserve.png'
import aave from '../assets/icons/aave.png'
import keep from '../assets/icons/keep.png'
import celsius from '../assets/icons/celsius.png'
import bilira from '../assets/icons/bilira.png'
import mer from '../assets/icons/mer.png'

export const TOKEN_NAMES = [
  {
    nameEn: 'Akropolis',
    nameKo: '아크로폴리스',
    symbol: 'AKRO/USDT',
    icon: akropolis,
    quoteLabel: 'USDT',
    baseLabel: 'AKRO',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Akropolis',
    nameKo: '아크로폴리스',
    symbol: 'AKRO/USDC',
    icon: akropolis,
    quoteLabel: 'USDC',
    baseLabel: 'AKRO',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Aleph.im',
    nameKo: '알파',
    symbol: 'ALEPH/USDT',
    icon: alpha,
    quoteLabel: 'USDT',
    baseLabel: 'ALEPH',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Bitcoin',
    nameKo: '비트코인',
    symbol: 'BTC/USDT',
    icon: bitcoin,
    quoteLabel: 'USDT',
    baseLabel: 'BTC',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Bitcoin',
    nameKo: '비트코인',
    symbol: 'BTC/USDC',
    icon: bitcoin,
    quoteLabel: 'USDC',
    baseLabel: 'BTC',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Ethereum',
    nameKo: '이더리움',
    symbol: 'ETH/USDT',
    icon: ethereum,
    quoteLabel: 'USDT',
    baseLabel: 'ETH',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Ethereum',
    nameKo: '이더리움',
    symbol: 'ETH/USDC',
    icon: ethereum,
    quoteLabel: 'USDC',
    baseLabel: 'ETH',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Cream Finance',
    nameKo: '크림 파이낸스',
    symbol: 'CREAM/USDT',
    icon: cream,
    quoteLabel: 'USDT',
    baseLabel: 'CREAM',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Frontier',
    nameKo: '프론티어',
    symbol: 'FRONT/USDT',
    icon: frontier,
    quoteLabel: 'USDT',
    baseLabel: 'FRONT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'FTX Token',
    nameKo: 'FTX 토큰',
    symbol: 'FTT/USDT',
    icon: ftx,
    quoteLabel: 'USDT',
    baseLabel: 'FTT',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Hedget',
    nameKo: '헤젯',
    symbol: 'HGET/USDT',
    icon: hedget,
    quoteLabel: 'USDT',
    baseLabel: 'HGET',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Helium',
    nameKo: '헬륨',
    symbol: 'HNT/USDT',
    icon: helium,
    quoteLabel: 'USDT',
    baseLabel: 'HNT',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Helium',
    nameKo: '헬륨',
    symbol: 'HNT/USDC',
    icon: helium,
    quoteLabel: 'USDC',
    baseLabel: 'HNT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Hxro',
    nameKo: 'Hxro',
    symbol: 'HXRO/USDT',
    icon: hxro,
    quoteLabel: 'USDT',
    baseLabel: 'HXRO',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Chainlink',
    nameKo: '체인링크',
    symbol: 'LINK/USDT',
    icon: chainlink,
    quoteLabel: 'USDT',
    baseLabel: 'LINK',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Lua Token',
    nameKo: '루아',
    symbol: 'LUA/USDT',
    icon: lua,
    quoteLabel: 'USDT',
    baseLabel: 'LUA',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Math',
    nameKo: '매스',
    symbol: 'MATH/USDT',
    icon: math,
    quoteLabel: 'USDT',
    baseLabel: 'MATH',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Mega Serum',
    nameKo: '메가세럼',
    symbol: 'MSRM/USDT',
    icon: serum,
    quoteLabel: 'USDT',
    baseLabel: 'MSRM',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Solana',
    nameKo: '솔라나',
    symbol: 'SOL/USDT',
    icon: solana,
    quoteLabel: 'USDT',
    baseLabel: 'SOL',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Solana',
    nameKo: '솔라나',
    symbol: 'SOL/USDC',
    icon: solana,
    quoteLabel: 'USDC',
    baseLabel: 'SOL',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Serum',
    nameKo: '세럼',
    symbol: 'SRM/USDT',
    icon: serum,
    quoteLabel: 'USDT',
    baseLabel: 'SRM',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Serum',
    nameKo: '세럼',
    symbol: 'SRM/USDC',
    icon: serum,
    quoteLabel: 'USDC',
    baseLabel: 'SRM',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'SushiSwap',
    nameKo: '스시스왑',
    symbol: 'SUSHI/USDT',
    icon: sushiswap,
    quoteLabel: 'USDT',
    baseLabel: 'SUSHI',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'SushiSwap',
    nameKo: '스시스왑',
    symbol: 'SUSHI/USDC',
    icon: sushiswap,
    quoteLabel: 'USDC',
    baseLabel: 'SUSHI',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Swipe',
    nameKo: '스와이프',
    symbol: 'SXP/USDT',
    icon: swipe,
    quoteLabel: 'USDT',
    baseLabel: 'SXP',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Swipe',
    nameKo: '스와이프',
    symbol: 'SXP/USDC',
    icon: swipe,
    quoteLabel: 'USDC',
    baseLabel: 'SXP',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'TomoChain',
    nameKo: '토모체인',
    symbol: 'TOMO/USDT',
    icon: tomochain,
    quoteLabel: 'USDT',
    baseLabel: 'TOMO',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'TomoChain',
    nameKo: '토모체인',
    symbol: 'TOMO/USDC',
    icon: tomochain,
    quoteLabel: 'USDC',
    baseLabel: 'TOMO',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'UpBots',
    nameKo: '업봇',
    symbol: 'UBXT/USDT',
    icon: upbots,
    quoteLabel: 'USDT',
    baseLabel: 'UBXT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Uniswap',
    nameKo: '유니스왑',
    symbol: 'UNI/USDT',
    icon: uniswap,
    quoteLabel: 'USDT',
    baseLabel: 'UNI',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Uniswap',
    nameKo: '유니스왑',
    symbol: 'UNI/USDC',
    icon: uniswap,
    quoteLabel: 'USDC',
    baseLabel: 'UNI',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Yearn.finance',
    nameKo: 'Yearn 파이낸스',
    symbol: 'YFI/USDT',
    icon: yearn,
    quoteLabel: 'USDT',
    baseLabel: 'YFI',
    deprecated: false,
    chart: true,
    chartType: 'BINANCE',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Yearn.finance',
    nameKo: 'Yearn 파이낸스',
    symbol: 'YFI/USDC',
    icon: yearn,
    quoteLabel: 'USDC',
    baseLabel: 'YFI',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Bonfida',
    nameKo: '본피다',
    symbol: 'FIDA/USDT',
    icon: bonfida,
    quoteLabel: 'USDT',
    baseLabel: 'FIDA',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Serum',
    nameKo: '세럼',
    symbol: 'SRM/SOL',
    icon: serum,
    quoteLabel: 'USDT',
    baseLabel: 'SRM',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Tether',
    nameKo: '테더',
    symbol: 'USDT/USDC',
    icon: tether,
    quoteLabel: 'USDC',
    baseLabel: 'USDT',
    deprecated: false,
    chart: true,
    chartType: 'KUCOIN',
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Swag',
    nameKo: '스웨그',
    symbol: 'SWAG/USDT',
    icon: swag,
    quoteLabel: 'USDT',
    baseLabel: 'SWAG',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Kin',
    nameKo: '킨',
    symbol: 'KIN/USDT',
    icon: kin,
    baseLabel: 'KIN',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Reserve Rights',
    nameKo: '리저브',
    symbol: 'RSR/USDT',
    icon: reserve,
    baseLabel: 'RSR',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Maps',
    nameKo: '맵스',
    symbol: 'MAPS/USDC',
    icon: maps,
    baseLabel: 'MAPS',
    quoteLabel: 'USDC',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Oxygen',
    nameKo: '옥시젠',
    symbol: 'OXY/USDT',
    icon: oxygen,
    baseLabel: 'OXY',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Cope',
    nameKo: '코프',
    symbol: 'COPE/USDC',
    icon: cope,
    baseLabel: 'COPE',
    quoteLabel: 'USDC',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'xCope',
    nameKo: 'x코프',
    symbol: 'XCOPE/USDC',
    icon: cope,
    baseLabel: 'XCOPE',
    quoteLabel: 'USDC',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Raydium',
    nameKo: '레이디움',
    symbol: 'RAY/USDT',
    icon: raydium,
    baseLabel: 'RAY',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Aave',
    nameKo: '아베',
    symbol: 'AAVE/USDT',
    icon: aave,
    baseLabel: 'AAVE',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Celsius',
    nameKo: 'Celsius',
    symbol: 'CEL/USDT',
    icon: celsius,
    baseLabel: 'CEL',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Bilira',
    nameKo: '빌리라',
    symbol: 'TRYB/USDT',
    icon: bilira,
    baseLabel: 'TRYB',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Keep Network',
    nameKo: '킵 네트워크',
    symbol: 'KEEP/USDT',
    icon: keep,
    baseLabel: 'KEEP',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Mercurial Finance',
    nameKo: '머큐리얼 파이낸스',
    symbol: 'MER/USDT',
    icon: mer,
    baseLabel: 'MER',
    quoteLabel: 'USDT',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: false,
  },
  {
    nameEn: 'Mercurial Finance',
    nameKo: '머큐리얼 파이낸스',
    symbol: 'MER/USDC',
    icon: mer,
    baseLabel: 'MER',
    quoteLabel: 'USDC',
    deprecated: false,
    chart: false,
    chartType: undefined,
    type: 'GENERAL',
    isNew: true,
  },
]

import React from 'react'
import styled from 'styled-components'
import { Row, Col, Result, Button } from 'antd'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
`

export default function CommonErrorPage() {
  function goToHome() {
    window.location.href = 'https://trade-pro.dexlab.space'
  }
  return (
    <Wrapper style={{ flex: 1, paddingTop: '60px' }}>
      <Row justify="center">
        <Col>
          <Result
            status="500"
            title="500"
            subTitle={<span style={{ color: '#FFF' }}>Sorry, something went wrong.</span>}
            extra={
              <Button type="primary" onClick={goToHome}>
                Back Home
              </Button>
            }
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

import DexlabAPI, { ExchangeEventRequest, CancelEventRequest, SettlementEventRequest } from './dexlabLoggingConnector'

// 매수,매도 이벤트
export function eventExchange(event: ExchangeEventRequest) {
  DexlabAPI.eventExchange({
    ...event,
    ref: 'WEB',
  } as ExchangeEventRequest)
    .then((_) => {})
    .catch((_) => {})
}

// 취소 이벤트
export function eventCancel(event: CancelEventRequest) {
  DexlabAPI.eventCancel({
    ...event,
    ref: 'WEB',
  } as CancelEventRequest)
    .then((_) => {})
    .catch((_) => {})
}

// 정산 이벤트
export function eventSettlement(event: SettlementEventRequest) {
  DexlabAPI.eventSettlement({
    ...event,
    ref: 'WEB',
  } as SettlementEventRequest)
    .then((_) => {})
    .catch((_) => {})
}

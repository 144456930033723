import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Result, Button, Divider } from 'antd'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import DexLabIdoApi from '../utils/client/dexlabIdoApiConnector'
import { EventSaleStatus } from '../utils/types'
import IdoPoolCard from '../componentsv2/IdoPoolCard'
import { DEXLAB_TELEGRAM, DEXLAB_TWITTER, DEXLAB_DISCORD } from '../utils/dexlab-utils'

// function refreshPriceInterval(setRefreshTime) {
//   setInterval(() => {
//     setRefreshTime(moment.valueOf())
//   }, 60000)
// }

const END_SALE_TYPES = ['WHITELIST_RANK', 'WHITELIST', 'WHITELIST_LOTTERY']

export default function IdoMarketListPage() {
  document.title = `IDO Markets - The best DEX platform on SOLANA.`
  const { t: trText } = useTranslation()

  // const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  // const [refreshTime, setRefreshTime] = useState(moment.valueOf())

  const [idoUpCommingMarketList, setIdoUpCommingMarketList] = useState<any[]>()
  const [idoOnGoingMarketList, setIdoOnGoingMarketList] = useState<any[]>()
  const [idoEndMarketList, setIdoEndMarketList] = useState<any[]>()

  // useEffect(() => {
  //   refreshPriceInterval(setRefreshTime)
  // }, [])

  useEffect(() => {
    getIdoMarketList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getIdoMarketList() {
    setIsLoading(true)
    const response = await DexLabIdoApi.getIdoMarketList()
    if (response) {
      const results = response.map((r) => {
        // let isSale = false
        let stateText = ''

        if (r.saleStatus === EventSaleStatus.ON_SALE) {
          // isSale = true
          stateText = trText('ido_on_sale')
        } else if (r.saleStatus === EventSaleStatus.END_SALE) {
          stateText = trText('ido_completed_sale')
        } else if (r.saleStatus === EventSaleStatus.UP_COMING) {
          stateText = trText('ido_coming_sale')
        } else if (r.saleStatus === EventSaleStatus.WHITELIST_UP_COMING) {
          stateText = trText('ido_whitelist_up_coming')
        } else if (r.saleStatus === EventSaleStatus.WHITELIST_PROGRESS) {
          stateText = trText('ido_whitelist_Progress')
        }

        return {
          ...r,
          stateText,
          percent: (Number(r.saleQuantity) / Number(r.totalAvailableQuantity)) * 100,
        }
      })

      const ongoingIdos = results.filter(
        (f) => f.saleStatus === EventSaleStatus.ON_SALE || f.saleStatus === EventSaleStatus.WHITELIST_PROGRESS,
      )
      const endIdos = results.filter(
        (f) =>
          f.saleStatus === EventSaleStatus.END_SALE &&
          END_SALE_TYPES.includes(f.saleType) &&
          !f.name.includes('Round 2'),
      )
      const upcomingIdos = results.filter(
        (f) => f.saleStatus === EventSaleStatus.UP_COMING || f.saleStatus === EventSaleStatus.WHITELIST_UP_COMING,
      )

      setIdoOnGoingMarketList(ongoingIdos)
      setIdoEndMarketList(endIdos)
      setIdoUpCommingMarketList(upcomingIdos)
    }
    setIsLoading(false)
  }

  return (
    // @ts-ignore
    <FloatingElement style={{ flex: 1, paddingTop: 30 }}>
      {!_.isEmpty(idoOnGoingMarketList) ? (
        <>
          <div>
            <div style={{ height: '70px', textAlign: 'center', marginTop: '20px', fontSize: '25px' }}>
              <b>{trText('ido_on_going_title')}</b>
            </div>
          </div>
          <IdoPoolCard saleMarketList={idoOnGoingMarketList} isEnded={false} />
        </>
      ) : !isLoading && _.isEmpty(idoUpCommingMarketList) ? (
        <Result
          style={{ marginTop: '35px' }}
          status="success"
          title={trText('ido_token_sale_wating_title')}
          subTitle={trText('ido_token_sale_wating_subtitle')}
          extra={[
            <Button
              type="primary"
              key="telegram"
              onClick={() => {
                window.open(DEXLAB_TELEGRAM)
              }}
            >
              Telegram
            </Button>,
            <Button
              type="primary"
              key="twitter"
              onClick={() => {
                window.open(DEXLAB_TWITTER)
              }}
            >
              Twitter
            </Button>,
            <Button
              type="primary"
              key="discord"
              onClick={() => {
                window.open(DEXLAB_DISCORD)
              }}
            >
              Discord
            </Button>,
          ]}
        />
      ) : null}
      {!_.isEmpty(idoUpCommingMarketList) ? (
        <>
          <div
            style={{
              height: '60px',
              textAlign: 'center',
              marginTop: !_.isEmpty(idoOnGoingMarketList) ? '150px' : '0px',
              fontSize: '25px',
            }}
          >
            <Divider>{trText('ido_up_coming_title')}</Divider>
          </div>
          <IdoPoolCard saleMarketList={idoUpCommingMarketList} isEnded={false} />
        </>
      ) : null}

      {!_.isEmpty(idoEndMarketList) ? (
        <>
          <div
            style={{
              height: '60px',
              textAlign: 'center',
              marginTop: '60px',
              fontSize: '25px',
            }}
          >
            <Divider>{trText('ido_closing_sale_title')}</Divider>
          </div>
          <IdoPoolCard saleMarketList={idoEndMarketList} isEnded={true} />
        </>
      ) : null}

      {/* <div className="card-body" style={{ marginTop: '60px' }}>
        {!_.isEmpty(idoEndMarketList) ? (
          <div className="table-responsive">
            <div>
              <Divider>{trText('ido_closing_sale_title')}</Divider>
            </div>
            <table className="table table-striped">
              <thead>
                <tr style={{ color: '#FFFFFF' }}>
                  <th>#</th>
                  <th>Name</th>
                  <th>Sale date(UTC)</th>
                  <th>Symbol</th>
                  <th>Pair</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {idoEndMarketList?.map((market, idx) => {
                  const sDate = moment(market.startDate)
                  const eDate = moment(market.endDate)
                  return (
                    <tr key={idx} style={{ color: '#FFFFFF' }}>
                      <td>{idx + 1}</td>
                      <td>
                        <Link to={`${getIdoPageUrl(market.id, market.saleType)}`}>
                          <Avatar size={25} style={{ marginRight: '5px', marginBottom: '5px' }} src={market.logoUrl} />{' '}
                          <span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>{market.name}</span>
                        </Link>
                      </td>
                      <td>
                        {sDate.format('YYYY-MM-DD HH:mm:ss')} ~ {eDate.format('YYYY-MM-DD HH:mm:ss')}
                      </td>
                      <td>{market.base}</td>
                      <td>
                        {market.base}-{market.quote}
                      </td>
                      <td>
                        <Progress
                          strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                          }}
                          percent={100}
                          status={'success'}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div> */}
    </FloatingElement>
  )
}

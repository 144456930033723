/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { SOLANA_EXPLORER_URL } from '../application'
import { Card, Avatar, Descriptions, Badge, Row, Col, Progress, Button, Tag, Tabs, Collapse } from 'antd'
import { useTranslation } from 'react-i18next'
import { useConnection } from '../utils/connection'
import { useWallet } from '../utils/wallet'
import { numberWithCommasNormal } from '../utils/dexlab-utils'
import { useWalletBalanceByAccount } from '../utils/dex-markets'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import { getTokenSymbolImageUrl } from '../utils/tokens-v2'
import { useSendTransaction } from '../utils/tokens/notifications'
import { notify } from '../utils/notifications'
import DexLabIdoApi from '../utils/client/dexlabIdoApiConnector'
import {
  DexlabIdoMarketDetail,
  EventSaleStatus,
  withdrawTypeText,
  DexlabIdoMarketTransactionResponse,
  DexlabIdoFaqResponse,
  MyIdoWhitelistResponse,
} from '../utils/types'
import BuyIdoTokenModal from '../componentsv2/BuyIdoTokenModal'
import TransactionHistoryItem from '../componentsv2/TransactionHistoryItem'

const { Panel } = Collapse
const { TabPane } = Tabs

enum PROCESS_STEP {
  NONE = 0,
  STEP_1 = 1,
  STEP_2 = 2,
  DONE = 4,
  ERROR = 9,
}

function refreshPriceInterval(setRefreshTime) {
  setInterval(() => {
    setRefreshTime(moment.valueOf())
  }, 10000)
}

export default function IdoPage() {
  document.title = `IDO Token Sale - The best DEX platform on SOLANA.`

  const { i18n } = useTranslation()
  const { marketId } = useParams()
  const { wallet, connected, select } = useWallet()

  const [refreshTime, setRefreshTime] = useState(moment.valueOf())
  const [saleMarketInfo, setSaleMarketInfo] = useState<DexlabIdoMarketDetail | undefined>(undefined)
  const [saleMarketTransactions, setSaleMarketTransactions] = useState<DexlabIdoMarketTransactionResponse[]>([])
  const [idoFaqList, setIdoFaqList] = useState<DexlabIdoFaqResponse[]>([])
  const [whiteListWinner, setWhiteListWinner] = useState<MyIdoWhitelistResponse | undefined>(undefined)

  // const [kycInfo, setKycInfo] = useState<DexlabKycInfoResponse | null>(null)
  // const [isKycLoading, setIsKycLoading] = useState<boolean>(false)

  // 자동 새로고침 등록
  useEffect(() => {
    refreshPriceInterval(setRefreshTime)
    getIdoMarketInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getIdoFaqList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  useEffect(() => {
    if (wallet && wallet?.connected) {
      getIdoMarketInfo()
      getWhiteListChackWinner()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.connected])

  useEffect(() => {
    getIdoMarketTransactionHistory()
  }, [refreshTime])

  async function getIdoFaqList() {
    const response = await DexLabIdoApi.getIdoFaqList(i18n.language)
    if (response) {
      setIdoFaqList(response)
    }
  }

  async function getWhiteListChackWinner() {
    const publicKey = connected && wallet ? wallet.publicKey.toBase58() : undefined
    if (publicKey) {
      // setIsKycLoading(true)
      const response = await DexLabIdoApi.checkWhiteListWinner(marketId, publicKey)
      if (response) {
        setWhiteListWinner(response)
      }
      // setIsKycLoading(false)
    }
  }

  async function getIdoMarketInfo() {
    const publicKey = connected && wallet ? wallet.publicKey.toBase58() : undefined
    const response = await DexLabIdoApi.getIdoMarket(marketId, publicKey)
    try {
      if (response) {
        if (response.version === 'V2') {
          window.location.href = `/#/v2/ido/${response.id}`
        } else if (response.version === 'V3') {
          window.location.href = `/#/v3/ido/${response.id}`
        } else {
          setSaleMarketInfo(response)
        }
        setSaleMarketInfo(response)
      } else {
        notify({
          message: 'Temporarily unable to load market information. Please try again in a few minutes',
          type: 'error',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getIdoMarketTransactionHistory() {
    const response = await DexLabIdoApi.getIdoMarketTransactions(marketId)
    if (response) {
      setSaleMarketTransactions(response)
    }
  }

  return (
    <FloatingElement
      // @ts-ignore
      style={{ flex: 1, paddingTop: 30, maxWidth: '1100px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
    >
      {!_.isEmpty(saleMarketInfo) && (
        <>
          <SaleDescription
            connected={connected}
            connect={select}
            idoFaqList={idoFaqList}
            saleMarketInfo={saleMarketInfo}
            whiteListWinner={whiteListWinner}
            saleMarketTransactions={saleMarketTransactions}
            // isKycLoading={isKycLoading}
            walletAddress={connected && wallet ? wallet.publicKey.toBase58() : undefined}
            reloadMarketInfo={getIdoMarketInfo}
          />
        </>
      )}
    </FloatingElement>
  )
}

function IdoPageInner({
  marketId,
  saleMarketInfo,
  buyModalVisible,
  reloadMarketInfo,
  whiteListWinner,
  setBuyModalVisible,
}) {
  const { wallet, connected } = useWallet()
  const connection = useConnection()

  const [loadingStep, setLoadingStep] = useState<PROCESS_STEP>(PROCESS_STEP.NONE)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [sendTransaction, sending] = useSendTransaction()

  useEffect(() => {
    if (loadingStep === PROCESS_STEP.NONE || loadingStep === PROCESS_STEP.DONE || loadingStep === PROCESS_STEP.ERROR) {
      // saleMarketInfo reload
      reloadMarketInfo()
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [loadingStep])

  let currentIdoWallet: any[] = [] // IDO 토큰 지갑

  // 예를들어 DXL 토큰지갑이 있는지 확인한다.
  const baseTokenWalletInfo = useWalletBalanceByAccount(saleMarketInfo.baseTokenMint, saleMarketInfo.baseTokenDecimals)
  currentIdoWallet = baseTokenWalletInfo ? [baseTokenWalletInfo] : []

  function onBuyModalClose() {
    setLoadingStep(PROCESS_STEP.NONE)
    setBuyModalVisible(false)
  }

  function onCreateTxId({ orderId, preOrder }) {
    console.log(`update txid ${orderId}`)
    console.log(`quoteUsdtPrice: ${preOrder.quoteUsdtPrice}`)
    setLoadingStep(PROCESS_STEP.STEP_1)
  }

  function onSuccess({ ownerPublicKey, signature, preOrder }) {
    notify({ message: 'Confirming transaction...', type: 'info' })
    setTimeout(() => {
      setLoadingStep(PROCESS_STEP.STEP_2)
      // 참여완료 처리(실패시 1초 단위로 재시도)
      DexLabIdoApi.orderConfirm(preOrder.orderId, {
        marketId,
        txId: signature,
        ownerPublicKey: ownerPublicKey.toBase58(),
      })
        .then((response) => {
          if (response) {
            notify({
              message: `Participation is complete. Your OrderID: ${response.orderId}`,
              type: 'success',
            })
            setLoadingStep(PROCESS_STEP.DONE)
          } else {
            notify({
              message: `An error has occurred. Please contact DEXLAB support. Your OrderID: ${preOrder.orderid}`,
              type: 'error',
            })
            setLoadingStep(PROCESS_STEP.ERROR)
          }
        })
        .catch((e) => {
          notify({
            message: `An error has occurred. Please contact DEXLAB support. Your OrderID: ${preOrder.orderid}`,
            type: 'error',
          })
          setLoadingStep(PROCESS_STEP.ERROR)
        })
    }, 1000)
  }

  function onError({ ownerPublicKey, error }) {
    setLoadingStep(PROCESS_STEP.ERROR)
    console.log(error)
    console.log(`onError!!! ${ownerPublicKey}, e: ${error.message}`)
    setErrorMessage(error.message)
    notify({
      message: `${error.message}`,
      type: 'error',
    })
  }

  async function onIdoSubmit({ fromPubkey, baseAmount, quoteAmount }) {
    setLoadingStep(PROCESS_STEP.NONE)
    if (!connected) {
      notify({ message: 'Wallet connection required.', type: 'error' })
      return
    }

    if (!saleMarketInfo) {
      notify({ message: 'Market is Not found.', type: 'error' })
      return
    }

    if (!baseAmount || Number(baseAmount) === Number(0)) {
      notify({ message: 'Enter the purchase quantity.', type: 'error' })
      return
    }

    console.log(`Sending: ${sending}`)
    setLoadingStep(PROCESS_STEP.STEP_1)
    return sendTransaction(
      { wallet, fromPubkey, connection, currentIdoWallet, saleMarketInfo },
      {
        ownerPublicKey: wallet?.publicKey,
        amount: parseFloat(quoteAmount),
      },
      { onCreateTxId, onSuccess, onError },
    )
  }

  return (
    // @ts-ignore
    <FloatingElement style={{ flex: 1, paddingTop: 10 }}>
      {saleMarketInfo && (
        <BuyIdoTokenModal
          visible={buyModalVisible}
          saleMarketInfo={saleMarketInfo}
          whiteListWinner={whiteListWinner}
          errorMessage={errorMessage}
          loadingStep={loadingStep}
          isLoading={isLoading}
          onClose={onBuyModalClose}
          onIdoSubmit={onIdoSubmit}
        />
      )}
    </FloatingElement>
  )
}

function SaleDescription({
  saleMarketInfo,
  idoFaqList,
  saleMarketTransactions,
  connected,
  connect,
  // isKycLoading,
  reloadMarketInfo,
  walletAddress,
  whiteListWinner,
}) {
  const { t: trText } = useTranslation()

  const [buyModalVisible, setBuyModalVisible] = useState(false)
  // const [kycRequestModal, setKycRequestModal] = useState(false)
  // const [isRequestKycLoading, setIsRequestKycLoading] = useState(false)

  const sDate = moment(saleMarketInfo.startDate).utc()
  const eDate = moment(saleMarketInfo.endDate).utc()

  let isSale = false
  let stateText = ''
  if (saleMarketInfo.saleStatus === EventSaleStatus.ON_SALE) {
    isSale = true
    stateText = trText('ido_on_sale')
  } else if (saleMarketInfo.saleStatus === EventSaleStatus.END_SALE) {
    stateText = trText('ido_completed_sale')
  } else if (saleMarketInfo.saleStatus === EventSaleStatus.UP_COMING) {
    stateText = trText('ido_coming_sale')
  }

  // const percent: Number = (Number(saleMarketInfo.saleQuantity) / Number(saleMarketInfo.totalAvailableQuantity)) * 100
  const txItems = saleMarketTransactions.map((tx, idx) => {
    return {
      key: idx,
      pair: { base: tx.base, quote: tx.quote },
      txId: tx.txId,
      amount: { amount: Number(tx.amount).toFixed(6), quote: tx.quote },
      withdrawBaseTokenAmount: {
        withdrawBaseTokenAmount: Number(tx.withdrawBaseTokenAmount).toFixed(6),
        base: tx.base,
      },
      createdAt: tx.createdAt,
    }
  })

  // const handleKycModalCancel = () => {
  //   setKycRequestModal(false)
  // }

  // async function handelRequestKyc() {
  //   setIsRequestKycLoading(true)
  //   try {
  //     const response = await DexLabKycApi.requestKyc({ marketId: saleMarketInfo.id, walletAddress, resendEmail: true })
  //     if (response) {
  //       notify({
  //         message: 'KYC verification requested. Check the emails that participated in the IDO',
  //         type: 'success',
  //       })
  //     } else {
  //       notify({ message: 'KYC verification request error.', type: 'error' })
  //     }
  //     setIsRequestKycLoading(false)
  //     handleKycModalCancel()
  //   } catch (e: any) {
  //     setIsRequestKycLoading(false)
  //     notify({ message: e.message, type: 'error' })
  //   }
  // }

  function BuyBotton() {
    if (!connected && saleMarketInfo.saleStatus === EventSaleStatus.ON_SALE) {
      return (
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={() => {
            connect()
          }}
        >
          {trText('ido_connect_wallet')}
        </Button>
      )
    } else if (saleMarketInfo.saleStatus === EventSaleStatus.UP_COMING) {
      return saleMarketInfo.whitelistInfo ? (
        <Button
          type="primary"
          shape="round"
          size={'large'}
          onClick={() => {
            const whitelistUrl = `https://apply-whitelist.dexlab.space/#/survey?id=${saleMarketInfo.whitelistInfo.whitelistId}&walletAddress=${walletAddress}`
            window.open(whitelistUrl)
          }}
        >
          Apply Whitelist
        </Button>
      ) : (
        <Button disabled={true} type="primary" shape="round" size={'large'}>
          Coming!
        </Button>
      )
    } else if (saleMarketInfo.saleStatus === EventSaleStatus.END_SALE) {
      return (
        <Button disabled={true} type="primary" shape="round" size={'large'}>
          {trText('ido_end_of_sale')}
        </Button>
      )
    } else {
      let isBottonDisable = saleMarketInfo.saleStatus !== 'ON_SALE' || (connected && !whiteListWinner)

      if (saleMarketInfo.saleStatus === EventSaleStatus.ON_SALE) {
        let btnText = ''
        if (!whiteListWinner) {
          // if (!isKycLoading) {
          btnText = `Didn't win the lottery`
          // }
        } else {
          if (saleMarketInfo.saleStatus === EventSaleStatus.ON_SALE) {
            // 잔여수량 검증
            if (Number(saleMarketInfo.availableQuantity) <= 0) {
              btnText = `Sold Out`
              isBottonDisable = true
            } else {
              btnText = `Buy ${saleMarketInfo.base}`
            }
          }
        }
        return (
          <Button
            disabled={isBottonDisable}
            type="primary"
            shape="round"
            size={'large'}
            onClick={() => {
              setBuyModalVisible(true)
            }}
          >
            {btnText}
          </Button>
        )
      } else {
        return (
          <Button disabled={isBottonDisable} type="primary" shape="round" size={'large'}>
            {trText('ido_to_be_sold')}
          </Button>
        )
      }
    }
  }

  return (
    <>
      <div style={{ height: '450px', width: '100%' }}>
        <Row justify="center" style={{ marginTop: '60px' }}>
          <Col xs={24} sm={24} md={12}>
            <Row>
              <Col span={24}>
                <Avatar.Group>
                  <Avatar size={50} style={{ marginRight: '5px', marginBottom: '5px' }} src={saleMarketInfo.logoUrl} />
                  <Avatar
                    size={50}
                    style={{ marginRight: '5px', marginBottom: '5px' }}
                    src={getTokenSymbolImageUrl(saleMarketInfo.quote)}
                  />
                </Avatar.Group>
              </Col>
              <Col span={24}>
                <span style={{ fontSize: '28px', fontWeight: 'bold' }}>{`${saleMarketInfo.name}`}</span>
              </Col>
              <Col span={24}>
                {sDate.format('YYYY-MM-DD HH:mm:ss')}(UTC) ~ {eDate.format('YYYY-MM-DD HH:mm:ss')}(UTC)
              </Col>
              <Col span={24}>
                <span style={{ color: '#76cbff' }}>{saleMarketInfo.baseTokenMint}</span>
              </Col>
              <Col span={24} style={{ marginTop: '30px' }}>
                <BuyBotton />
                <Button
                  style={{ marginLeft: '5px' }}
                  shape="round"
                  size={'large'}
                  onClick={() => {
                    window.open(`${SOLANA_EXPLORER_URL}/address/${saleMarketInfo.baseTokenMint}`)
                  }}
                >
                  {trText('ido_view_explorer')}
                </Button>
              </Col>
              {/* <Col span={24} style={{ marginTop: '20px' }}>
                <Badge status={isSale ? 'processing' : 'success'} text={''} />{' '}
                <Tag color={isSale ? 'processing' : 'success'}>{stateText}</Tag> <span></span>
              </Col> */}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card
              className="exchange-card"
              style={{
                maxWidth: '500px',
                minWidth: '450px',
                height: '235px',
                flex: 1,
              }}
              headStyle={{ padding: 0 }}
              bodyStyle={{ position: 'relative' }}
            >
              <div>
                <Row>
                  <Col span={24}>
                    <span style={{ fontWeight: 'bold' }}>{trText('ido_sale_amount')}</span>
                    <Tag style={{ marginLeft: '10px' }} color={isSale ? 'processing' : 'success'}>
                      {/* {stateText} - {trText('ido_sale_round')} {saleMarketInfo.stage} */}
                      {stateText}
                    </Tag>{' '}
                    <span></span>
                  </Col>
                  <Col span={24}>
                    <span style={{ fontSize: '28px', fontWeight: 'bold', color: '#76cbff' }}>
                      {numberWithCommasNormal(saleMarketInfo.totalAvailableQuantity)} {saleMarketInfo.base}
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: '50px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      <Badge status={isSale ? 'processing' : 'success'} text={''} />
                      {isSale ? trText('ido_sale_progress') : ''}
                    </span>
                  </Col>
                  <Col span={24}>
                    <Progress
                      style={{ marginTop: '5px' }}
                      strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                      }}
                      // percent={Number(percent.toFixed(2))}
                      percent={100}
                      status={'active'}
                    />
                    <div style={{ textAlign: 'right' }}>
                      <span style={{ fontSize: '11px' }}>
                        {saleMarketInfo.totalAvailableQuantity}/{saleMarketInfo.totalAvailableQuantity}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {saleMarketInfo && (
        <Tabs style={{ minHeight: '600px' }} defaultActiveKey="about">
          <TabPane tab={<span>{trText('ido_about_project')}</span>} key="about">
            <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>
              {trText('ido_about_project')}
            </div>
            {saleMarketInfo.image ? (
              <img
                style={{ width: '100%', height: '400px', objectFit: 'cover', marginBottom: '30px' }}
                src={saleMarketInfo.image}
              />
            ) : null}
            <div dangerouslySetInnerHTML={{ __html: saleMarketInfo.desc }}></div>
            <div style={{ marginBottom: '100px', marginTop: '30px', textAlign: 'center' }}>
              {saleMarketInfo.community &&
                saleMarketInfo.community.map(({ type, value }, idx) => {
                  return (
                    <>
                      <Button
                        key={idx}
                        style={{ marginLeft: '5px' }}
                        shape="round"
                        size="middle"
                        onClick={() => {
                          window.open(value)
                        }}
                      >
                        {type.replace('_', ' ')}
                      </Button>
                    </>
                  )
                })}
            </div>
          </TabPane>
          <TabPane tab={<span>{trText('ido_sale_detail')}</span>} key="market_info">
            <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>
              {trText('ido_sale_detail')}
            </div>
            <Descriptions bordered>
              <Descriptions.Item label={trText('ido_token_info_name')}>{saleMarketInfo.name}</Descriptions.Item>
              <Descriptions.Item label={trText('ido_token_info_type')}>Solana(SPL)</Descriptions.Item>
              <Descriptions.Item label={`Participate`}>{getSaleTypeText(saleMarketInfo.saleType)}</Descriptions.Item>
              <Descriptions.Item label={trText('ido_token_info_withdraw_type')}>
                {withdrawTypeText(saleMarketInfo.withdrawType)}
              </Descriptions.Item>
              <Descriptions.Item label={trText('ido_token_info_start_date')}>
                {moment.utc(saleMarketInfo.startDate).format('YYYY-MM-DD HH:mm:ss')}(UTC)
              </Descriptions.Item>
              <Descriptions.Item label={trText('ido_token_info_end_date')} span={2}>
                {moment.utc(saleMarketInfo.endDate).format('YYYY-MM-DD HH:mm:ss')}(UTC)
              </Descriptions.Item>
              {/* <Descriptions.Item span={6} label={trText('ido_sale_round')}>
                <span style={{ fontWeight: 'bold' }}>
                  {trText('ido_sale_round')} {saleMarketInfo.stage}
                </span>
              </Descriptions.Item> */}
              <Descriptions.Item span={6} label={trText('ido_token_info_sale_token')}>
                <span>
                  {saleMarketInfo.base} - {saleMarketInfo.quote}
                </span>
              </Descriptions.Item>
              <Descriptions.Item span={6} label={trText('ido_token_info_unit_price')}>
                <span>
                  ${parseFloat(Number(saleMarketInfo.unitSalePriceUsd).toFixed(saleMarketInfo.baseTokenDecimals))}
                </span>
              </Descriptions.Item>
              <Descriptions.Item span={12} label={trText('ido_token_info_sale_size')}>
                <span>
                  {saleMarketInfo.minQuantity !== 0
                    ? saleMarketInfo.minQuantity !== saleMarketInfo.maxQuantity
                      ? `${numberWithCommasNormal(saleMarketInfo.minQuantity)} ~ ${numberWithCommasNormal(
                          saleMarketInfo.maxQuantity,
                        )} ${saleMarketInfo.base}`
                      : `${numberWithCommasNormal(saleMarketInfo.minQuantity)} ${saleMarketInfo.base}`
                    : 'Individual hard cap'}
                </span>
              </Descriptions.Item>
              <Descriptions.Item span={12} label={trText('ido_token_info_sale_quantity')}>
                <span>{`${numberWithCommasNormal(saleMarketInfo.saleQuantity)} / ${numberWithCommasNormal(
                  saleMarketInfo.totalAvailableQuantity,
                )} ${saleMarketInfo.base}`}</span>
              </Descriptions.Item>
            </Descriptions>
            <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '30px', marginBottom: '15px' }}>LINK</div>
            <div style={{ marginBottom: '100px' }}>
              {saleMarketInfo.community &&
                saleMarketInfo.community.map(({ type, value }, idx) => {
                  return (
                    <>
                      <Button
                        key={idx}
                        style={{ marginLeft: '5px' }}
                        shape="round"
                        size="middle"
                        onClick={() => {
                          window.open(value)
                        }}
                      >
                        {type.replace('_', ' ')}
                      </Button>
                    </>
                  )
                })}
            </div>
          </TabPane>
          <TabPane tab={<span>{trText('ido_token_info')}</span>} key="token_info">
            <div style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px', marginBottom: '15px' }}>
              {trText('ido_token_info')}
            </div>
            <Descriptions bordered>
              <Descriptions.Item span={12} label={`Symbol`}>
                {saleMarketInfo.base}
              </Descriptions.Item>
              <Descriptions.Item span={12} label={`Decimals`}>
                {saleMarketInfo.baseTokenDecimals}
              </Descriptions.Item>
              <Descriptions.Item span={12} label={`Supply`}>
                {numberWithCommasNormal(saleMarketInfo.baseMintSupply)}
                {saleMarketInfo.base}
              </Descriptions.Item>
              <Descriptions.Item span={12} label={`Network`}>
                SOLANA
              </Descriptions.Item>
            </Descriptions>
          </TabPane>
          <TabPane tab={<span>{trText('ido_token_info_transaction_history')}</span>} key="transactions">
            <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>
              {trText('ido_token_info_transaction_history')}
            </div>
            <TransactionHistoryItem items={txItems} />
          </TabPane>
          <TabPane tab={<span>FAQ</span>} key="faq">
            <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>FAQ</div>
            <Collapse style={{ marginTop: '5px' }} defaultActiveKey={[0]}>
              {idoFaqList &&
                idoFaqList.map((faq, idx) => {
                  return (
                    <Panel header={faq.question} key={idx}>
                      <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                    </Panel>
                  )
                })}
            </Collapse>
          </TabPane>
        </Tabs>
      )}
      {connected && (
        <IdoPageInner
          marketId={saleMarketInfo.id}
          saleMarketInfo={saleMarketInfo}
          whiteListWinner={whiteListWinner}
          buyModalVisible={buyModalVisible}
          reloadMarketInfo={reloadMarketInfo}
          setBuyModalVisible={setBuyModalVisible}
        />
      )}
    </>
  )
}

function getSaleTypeText(type) {
  if (type === 'WHITELIST') {
    return 'Lottery'
  } else {
    return 'Everyone'
  }
}

import React, { Suspense, useContext } from 'react'
import { ConnectionProvider } from './utils/connection'
import { WalletProvider } from './utils/wallet'
import { useLocalStorageState } from './utils/utils'
import { GlobalStyleDark } from './global_style'
import { Spin } from 'antd'
import ErrorBoundary from './componentsv2/ErrorBoundary'
import { Routes } from './routes'
import { PreferencesProvider } from './utils/preferences'
import { ThemeContext } from './utils/Contexts/ThemeContext'
import { DEFAULT_MARKET_ADDRESS } from './application'
import { ReferrerProvider } from './utils/referrer'
// import './App.less'
import './AppDark.less'
import './css/style.css'

export default function App() {
  const [theme] = useContext(ThemeContext)
  const [marketAddress, setMarketAddress] = useLocalStorageState('marketAddress', DEFAULT_MARKET_ADDRESS)

  document.documentElement.setAttribute('data-theme', theme ?? window.localStorage.getItem('THEME', 'dark'))
  window.dataLayer.push({
    event: 'pageview',
  })

  // if (isDark(theme)) {
  //   return AppDark({ marketAddress, setMarketAddress })
  // } else {
  //   return AppLight({ marketAddress, setMarketAddress })
  // }
  return AppDark({ marketAddress, setMarketAddress })
}

function AppDark({ marketAddress, setMarketAddress }) {
  // require('./AppDark.less')
  // require('./css/style.css')
  return (
    <Suspense fallback={() => <Spin size="large" />}>
      <GlobalStyleDark />
      <ErrorBoundary>
        <ConnectionProvider>
          <ReferrerProvider>
            <WalletProvider>
              <PreferencesProvider>
                <Suspense fallback={() => <Spin size="large" />}>
                  <Routes />
                </Suspense>
              </PreferencesProvider>
            </WalletProvider>
          </ReferrerProvider>
        </ConnectionProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

// function AppLight({ marketAddress, setMarketAddress }) {
//   require('./App.less')
//   require('./css/style.css')
//   return (
//     <Suspense fallback={() => <Spin size="large" />}>
//       <GlobalStyleLight />
//       <ErrorBoundary>
//         <ConnectionProvider>
//           <MarketProvider marketAddress={marketAddress} setMarketAddress={setMarketAddress}>
//             <WalletProvider>
//               <PreferencesProvider>
//                 <Suspense fallback={() => <Spin size="large" />}>
//                   <Routes />
//                 </Suspense>
//               </PreferencesProvider>
//             </WalletProvider>
//           </MarketProvider>
//         </ConnectionProvider>
//       </ErrorBoundary>
//     </Suspense>
//   )
// }

import React from 'react'
import _ from 'lodash'
import { Card, Avatar, Typography, Row, Col, Input, Button } from 'antd'

const { Title } = Typography

export default function AddPreviewMarket({ finalRequest }) {
  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <Title style={{ marginBottom: '30px', textAlign: 'center' }} level={3}>
          Market Preview
        </Title>
        <Row justify="center" style={{ marginTop: '60px' }}>
          <Col xs={24} sm={24} md={12}>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Avatar size={60} src={finalRequest.iconUrl} />
              </Col>
              <Col span={24} style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '28px', fontWeight: 'bold' }}>{finalRequest.nameEn}</span>
              </Col>
              <Col span={24} style={{ textAlign: 'center' }}>
                {finalRequest.base} - {finalRequest.quote}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card
              className="exchange-card"
              style={{
                maxWidth: '500px',
                minWidth: '450px',
                height: '100%',
                flex: 1,
              }}
              headStyle={{ padding: 0 }}
              bodyStyle={{ position: 'relative' }}
            >
              <div>
                <Row>
                  <Col span={24}>
                    <Title style={{ marginBottom: '30px', textAlign: 'center' }} level={4}>
                      Info
                    </Title>
                    <Input addonBefore="Project Name(English)" value={finalRequest.nameEn} />
                    <Input addonBefore="Project Name(Korean)" value={finalRequest.nameKr ?? 'No Input'} />
                    <Input addonBefore="Project Name(Chinese)" value={finalRequest.nameCn ?? 'No Input'} />
                  </Col>
                  <Col span={24} style={{ marginTop: '30px' }}>
                    <Input addonBefore="Description(English)" value={finalRequest.descriptionEn ?? 'No Input'} />
                    <Input addonBefore="Description(Korean)" value={finalRequest.descriptionKr ?? 'No Input'} />
                    <Input addonBefore="Description(Chinese)" value={finalRequest.descriptionCn ?? 'No Input'} />
                  </Col>
                  <Col span={24} style={{ marginTop: '30px' }}>
                    <Input addonBefore="Main Category" value={finalRequest.mainCategory} />
                    <Input addonBefore="Sub Category" value={finalRequest.subCategory} />
                  </Col>
                  <Col span={24} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <Title style={{ marginBottom: '30px', textAlign: 'center' }} level={4}>
                      Links
                    </Title>
                    {!_.isEmpty(finalRequest.community)
                      ? finalRequest.community.map(({ name, url }, idx) => {
                          return (
                            <Button
                              key={idx}
                              style={{ marginLeft: '5px' }}
                              shape="round"
                              size="middle"
                              onClick={() => {
                                window.open(url)
                              }}
                            >
                              {name}
                            </Button>
                          )
                        })
                      : 'empty link'}
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Alert, Typography, Steps, Button, Row, Col, Spin, Checkbox } from 'antd'
import { notify } from '../../utils/notifications'
import { useDexlabRpcConnection } from '../../utils/connection'
import FloatingElement from '../../componentsv2/layout/FloatingElement'
import styled from 'styled-components'
import { useWallet } from '../../utils/wallet'
import WalletConnectGuide from '../../componentsv2/WalletConnectGuide'

import DexlabMintingAPI from '../../utils/client/dexlabMintingApiConnector'
import { TokenInfo } from '@solana/spl-token-registry'
import { DexlabInitMarketResponse } from '../../utils/types'

import InputTokenInfomation from '../../componentsv2/mintinglab/InputTokenInfomation'
import InputMarketAddress from '../../componentsv2/mintinglab/InputMarketAddress'
import AddPreviewMarket from '../../componentsv2/mintinglab/AddPreviewMarket'
import CreateCompleteMarket from '../../componentsv2/mintinglab/CreateCompleteMarket'
import { requestOwnerVerifiedTransactionForMemo } from '../../utils/wallet'
import { getSplTokens } from '../../utils/utils'

const { Title } = Typography
const { Step } = Steps

const steps = [
  {
    title: 'Token information',
  },
  {
    title: 'Market information',
  },
  {
    title: 'Preview',
  },
  {
    title: 'Creating',
  },
  {
    title: 'Complete',
  },
]

const Wrapper = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
`

enum CreateStepEnum {
  STEP_1 = 0,
  STEP_2 = 1,
  STEP_3 = 2,
  STEP_4 = 3,
  FINAL = 4,
}

/**
 * 마켓주소가 이미 있는 경우 추가
 */
export default function DexAddExistingMarketPage() {
  document.title = `Add Extra Markets - The best DEX platform on SOLANA.`

  const connection = useDexlabRpcConnection()
  const { wallet, connected } = useWallet()
  const [createStepCurrent, setCreateStepCurrent] = useState(CreateStepEnum.STEP_1)

  const next = () => {
    setCreateStepCurrent(createStepCurrent + 1)
  }

  const prev = () => {
    setCreateStepCurrent(createStepCurrent - 1)
  }
  const [submitting, setSubmitting] = useState(false)

  const [termsOfUseConfirmCheckbox, setTermsOfUseConfirmCheckbox] = useState(false)
  const [marketAddress, setMarketAddress] = useState('')
  const [inputTokenInfomation, setInputTokenInfomation] = useState<any>({})
  const [inputMarketInfomation, setInputMarketInfomation] = useState<any>({})
  const [finalRequest, setFinalRequest] = useState<any>({})
  const [splTokenList, setSplTokenList] = useState<TokenInfo[]>([])

  // 최초 진입시 데이터 리셋
  useEffect(() => {
    resetData()
    splTokens()
  }, [])

  function resetData() {
    setSubmitting(false)
    setMarketAddress('')
    setInputTokenInfomation({})
    setInputMarketInfomation({})
    setFinalRequest({})
    setCreateStepCurrent(CreateStepEnum.STEP_1)
  }

  function splTokens() {
    getSplTokens().then((tokens) => {
      setSplTokenList(tokens)
    })
  }

  // 마켓 기본정보 입력완료
  async function onCreateMarketPreview(inputMarketInfomation) {
    const finalRequest = {
      ownerWalletAddress: wallet!!.publicKey.toBase58(),
      ...inputTokenInfomation,
      ...inputMarketInfomation,
    }

    // 데이터 유효성 체크

    setInputMarketInfomation(inputMarketInfomation)
    setFinalRequest(finalRequest)
    next()
  }

  // 프리뷰(서버에서 받은 데이터로 노출 createId 기반)
  async function onCreateMarketInit() {
    try {
      setSubmitting(true)
      const initMarketInfo = await DexlabMintingAPI.initMarket({
        ownerWalletAddress: wallet!!.publicKey.toBase58(),
        ...inputTokenInfomation,
        ...inputMarketInfomation,
      })
      if (!initMarketInfo) {
        setSubmitting(false)
        notify({
          message: 'Error Create market',
          description: 'Please check the entered market information.',
          type: 'error',
        })
        return
      }
      onCreateMarketRequestTx(initMarketInfo)
    } catch (e: any) {
      console.warn(e)
      notify({
        message: 'Error listing new market',
        description: e.message,
        type: 'error',
      })
      setSubmitting(false)
    }
  }

  // 최종생성 트랜잭션 발생
  async function onCreateMarketRequestTx(initMarketInfo: DexlabInitMarketResponse) {
    try {
      if (!initMarketInfo || !initMarketInfo.address) {
        throw new Error(`Unable to register market information.`)
      }
      next()
      const txId = await requestOwnerVerifiedTransactionForMemo({
        connection,
        wallet,
        verifiedCode: initMarketInfo.id,
        marketAddress: initMarketInfo.address,
      })
      setMarketAddress(initMarketInfo.address)
      onCreateMarketStepConfirm(initMarketInfo.id, txId)
    } catch (e: any) {
      setSubmitting(false)
      console.warn(e)
      notify({
        message: 'Error listing new market',
        description: e.message,
        type: 'error',
      })
      // 오류 발생시 프리뷰 화면으로 이동
      setCreateStepCurrent(CreateStepEnum.STEP_3)
      return {
        isSuccess: false,
        marketAddress: undefined,
        txIds: [],
      }
    } finally {
      setSubmitting(false)
    }
  }

  // 트랜잭션 컨펌 및 최종완료
  async function onCreateMarketStepConfirm(createId: string, txId: string) {
    try {
      // 서버에 컨펌 요청
      const confirmResult = await DexlabMintingAPI.initMarketSecondConfirm(createId, {
        txId,
        ownerWalletAddress: wallet!!.publicKey.toBase58(),
        type: 'ADD',
      })
      setSubmitting(false)
      if (confirmResult) {
        // 성공
        setCreateStepCurrent(CreateStepEnum.FINAL)
      }
    } catch (e: any) {
      console.warn(e)
      setSubmitting(false)
      notify({
        message: 'Error listing new market',
        description: e.message,
        type: 'error',
      })
      setCreateStepCurrent(CreateStepEnum.STEP_3)
    }
  }

  function onTermsOfUseConfirmCheckbox(e) {
    setTermsOfUseConfirmCheckbox(e.target.checked)
  }

  return (
    <Wrapper>
      <FloatingElement>
        {connected && wallet ? (
          <>
            {createStepCurrent === CreateStepEnum.STEP_1 || createStepCurrent === CreateStepEnum.STEP_2 ? (
              <CreateMarketHeader />
            ) : (
              <div style={{ marginTop: '60px' }}></div>
            )}
            <Steps current={createStepCurrent}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div style={{ marginTop: '60px' }} className="steps-action">
              {createStepCurrent === CreateStepEnum.STEP_1 && (
                <>
                  <div style={{ marginTop: '30px' }}>
                    <InputTokenInfomation
                      inputTokenInfomation={inputTokenInfomation}
                      setInputTokenInfomation={setInputTokenInfomation}
                      next={next}
                    />
                  </div>
                </>
              )}
              {createStepCurrent === CreateStepEnum.STEP_2 && (
                <>
                  <div style={{ marginTop: '30px' }}>
                    <InputMarketAddress
                      splTokenList={splTokenList}
                      inputMarketInfomation={inputMarketInfomation}
                      onCreateMarketPreview={onCreateMarketPreview}
                      prev={prev}
                    />
                  </div>
                </>
              )}
              {createStepCurrent === CreateStepEnum.STEP_3 && (
                <>
                  <div style={{ marginTop: '30px' }}>
                    <AddPreviewMarket finalRequest={finalRequest} />
                  </div>
                  <div>
                    <Checkbox onChange={onTermsOfUseConfirmCheckbox}>
                      I agree to the standard{' '}
                      <a
                        href="https://docs.dexlab.space/products/getting-started/mintinglab-tutorial/extra-markets/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms and conditions
                      </a>
                      .
                    </Checkbox>
                  </div>
                  <Button
                    style={{ marginTop: '30px' }}
                    type="primary"
                    disabled={!termsOfUseConfirmCheckbox}
                    loading={submitting}
                    onClick={() => {
                      onCreateMarketInit()
                    }}
                  >
                    Add Market
                  </Button>
                  <Button
                    style={{ margin: '0 8px' }}
                    htmlType="button"
                    onClick={() => {
                      prev()
                    }}
                  >
                    Previous
                  </Button>
                </>
              )}
              {createStepCurrent === CreateStepEnum.STEP_4 && (
                <>
                  <div style={{ marginTop: '30px' }}>
                    <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                      <Spin size="large" />
                    </div>
                    <div style={{ marginTop: '30px', textAlign: 'center' }}>
                      Add a market. Approve the transaction and wait... <Spin size="small" />
                    </div>
                  </div>
                </>
              )}
              {createStepCurrent === CreateStepEnum.FINAL && (
                <div style={{ marginTop: '30px' }}>
                  <CreateCompleteMarket marketAddress={marketAddress} />
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <CreateMarketHeader />
            <NotConnectWallet />
          </>
        )}
      </FloatingElement>
    </Wrapper>
  )

  function NotConnectWallet() {
    return (
      <Row justify="center">
        <Col>
          <WalletConnectGuide />
        </Col>
      </Row>
    )
  }

  function CreateMarketHeader() {
    return (
      <div>
        <Title style={{ marginTop: '30px' }} level={2}>
          Dexlab Add Market
        </Title>
        <div style={{ marginBottom: '40px' }}>
          <span>
            The Add market is immediately listed on Dexlab (non-certified market).
            <br />
            Market modification and delisting can be managed through <b>My Markets.</b> Check out the tutorial{' '}
            <a
              href="https://docs.dexlab.space/products/minting-lab/mintinglab-tutorial/extra-markets"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </span>
        </div>
        <Alert
          message={
            <>
              Tokens not registered in the solana token-list will expose a fraud warning message to the user.
              <br />
              If you are not registered in solana token-list, please register as soon as possible.{' '}
              <a href="https://github.com/solana-labs/token-list" target="_blank" rel="noopener noreferrer">
                token-list
              </a>
            </>
          }
          type="warning"
        />
        <Alert
          message={
            <>
              Be sure to check the{' '}
              <a
                href="https://docs.dexlab.space/products/minting-lab/mintinglab-tutorial/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>{' '}
              before registering the market
              <br />
              <b>It will be reflected after 5 to 10 minutes of cache time after market creation.</b>
            </>
          }
        />
        <br />
      </div>
    )
  }
}

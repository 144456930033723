import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Checkbox } from 'antd'
import { PublicKey, Keypair, Transaction, SystemProgram, Signer } from '@solana/web3.js'
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { BN, Provider } from '@project-serum/anchor'
import { makeStyles, Card, Button, Typography, TextField, useTheme } from '@material-ui/core'
import { ExpandMore, ArrowDownward } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useSwapContext, useSwapFair } from '../context/Swap'
import {
  useDexContext,
  useOpenOrders,
  useRouteVerbose,
  useMarket,
  useRoute,
  useBbo,
  FEE_MULTIPLIER,
} from '../context/Dex'
import { useTokenMap } from '../context/TokenList'
import { useMint, useOwnedTokenAccount } from '../context/Token'
import { useCanSwap, useReferral } from '../context/Swap'
import TokenDialog from './TokenDialog'
import { SettingsButton } from './Settings'
import { InfoLabel } from './Info'
import { SOL_MINT, USDC_MINT, USDT_MINT, WRAPPED_SOL_MINT } from '../utils/pubkeys'
import { useWallet } from '../../utils/wallet'
import { DexLabMarketV2Info, DexLabHourPrice, DexLabRecentPrice } from '../../utils/types'
import DexlabAPI from '../../utils/client/dexlabApiConnector'
import DexlabMarketApi from '../../utils/client/dexlabMarketApiConnector'
import ChartItem from '../components/ChartItem'
import { Avatar, Tag } from 'antd'
import { numberWithCommasNormal } from '../../utils/dexlab-utils'
import Link from '../../componentsv2/Link'
import { notify } from '../../utils/notifications'

const useStyles = makeStyles((theme) => ({
  card: {
    width: theme.spacing(50),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 0px 30px 5px rgba(0,0,0,0.075)',
    padding: theme.spacing(2),
  },
  tab: {
    width: '50%',
  },
  settingsButton: {
    padding: 0,
  },
  swapButton: {
    width: '100%',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    fontWeight: 700,
    padding: theme.spacing(1.5),
  },
  swapToFromButton: {
    display: 'block',
    margin: '10px auto 10px auto',
    cursor: 'pointer',
  },
  amountInput: {
    fontSize: 22,
    fontWeight: 600,
  },
  input: {
    color: '#FFFFFF',
    textAlign: 'right',
  },
  swapTokenFormContainer: {
    borderRadius: theme.spacing(2),
    boxShadow: '0px 0px 15px 2px rgba(33,150,243,0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  swapTokenSelectorContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  balanceContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  maxButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '12px',
    cursor: 'pointer',
  },
  tokenButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
}))

export default function SwapCard({
  containerStyle,
  contentStyle,
  swapTokenContainerStyle,
  allV2Markets,
  selectTokenInfo,
  setIsSwapLoading,
}: {
  containerStyle?: any
  contentStyle?: any
  swapTokenContainerStyle?: any
  allV2Markets: DexLabMarketV2Info[]
  selectTokenInfo: (fromMint?: string | null, toMint?: string | null) => void
  setIsSwapLoading: any
}) {
  const [recentMarkets, setRecentMarkets] = useState<DexLabRecentPrice[]>([])
  const [market24hPriceChartData, setMarket24hPriceChartData] = useState<DexLabHourPrice[] | null>([])

  const { fromMint, toMint } = useSwapContext()
  const route = useRoute(fromMint, toMint)
  const bbo = useBbo(route && !_.isEmpty(route) ? route[0] : undefined)

  useEffect(() => {
    async function get24hChartData(marketAddress: string) {
      try {
        const response = await DexlabAPI.getMarketHourPriceByAddress(marketAddress)
        if (response) {
          setMarket24hPriceChartData(response)
        }
      } catch (e) {
        console.log(e)
      }
    }
    async function getAllMarketPrices() {
      const response = await DexlabMarketApi.getAllMarketCurrent24hPriceAndLastPrice()
      if (response) {
        setRecentMarkets(response)
      }
    }
    if (route && !_.isEmpty(route)) {
      getAllMarketPrices()
      get24hChartData(route[0].toBase58())
    }
  }, [route])

  const findMarketPriceInfo = (symbol) => {
    return recentMarkets.find((f) => f.market === symbol)
  }

  const styles = useStyles()
  const findMarketInfo = route ? allV2Markets.find((f) => f.address === route[0].toBase58()) : undefined
  const marketPriceInfo = findMarketPriceInfo(findMarketInfo?.symbol)
  const isExtra = findMarketInfo?.source === 'UNCERTIFIED' || findMarketInfo?.source === 'NOT_LISTING' ? true : false
  return (
    <>
      <Card className={styles.card} style={containerStyle}>
        <SwapHeader isExtra={isExtra} />
        <div style={contentStyle}>
          <SwapFromForm style={swapTokenContainerStyle} selectTokenInfo={selectTokenInfo} />
          <ArrowButton />
          <SwapToForm style={swapTokenContainerStyle} selectTokenInfo={selectTokenInfo} />
          <InfoLabel />
          <SwapButton allV2Markets={allV2Markets} setIsSwapLoading={setIsSwapLoading} isExtra={isExtra} />
        </div>
      </Card>
      {findMarketInfo && market24hPriceChartData && marketPriceInfo && !_.isEmpty(market24hPriceChartData) && (
        <div style={{ marginTop: '10px' }}>
          <Card className={styles.card} style={containerStyle}>
            <div className="card-header" style={{ padding: 0 }}>
              <div className="media">
                <div className="media-body">
                  <Link to={`/market/${findMarketInfo.address}`}>
                    <Avatar style={{ marginRight: '6px' }} src={findMarketInfo.iconUrl} size={35} />
                    <span style={{ color: '#FFFFFF' }}>{findMarketInfo.nameEn}</span>
                  </Link>
                </div>
              </div>
              <div className="mb-0">
                <span style={{ fontSize: '12px', color: '#6f6f6f' }}>24h Volume</span>
                <br />
                <span style={{ fontSize: '12px' }}>
                  {numberWithCommasNormal(findMarketInfo.summary.volume)} {findMarketInfo.quote}
                </span>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <h3>$ {bbo && bbo.mid ? bbo.mid.toFixed(6) : '-'}</h3>
              {parseFloat(marketPriceInfo.percent) > 0 ? (
                <span style={{ color: '#26A69A' }}>+{marketPriceInfo.percent}%</span>
              ) : (
                <span style={{ color: '#f6465d' }}>{marketPriceInfo.percent}%</span>
              )}
              <ChartItem
                data={market24hPriceChartData.map((d) => Number(d.price))}
                type={parseFloat(marketPriceInfo.percent) > 0 ? 'UP' : 'DOWN'}
              />
            </div>
          </Card>
        </div>
      )}
    </>
  )
}

export function SwapHeader({ isExtra }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '16px',
      }}
    >
      <Typography
        style={{
          fontSize: 18,
          fontWeight: 700,
        }}
      >
        SWAP <Tag color={isExtra ? 'warning' : 'green'}>{isExtra ? 'Extra Makret' : 'Official market'}</Tag>
      </Typography>
      <SettingsButton />
    </div>
  )
}

export function ArrowButton() {
  const styles = useStyles()
  const theme = useTheme()
  const { swapToFromMints } = useSwapContext()
  return (
    <ArrowDownward
      className={styles.swapToFromButton}
      fontSize="large"
      htmlColor={theme.palette.primary.main}
      onClick={swapToFromMints}
    />
  )
}

function SwapFromForm({
  style,
  selectTokenInfo,
}: {
  style?: any
  selectTokenInfo: (fromMint?: string | null, toMint?: string | null) => void
}) {
  const { fromMint, toMint, setFromMint, fromAmount, setFromAmount } = useSwapContext()
  return (
    <SwapTokenForm
      from
      style={style}
      mint={fromMint}
      fromMint={fromMint}
      toMint={toMint}
      setMint={setFromMint}
      amount={fromAmount}
      setAmount={setFromAmount}
      selectTokenInfo={selectTokenInfo}
    />
  )
}

function SwapToForm({
  style,
  selectTokenInfo,
}: {
  style?: any
  selectTokenInfo: (fromMint?: string | null, toMint?: string | null) => void
}) {
  const { toMint, fromMint, setToMint, toAmount, setToAmount } = useSwapContext()
  return (
    <SwapTokenForm
      from={false}
      style={style}
      mint={toMint}
      fromMint={fromMint}
      toMint={toMint}
      setMint={setToMint}
      amount={toAmount}
      setAmount={setToAmount}
      selectTokenInfo={selectTokenInfo}
    />
  )
}

export function SwapTokenForm({
  from,
  style,
  mint,
  fromMint,
  toMint,
  setMint,
  amount,
  setAmount,
  selectTokenInfo,
}: {
  from: boolean
  style?: any
  mint: PublicKey
  fromMint: PublicKey
  toMint: PublicKey
  setMint: (m: PublicKey) => void
  amount: number
  setAmount: (a: number) => void
  selectTokenInfo: (fromMint?: string | null, toMint?: string | null) => void
}) {
  const styles = useStyles()

  const { connected } = useWallet()
  const [showTokenDialog, setShowTokenDialog] = useState(false)
  const tokenAccount = useOwnedTokenAccount(mint)
  const mintAccount = useMint(mint)

  const balance = tokenAccount && mintAccount && Number(tokenAccount.account.amount) / 10 ** mintAccount.decimals
  const formattedAmount =
    mintAccount && amount
      ? amount.toLocaleString('fullwide', {
          maximumFractionDigits: mintAccount.decimals,
          useGrouping: false,
        })
      : amount

  return (
    <div className={styles.swapTokenFormContainer} style={style}>
      <div className={styles.swapTokenSelectorContainer}>
        <TokenButton mint={mint} onClick={() => setShowTokenDialog(true)} />
        <Typography className={styles.balanceContainer}>
          {tokenAccount !== undefined
            ? connected && tokenAccount && mintAccount
              ? `Balance: ${balance?.toFixed(mintAccount.decimals)}`
              : `-`
            : 'Loading...'}
          {from && !!balance ? (
            <span className={styles.maxButton} onClick={() => setAmount(balance)}>
              MAX
            </span>
          ) : null}
        </Typography>
      </div>
      <TextField
        type="number"
        value={formattedAmount}
        onChange={(e) => setAmount(parseFloat(e.target.value))}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: styles.amountInput,
            input: styles.input,
          },
        }}
      />
      <TokenDialog
        setMint={(r) => {
          if (from) {
            selectTokenInfo(r.toBase58(), toMint.toBase58())
          } else {
            selectTokenInfo(fromMint.toBase58(), r.toBase58())
          }
          setMint(r)
          setShowTokenDialog(false)
        }}
        open={showTokenDialog}
        onClose={() => setShowTokenDialog(false)}
      />
    </div>
  )
}

function TokenButton({ mint, onClick }: { mint: PublicKey; onClick: () => void }) {
  const styles = useStyles()
  const theme = useTheme()

  return (
    <div onClick={onClick} className={styles.tokenButton}>
      <TokenIcon mint={mint} style={{ width: theme.spacing(4) }} />
      <TokenName mint={mint} style={{ fontSize: 14, fontWeight: 700 }} />
      <ExpandMore />
    </div>
  )
}

export function TokenIcon({ mint, style }: { mint: PublicKey; style: any }) {
  const tokenMap = useTokenMap()
  let tokenInfo = tokenMap.get(mint.toString())
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {tokenInfo?.logoURI ? <img alt="Logo" style={style} src={tokenInfo?.logoURI} /> : <div style={style}></div>}
    </div>
  )
}

function TokenName({ mint, style }: { mint: PublicKey; style: any }) {
  const tokenMap = useTokenMap()
  const theme = useTheme()
  let tokenInfo = tokenMap.get(mint.toString())

  return (
    <Typography
      style={{
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        ...style,
      }}
    >
      {tokenInfo?.symbol}
    </Typography>
  )
}

// function isIncrement(num: any, step: any) {
//   return Math.abs((num / step) % 1) < 1e-5 || Math.abs(((num / step) % 1) - 1) < 1e-5
// }

export function SwapButton({ allV2Markets, setIsSwapLoading, isExtra }) {
  const styles = useStyles()
  const { t: trText } = useTranslation()
  const { wallet, connected, select } = useWallet()
  const { fromMint, toMint, fromAmount, toAmount, slippage, isClosingNewAccounts, isStrict } = useSwapContext()
  const { swapClient } = useDexContext()
  const fromMintInfo = useMint(fromMint)
  const toMintInfo = useMint(toMint)
  const openOrders = useOpenOrders()
  const route = useRouteVerbose(fromMint, toMint)
  const fromMarket = useMarket(route && route.markets ? route.markets[0] : undefined)
  const toMarket = useMarket(route && route.markets ? route.markets[1] : undefined)
  const canSwap = useCanSwap()
  const referral = useReferral(fromMarket)
  const fair = useSwapFair()
  let fromWallet = useOwnedTokenAccount(fromMint)
  let toWallet = useOwnedTokenAccount(toMint)
  const quoteMint = fromMarket && fromMarket.quoteMintAddress
  const quoteMintInfo = useMint(quoteMint)
  const quoteWallet = useOwnedTokenAccount(quoteMint)

  const [extraAgreeOrNot, setExtraAgreeOrNot] = useState<boolean>(false)
  const [keepHidingExtraAgreeOrNot, setKeepHidingExtraAgreeOrNot] = useState<boolean>(
    localStorage.getItem(`extra_agree_or_not`)
      ? localStorage.getItem(`extra_agree_or_not`) === 'Y'
        ? true
        : false
      : false,
  )
  const [isShowAgreeOrNotView, setIsShowAgreeOrNotView] = useState(false)

  useEffect(() => {
    if (!extraAgreeOrNot && !keepHidingExtraAgreeOrNot) {
      setIsShowAgreeOrNotView(true)
    } else {
      setIsShowAgreeOrNotView(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Click handler.
  const sendSwapTransaction = async () => {
    if (!fromMintInfo || !toMintInfo) {
      throw new Error('Unable to calculate mint decimals')
    }
    if (!fair) {
      throw new Error('Invalid fair')
    }
    if (!quoteMint || !quoteMintInfo) {
      throw new Error('Quote mint not found')
    }

    if (isExtra && !keepHidingExtraAgreeOrNot) {
      if (isExtra && !extraAgreeOrNot) {
        notify({
          message: 'Agree to Extra Makret Trading Risks',
          type: 'error',
        })
        return
      }
    }

    const amount = new BN(fromAmount * 10 ** fromMintInfo.decimals)
    const isSol = fromMint.equals(SOL_MINT) || toMint.equals(SOL_MINT)
    const wrappedSolAccount = isSol ? Keypair.generate() : undefined

    if (fromMarket && fromMint.toBase58() !== USDC_MINT.toBase58() && fromMint.toBase58() !== USDT_MINT.toBase58()) {
      // console.log(`FROM: ${fromAmount} / ${isIncrement(fromAmount, fromMarket?.minOrderSize)}`)
      if (fromAmount < fromMarket.minOrderSize) {
        const findTokenName = allV2Markets.find((f) => f.baseMint === fromMint.toBase58())
        notify({
          message: `${findTokenName?.base ?? ''} Token Size must be an increment of ${fromMarket?.minOrderSize || 1}`,
          type: 'error',
        })
        return
      }
    }

    if (fromMarket && toMint.toBase58() !== USDC_MINT.toBase58() && toMint.toBase58() !== USDT_MINT.toBase58()) {
      // console.log(`TO: ${toAmount} / ${isIncrement(toAmount, fromMarket?.minOrderSize)}`)
      if (toAmount < fromMarket.minOrderSize) {
        const findTokenName = allV2Markets.find((f) => f.baseMint === toMint.toBase58())
        notify({
          message: `${findTokenName?.base ?? ''} Token Size must be an increment of ${fromMarket.minOrderSize || 1}`,
          type: 'error',
        })
        return
      }
    }

    // Build the swap.
    let txs = await (async () => {
      if (!fromMarket) {
        throw new Error('Market undefined')
      }

      const minExchangeRate = {
        rate: new BN((10 ** toMintInfo.decimals * FEE_MULTIPLIER) / fair).muln(100 - slippage).divn(100),
        fromDecimals: fromMintInfo.decimals,
        quoteDecimals: quoteMintInfo.decimals,
        strict: isStrict,
      }
      const fromOpenOrders = fromMarket ? openOrders.get(fromMarket?.address.toString()) : undefined
      const toOpenOrders = toMarket ? openOrders.get(toMarket?.address.toString()) : undefined
      const fromWalletAddr = fromMint.equals(SOL_MINT)
        ? wrappedSolAccount!.publicKey
        : fromWallet
        ? fromWallet.publicKey
        : undefined
      const toWalletAddr = toMint.equals(SOL_MINT)
        ? wrappedSolAccount!.publicKey
        : toWallet
        ? toWallet.publicKey
        : undefined

      setIsSwapLoading(true)
      return await swapClient.swapTxs({
        fromMint,
        toMint,
        quoteMint,
        amount,
        minExchangeRate,
        referral,
        // @ts-ignore
        fromMarket,
        // @ts-ignore
        toMarket,
        // Automatically created if undefined.
        fromOpenOrders: fromOpenOrders ? fromOpenOrders[0].address : undefined,
        toOpenOrders: toOpenOrders ? toOpenOrders[0].address : undefined,
        fromWallet: fromWalletAddr,
        toWallet: toWalletAddr,
        quoteWallet: quoteWallet ? quoteWallet.publicKey : undefined,
        // Auto close newly created open orders accounts.
        close: isClosingNewAccounts,
      })
    })()

    // If swapping SOL, then insert a wrap/unwrap instruction.
    if (isSol) {
      if (txs.length > 1) {
        throw new Error('SOL must be swapped in a single transaction')
      }
      const { tx: wrapTx, signers: wrapSigners } = await wrapSol(
        swapClient.program.provider,
        wrappedSolAccount as Keypair,
        fromMint,
        amount,
      )
      const { tx: unwrapTx, signers: unwrapSigners } = unwrapSol(
        swapClient.program.provider,
        wrappedSolAccount as Keypair,
      )
      const tx = new Transaction()
      tx.add(wrapTx)
      tx.add(txs[0].tx)
      tx.add(unwrapTx)
      txs[0].tx = tx
      txs[0].signers.push(...wrapSigners)
      txs[0].signers.push(...unwrapSigners)
    }

    await swapClient.program.provider.sendAll(txs)
  }

  return (
    <>
      {isExtra && isShowAgreeOrNotView && (
        <Card style={{ marginTop: '6px', marginBottom: '15px', backgroundColor: '#000829' }}>
          <div style={{ color: '#FFFFFF', padding: '6px', fontSize: '13px' }}>
            <p>Extra Market is a market registered by users, and trade after confirming the project and token.</p>
            <b>I confirm that I am fully aware of the risks</b>
            <br />
            <Checkbox
              disabled={keepHidingExtraAgreeOrNot && extraAgreeOrNot}
              checked={extraAgreeOrNot}
              onChange={(e) => {
                setExtraAgreeOrNot(e.target.checked)
              }}
            >
              I confirm
            </Checkbox>
            <br />
            <Checkbox
              checked={keepHidingExtraAgreeOrNot}
              onChange={(e) => {
                setKeepHidingExtraAgreeOrNot(e.target.checked)
                localStorage.setItem('extra_agree_or_not', e.target.checked ? 'Y' : 'N')
              }}
            >
              Do not warn again
            </Checkbox>
          </div>
        </Card>
      )}
      {wallet && connected ? (
        <Button variant="contained" className={styles.swapButton} onClick={sendSwapTransaction} disabled={!canSwap}>
          {route && route.markets.length === 1 ? trText('swap') : trText('swap_not_support_market')}
        </Button>
      ) : (
        <Button variant="contained" className={styles.swapButton} onClick={select}>
          {trText('connect_wallet')}
        </Button>
      )}
    </>
  )
}

async function wrapSol(
  provider: Provider,
  wrappedSolAccount: Keypair,
  fromMint: PublicKey,
  amount: BN,
): Promise<{ tx: Transaction; signers: Array<Signer | undefined> }> {
  const tx = new Transaction()
  const signers = [wrappedSolAccount]
  // Create new, rent exempt account.
  tx.add(
    SystemProgram.createAccount({
      fromPubkey: provider.wallet.publicKey,
      newAccountPubkey: wrappedSolAccount.publicKey,
      lamports: await Token.getMinBalanceRentForExemptAccount(provider.connection),
      space: 165,
      programId: TOKEN_PROGRAM_ID,
    }),
  )
  // Transfer lamports. These will be converted to an SPL balance by the
  // token program.
  if (fromMint.equals(SOL_MINT)) {
    tx.add(
      SystemProgram.transfer({
        fromPubkey: provider.wallet.publicKey,
        toPubkey: wrappedSolAccount.publicKey,
        lamports: Number(amount),
      }),
    )
  }
  // Initialize the account.
  tx.add(
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      WRAPPED_SOL_MINT,
      wrappedSolAccount.publicKey,
      provider.wallet.publicKey,
    ),
  )
  return { tx, signers }
}

function unwrapSol(
  provider: Provider,
  wrappedSolAccount: Keypair,
): { tx: Transaction; signers: Array<Signer | undefined> } {
  const tx = new Transaction()
  tx.add(
    Token.createCloseAccountInstruction(
      TOKEN_PROGRAM_ID,
      wrappedSolAccount.publicKey,
      provider.wallet.publicKey,
      provider.wallet.publicKey,
      [],
    ),
  )
  return { tx, signers: [] }
}

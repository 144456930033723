/* eslint-disable */
import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Button, Steps, Space, Card, Row, Col, Statistic, Tag, Tooltip } from 'antd'
import { HelpOutline } from '@material-ui/icons'
import { useWallet } from '../utils/wallet'
import DexlabWhitelistApi from '../utils/client/dexlabWhitelistApiConnector'
import { DexlabMyApplyWhitelistResponse } from '../utils/types'
import DxlSnapshotHistoryModal from './DxlSnapshotHistoryModal'
import { useTranslation } from 'react-i18next'
import { ReloadOutlined } from '@ant-design/icons'

const { Step } = Steps
const DXL_MARKET_ADDRESS = 'DYfigimKWc5VhavR4moPBibx9sMcWYVSjVdWvPztBPTa'
// const WHITELIST_APPLY_MIN_DXL = 300

const { Countdown } = Statistic

export default function IdoTimelineView({
  saleMarketInfo,
  reloadMarketInfo,
  whiteListWinner,
  whiteListRank,
  // myRankMessage,
  refreshAllData,
}) {
  const { t: trText } = useTranslation()

  const { wallet, connect, connected } = useWallet()
  const [countdownEnded, setCountdownEnded] = useState(false)
  const [countdownDateTime, setCountdownDateTime] = useState<number | undefined>(undefined)
  const [whitelistSoon, setWhitelistSoon] = useState(false)
  const [myWhitelistApplyInfo, setMyWhitelistApplyInfo] = useState<DexlabMyApplyWhitelistResponse | undefined | null>(
    undefined,
  )
  const [myDxlInfoErrorMessage, setMyDxlInfoErrorMessage] = useState<string | undefined>(undefined)
  const [dxlSnapshotHistoryModal, setDxlSnapshotHistoryModal] = useState(false)

  useEffect(() => {
    setupCountDown()
  }, [saleMarketInfo])

  useEffect(() => {
    // 지갑연결 + 화리 참여한 경우
    if (wallet && connected && saleMarketInfo.whitelistInfo.participation) {
      getMyWhitelistApplyInfo() // 화리 참여정보 조회(스냅샷 정보등)
    }
  }, [connected, saleMarketInfo])

  const onCloseDxlSnapshotModal = () => {
    setDxlSnapshotHistoryModal(false)
  }

  function isWithdrawBtn() {
    const todayDate = moment().utc()
    const tokenWithdrawDate = moment(saleMarketInfo.withdrawDate).utc(false)

    if (todayDate.isAfter(tokenWithdrawDate)) {
      return true
    } else {
      return false
    }
  }

  function getStepCurrent() {
    const todayDate = moment().utc()
    // 화리 시작~종료는 시간이 string으로 내려오기 때문에 그 자체가 UTC이다. 그렇게 때문에 .utc(true)
    const whitelistStartDate = moment(saleMarketInfo.whitelistInfo.startDate).utc(true)
    const whitelistEndDate = moment(saleMarketInfo.whitelistInfo.endDate).utc(true)

    const whitelistWinnerAnnouncementDate = moment(saleMarketInfo.winnerAnnouncementDate).utc(false)

    const tokenSaleStartDate = moment(saleMarketInfo.startDate).utc(false)
    const tokenSaleEndDate = moment(saleMarketInfo.endDate).utc(false)

    const tokenWithdrawDate = moment(saleMarketInfo.withdrawDate).utc(false)

    if (todayDate.isBetween(whitelistStartDate, whitelistEndDate)) {
      return 0
    }

    if (todayDate.isBetween(whitelistWinnerAnnouncementDate, tokenSaleStartDate)) {
      return 1
    }

    if (todayDate.isBetween(tokenSaleStartDate, tokenSaleEndDate)) {
      return 2
    }

    if (todayDate.isBetween(tokenSaleEndDate, tokenWithdrawDate) || todayDate.isAfter(tokenWithdrawDate)) {
      return 3
    }

    return 0
  }

  // 내 화리 참여정보 조회(스냅샷 정보 포함)
  async function getMyWhitelistApplyInfo() {
    try {
      const response = await DexlabWhitelistApi.getMyApplyWhitelist(
        saleMarketInfo.whitelistInfo.whitelistId,
        wallet!!.publicKey.toBase58(),
      )
      if (response) {
        setMyDxlInfoErrorMessage(undefined)
        setMyWhitelistApplyInfo(response)
      }
    } catch (e: any) {
      setMyDxlInfoErrorMessage(e.message)
    }
  }

  function setupCountDown() {
    if (saleMarketInfo.whitelistInfo) {
      const baseTime = saleMarketInfo.whitelistInfo.progress
        ? saleMarketInfo.whitelistInfo.endDate
        : saleMarketInfo.whitelistInfo.startDate
      const countdownTime = moment(baseTime).utc(true).valueOf() - moment().utc().valueOf()
      const deadline = moment().utc().valueOf() + countdownTime
      setCountdownDateTime(deadline)
      if (countdownTime <= 0) {
        setCountdownEnded(true)
      } else {
        if (!saleMarketInfo.whitelistInfo.progress) {
          setWhitelistSoon(true)
        } else {
          setWhitelistSoon(false)
        }
        setCountdownEnded(false)
      }
    }
  }

  function onCountdownFinish() {
    setCountdownEnded(true)
  }

  function onStartWhitelistCountdownFinish() {
    setupCountDown()
    reloadMarketInfo()
  }

  let dxlHoldeMessage = ''
  if (connected && saleMarketInfo.whitelistInfo.participation && myWhitelistApplyInfo) {
    dxlHoldeMessage = `${myWhitelistApplyInfo.dxlQuantityText} DXL`
  } else {
    if (!saleMarketInfo.whitelistInfo.participation) {
      dxlHoldeMessage = 'Join the Whitelist'
    } else {
      if (!myWhitelistApplyInfo && myDxlInfoErrorMessage) {
        dxlHoldeMessage = myDxlInfoErrorMessage
      } else {
        dxlHoldeMessage = '-'
      }
    }
  }

  const stepCurrent = getStepCurrent()
  // let findMyRank: any = null
  // if (connected && wallet && whiteListRank && !_.isEmpty(whiteListRank.ranks)) {
  //   findMyRank = whiteListRank.ranks.find((rf) => rf.orgWalletAddress === wallet.publicKey.toBase58())
  // }
  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <h3 style={{ marginBottom: '25px' }}>{trText('ido_v2_subscription_timeline')}</h3>
        <Steps direction="vertical" style={{ marginBottom: '30px', width: '100%' }} size="small" current={stepCurrent}>
          <Step
            title={
              <>
                {saleMarketInfo.saleType === 'WHITELIST_FCFS' ? (
                  <>It can be purchased on a first-come, first-served basis.</>
                ) : (
                  <>{trText('ido_v2_subscription_timeline_step_1_title')} </>
                )}
                <span style={{ cursor: 'pointer' }} onClick={refreshAllData}>
                  <ReloadOutlined
                    style={{ fontSize: '20px', color: 'coral', paddingLeft: '5px', paddingBottom: '5px' }}
                  />
                </span>
              </>
            }
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 0 ? 'green' : 'default'}>
                    {saleMarketInfo.whitelistInfo.startDate && saleMarketInfo.whitelistInfo.endDate
                      ? `${saleMarketInfo.whitelistInfo.startDate} ~ ${saleMarketInfo.whitelistInfo.endDate}(UTC)`
                      : 'TBA'}
                  </Tag>
                </p>
                <div
                  style={{
                    width: '100%',
                    display: saleMarketInfo.saleType !== 'WHITELIST_FCFS' && stepCurrent === 0 ? '' : 'none',
                  }}
                >
                  <p>
                    {trText('ido_v2_subscription_timeline_step_4_desc_1')}{' '}
                    <a href="https://medium.com/dexlab-official/dexlab-new-ido-rule-b4e53e2c1e5f" target="_blank">
                      here
                    </a>
                    .
                  </p>
                  <Row style={{ marginTop: '35px', display: stepCurrent === 0 ? '' : 'none' }}>
                    <Col span={12} style={{ textAlign: 'left' }}>
                      {!countdownEnded && !whitelistSoon && <span>Time left until subscription starts:</span>}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        // textAlign: saleMarketInfo.saleType === 'WHITELIST_RANK' ? 'right' : 'left',
                        textAlign: 'right',
                        fontSize: '30px',
                      }}
                    >
                      {saleMarketInfo.whitelistInfo.startDate &&
                      saleMarketInfo.whitelistInfo.endDate &&
                      countdownDateTime ? (
                        !countdownEnded && !whitelistSoon ? (
                          <Countdown
                            title={trText('ido_v2_subscription_timeline_step_1_whitelist_ended_time')}
                            value={countdownDateTime}
                            onFinish={onCountdownFinish}
                            format="DD:HH:mm:ss"
                          />
                        ) : (
                          <>{countdownEnded && <span>Ended!</span>}</>
                        )
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <div style={{ display: stepCurrent === 0 ? '' : 'none' }}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Card
                        title={
                          <>
                            <span style={{ fontWeight: 'bold' }}>
                              {trText('ido_v2_subscription_timeline_step_1_avg_dxl_title')}
                            </span>{' '}
                            <Tooltip
                              title={
                                <>
                                  This is the average of DXL holdings from the start date to the end date of the
                                  whitelist.
                                  <br />
                                  <br />
                                  (1day avg + 1day avg + 1day avg) / days = average holding
                                </>
                              }
                            >
                              <HelpOutline style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </>
                        }
                      >
                        <p
                          style={{
                            fontSize: !whitelistSoon && connected ? '30px' : '17px',
                            fontWeight: 'bold',
                            textAlign: connected ? 'left' : 'center',
                          }}
                        >
                          {!whitelistSoon ? (
                            <>{connected ? dxlHoldeMessage : 'You are not connected wallet'}</>
                          ) : (
                            trText('ido_v2_subscription_timeline_step_1_whitelist_comingsoon')
                          )}
                        </p>
                        {myWhitelistApplyInfo && saleMarketInfo.saleType === 'WHITELIST_LOTTERY'
                          ? Number(myWhitelistApplyInfo.dxlQuantity) >= Number(300)
                            ? '🎉 It is currently subject to lottery! 🎉'
                            : `Average holdings are low (requires at least ${
                                300 - Number(myWhitelistApplyInfo.dxlQuantity)
                              })`
                          : null}
                        {whitelistSoon &&
                          saleMarketInfo.whitelistInfo.startDate &&
                          saleMarketInfo.whitelistInfo.endDate && (
                            <p style={{ textAlign: 'center' }}>
                              <Countdown
                                value={countdownDateTime}
                                onFinish={onStartWhitelistCountdownFinish}
                                format="DD:HH:mm:ss"
                              />
                            </p>
                          )}
                        {!whitelistSoon && (
                          <Row>
                            <Col span={12} style={{ textAlign: 'left', textDecoration: 'underline' }}>
                              {connected && myWhitelistApplyInfo && (
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setDxlSnapshotHistoryModal(true)
                                  }}
                                >
                                  DXL holding details
                                </span>
                              )}
                            </Col>
                            {connected ? (
                              <Col span={12} style={{ textAlign: 'right' }}>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    window.location.href = `/#/swap?id=${DXL_MARKET_ADDRESS}`
                                  }}
                                >
                                  Buy DXL
                                </Button>
                              </Col>
                            ) : (
                              <Col span={24} style={{ textAlign: 'center' }}>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    connect()
                                  }}
                                >
                                  Connect Wallet
                                </Button>
                              </Col>
                            )}
                          </Row>
                        )}
                        <div style={{ fontSize: '12px', color: '#646464', marginTop: '30px' }}>
                          {trText('ido_v2_subscription_timeline_step_1_avg_dxl_info_msg_1')}
                          <br />
                          {trText('ido_v2_subscription_timeline_step_1_avg_dxl_info_msg_3')}
                          <br />
                          {trText('ido_v2_subscription_timeline_step_1_avg_dxl_info_msg_4')}
                        </div>
                      </Card>
                    </Space>
                  </div>
                </div>
              </>
            }
          />
          <Step
            style={{
              display:
                saleMarketInfo.whitelistInfo.startDate && saleMarketInfo.whitelistInfo.endDate ? 'block' : 'none',
            }}
            title={trText('ido_v2_subscription_timeline_step_2_title')}
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 1 ? 'green' : 'default'}>{`${moment(
                    saleMarketInfo.winnerAnnouncementDate ?? saleMarketInfo.startDate,
                  )
                    .utc(false)
                    .format('YYYY-MM-DD HH:mm:ss')}(UTC)`}</Tag>
                </p>
                <p>
                  You can check whether you have won or not.
                  {stepCurrent === 1 && !connected && (
                    <>
                      <br />
                      Winning or not will be displayed when you connect My IDO or wallet.
                    </>
                  )}
                </p>
                {whiteListWinner ? (
                  connected &&
                  stepCurrent === 1 && (
                    <span style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '10px' }}>
                      🎉 Congratulations! 🎉
                      <br />
                      You have won the IDO sale.
                    </span>
                  )
                ) : connected && stepCurrent === 1 ? (
                  <span style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '10px' }}>Didn't Win. 😞</span>
                ) : null}
              </>
            }
          />
          <Step
            style={{
              display:
                saleMarketInfo.whitelistInfo.startDate && saleMarketInfo.whitelistInfo.endDate ? 'block' : 'none',
            }}
            title={trText('ido_v2_subscription_timeline_step_3_title')}
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 2 ? 'green' : 'default'}>{`${moment(saleMarketInfo.startDate)
                    .utc(false)
                    .format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(saleMarketInfo.endDate)
                    .utc(false)
                    .format('YYYY-MM-DD HH:mm:ss')}(UTC)`}</Tag>
                </p>
                {whiteListWinner && stepCurrent === 2 ? (
                  <span style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '10px' }}>
                    🎉 Congratulations! 🎉
                    <br />
                    You have won the IDO sale.
                  </span>
                ) : (
                  <p>Winners can participate in the token sale.</p>
                )}
              </>
            }
          />
          <Step
            style={{
              display:
                saleMarketInfo.whitelistInfo.startDate && saleMarketInfo.whitelistInfo.endDate ? 'block' : 'none',
            }}
            title={trText('ido_v2_subscription_timeline_step_4_title')}
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 3 ? 'green' : 'default'}>
                    {saleMarketInfo.withdrawDate
                      ? `${moment(saleMarketInfo.withdrawDate).utc(false).format('YYYY-MM-DD HH:mm:ss')}(UTC) ~ `
                      : 'TBA'}
                  </Tag>
                </p>
                {saleMarketInfo.withdrawDate && !saleMarketInfo.withdrawMessage && (
                  <p>You can claim tokens from My IDO.</p>
                )}
                {!saleMarketInfo.withdrawDate && saleMarketInfo.withdrawMessage && (
                  <p>{saleMarketInfo.withdrawMessage}</p>
                )}
                {isWithdrawBtn() && whiteListWinner && (
                  <Button
                    type="primary"
                    onClick={() => {
                      window.location.href = `/#/v2/my/ido`
                    }}
                  >
                    My IDO
                  </Button>
                )}
              </>
            }
          />
        </Steps>
      </div>
      {dxlSnapshotHistoryModal && myWhitelistApplyInfo && (
        <DxlSnapshotHistoryModal myWhitelistApplyInfo={myWhitelistApplyInfo} onClose={onCloseDxlSnapshotModal} />
      )}
    </>
  )
}

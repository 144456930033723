import React, { useState } from 'react'
import { Button, Form, Input, Space, Select, Avatar, Typography } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  MainCategoryEnum,
  PROJECT_COMMUNITY_HOMEPAGE_KEY,
  PROJECT_COMMUNITY_TWITTER_KEY,
  SubCategoryEnum,
} from '../../utils/types'

// const DEFAULT_ICON_IMAGE = 'https://github.com/dexlab-project/assets/blob/master/tokens/solana/dxl/symbol.png?raw=true'

const { Option } = Select
const { Title } = Typography

export default function InputTokenInfomation({ inputTokenInfomation, setInputTokenInfomation, next }) {
  const [form] = Form.useForm()
  const [iconUrl, setIconUrl] = useState<string>()
  const [imageCheck, setImageCheck] = useState(false)

  function isImageUrl(url) {
    const img = new Image()
    img.onload = function () {
      setImageCheck(true)
      setIconUrl(url)
    }
    img.onerror = function () {
      setImageCheck(false)
    }
    img.src = url
  }

  const onFinish = (values: any) => {
    if (!values.homepage) {
      window.alert(`Please project homepage url`)
      return
    }
    if (!imageCheck) {
      window.alert(`Please enter a valid symbol image URL`)
      return
    }

    const extLinks = values.community ?? []
    const community = [
      ...extLinks,
      {
        name: PROJECT_COMMUNITY_HOMEPAGE_KEY,
        url: values.homepage,
      },
    ]
    if (values.twitter) {
      community.push({
        name: PROJECT_COMMUNITY_TWITTER_KEY,
        url: values.twitter,
      })
    }
    setInputTokenInfomation({ ...values, community })
    next()
  }

  return (
    <>
      <Title style={{ marginBottom: '30px' }} level={4}>
        Enter token information
      </Title>
      <div style={{ marginBottom: '60px' }}>
        <Form form={form} initialValues={inputTokenInfomation} onFinish={onFinish} layout="vertical">
          <Form.Item style={{ display: 'inline-block', width: 'calc(10%)' }}>
            <Avatar src={iconUrl ?? inputTokenInfomation.iconUrl ?? null} size={80} />
          </Form.Item>
          <Form.Item
            name="iconUrl"
            label="Symbol icon URL"
            required
            tooltip="This icon will be displayed on the Dexlab market list."
            style={{ display: 'inline-block', width: 'calc(90%)' }}
            rules={[{ required: true }]}
          >
            <Input
              required
              value={inputTokenInfomation.iconUrl}
              type="url"
              placeholder="Symbol icon URL"
              onChange={(e) => {
                isImageUrl(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ display: 'inline-block', width: 'calc(50%)' }}
            name="mainCategory"
            label="Main Category"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select main category" allowClear>
              <Option value="GENERAL">{MainCategoryEnum.GENERAL}</Option>
              <Option value="MEME">{MainCategoryEnum.MEME}</Option>
              <Option value="NFT">{MainCategoryEnum.NFT}</Option>
              <Option value="POOL">{MainCategoryEnum.POOL}</Option>
              <Option value="DEX">{MainCategoryEnum.DEX}</Option>
              <Option value="MANAGEMENT">{MainCategoryEnum.MANAGEMENT}</Option>
              <Option value="ETC">{MainCategoryEnum.ETC}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            style={{ display: 'inline-block', width: 'calc(50%)' }}
            name="subCategory"
            label="Sub Category"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select sub category" allowClear>
              <Option value="GENERAL">{SubCategoryEnum.GENERAL}</Option>
              <Option value="DEFI">{SubCategoryEnum.DEFI}</Option>
              <Option value="FINANCE">{SubCategoryEnum.FINANCE}</Option>
              <Option value="GAME">{SubCategoryEnum.GAME}</Option>
              <Option value="ENERGY">{SubCategoryEnum.ENERGY}</Option>
              <Option value="COMMERCE">{SubCategoryEnum.COMMERCE}</Option>
              <Option value="IOT">{SubCategoryEnum.IOT}</Option>
              <Option value="AMM">{SubCategoryEnum.AMM}</Option>
              <Option value="MEDIA">{SubCategoryEnum.MEDIA}</Option>
              <Option value="HEALTH">{SubCategoryEnum.HEALTH}</Option>
              <Option value="LENDING">{SubCategoryEnum.LENDING}</Option>
              <Option value="INDEX">{SubCategoryEnum.INDEX}</Option>
              <Option value="FARMING">{SubCategoryEnum.FARMING}</Option>
              <Option value="ETC">{SubCategoryEnum.ETC}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="nameEn"
            label="Project Name(English)"
            required
            tooltip="This is the name that will be displayed on the dexlab market list"
            rules={[{ required: true, max: 20 }]}
          >
            <Input
              required
              placeholder="The project name to be exposed by default. Please enter in English."
              maxLength={20}
              max={20}
            />
          </Form.Item>
          <Form.Item
            label="Project Name(Korean/Chinese)"
            style={{ marginBottom: 0 }}
            tooltip="(Optional) If you do not enter an additional language, only English is displayed."
          >
            <Form.Item
              name="nameKr"
              style={{ display: 'inline-block', width: 'calc(50% - 1.5px)', marginRight: '3px' }}
              rules={[{ required: true, max: 20 }]}
            >
              <Input placeholder="Korean" maxLength={20} max={20} />
            </Form.Item>
            <Form.Item
              name="nameCn"
              style={{ display: 'inline-block', width: 'calc(50% - 1.5px)' }}
              rules={[{ required: true, max: 20 }]}
            >
              <Input placeholder="Chinese" maxLength={20} max={20} />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label="Project Homepage URL"
            tooltip="Enter the url of the project's official website. If there is no website, it will be automatically delisted."
            rules={[{ required: true }]}
            name="homepage"
            style={{ marginTop: '20px', marginBottom: 0 }}
          >
            <Input type="url" placeholder="project hompage url" />
          </Form.Item>

          <Form.Item
            name="twitter"
            label="Project Twitter URL"
            tooltip="If you enter the Twitter URL, the Twitter widget is exposed in the market information."
            style={{ marginTop: '20px', marginBottom: 0 }}
          >
            <Input type="url" placeholder="project twipper url" />
          </Form.Item>

          <Form.Item
            name="descriptionEn"
            label="Project description"
            style={{ marginTop: '50px', marginBottom: '10px' }}
          >
            <Input.TextArea placeholder="English" />
          </Form.Item>
          <Form.Item name="descriptionKr" style={{ marginBottom: 0 }}>
            <Input.TextArea style={{ marginTop: '10px', marginBottom: '10px' }} placeholder="Korean" />
          </Form.Item>
          <Form.Item name="descriptionCn" style={{ marginBottom: 0 }}>
            <Input.TextArea style={{ marginTop: '10px', marginBottom: '10px' }} placeholder="Chinese" />
          </Form.Item>

          <Form.Item label="Etc Links" style={{ marginTop: '6px' }} tooltip="Add links to white paper etc.">
            <Form.List name="community">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item {...restField} name={[name, 'name']} rules={[{ required: true, message: 'Name' }]}>
                        <Input type="text" placeholder="Name" />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, 'url']} rules={[{ required: true, message: 'Link URL' }]}>
                        <Input type="url" placeholder="Url" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Link
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

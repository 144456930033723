import { Connection, PublicKey } from '@solana/web3.js'
import { programs } from '@metaplex/js'

const Metadata = programs.metadata.Metadata

export async function getTokenMetadata(
  connection: Connection,
  mint: PublicKey,
): Promise<{ name; symbol; image; uri } | null> {
  try {
    const metadata = await Metadata.load(connection, await Metadata.getPDA(mint))
    if (metadata) {
      const uri = metadata.data.data.uri
      try {
        const response = fetch(uri)
        if (response) {
          const meta = await (await response).json()
          if (meta && meta.name && meta.symbol && meta.image) {
            return {
              ...meta,
              uri,
            }
          }
        }
      } catch (ex) {
        console.error(ex)
      }
    } else {
      // TODO Solana token-list
    }
    return null
  } catch {
    return null
  }
}

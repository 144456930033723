import React, { useEffect } from 'react'

const TwitterContainer = ({ projectName, url }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://platform.twitter.com/widgets.js'
    document.getElementsByClassName('twitter-embed')[0].appendChild(script)
  }, [])

  return (
    <section className="twitterContainer">
      <div className="twitter-embed">
        <a
          className="twitter-timeline"
          data-dnt="true"
          data-theme="dark"
          data-tweet-limit="1"
          data-chrome="nofooter"
          href={url}
        >
          {projectName} Tweet
        </a>
      </div>
    </section>
  )
}

export default TwitterContainer

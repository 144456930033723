import React from 'react'
import { useTranslation } from 'react-i18next'
import { isIE } from 'react-device-detect'

export default function NoneSupportBrowser() {
  const { t: trText } = useTranslation()
  return isIE ? (
    <div style={{ textAlign: 'center', height: '20px' }}>
      <div style={{ marginTop: '80px', fontWeight: 'bold' }}>{trText('no_support_browser')}</div>
    </div>
  ) : null
}

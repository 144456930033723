import React from 'react'
import { Result, Button, Typography } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useWallet } from '../utils/wallet'
import { useTranslation } from 'react-i18next'

const { Paragraph } = Typography

export default function WalletConnectGuide() {
  const { t: trText } = useTranslation()

  const { connected, select, disconnect } = useWallet()
  return (
    <Result
      status="info"
      title={trText('wallet_not_connect_title')}
      subTitle={trText('wallet_not_connect_desc')}
      extra={[
        <Button type="primary" key="console" onClick={connected ? disconnect : select}>
          {connected ? trText('disconnect_wallet') : trText('connect_wallet')}
        </Button>,
      ]}
    >
      <div className="desc">
        <Paragraph>
          <a
            style={{ color: '#FFFFFF' }}
            href="https://docs.dexlab.space/products/getting-started/wallet-tutorial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <QuestionCircleOutlined />
            {trText('wallet_not_connect_how_1')}&gt;
          </a>
        </Paragraph>
        <Paragraph>
          <a
            style={{ color: '#FFFFFF' }}
            href="https://docs.dexlab.space/products/getting-started/wallet-tutorial/connect-your-wallet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <QuestionCircleOutlined />
            {trText('wallet_not_connect_how_2')}&gt;
          </a>
        </Paragraph>
      </div>
    </Result>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { Spin, Tabs, Row, Switch, Alert } from 'antd'
import { useInterval } from 'react-use'
import {
  useAllOpenOrdersBalances,
  useWalletBalancesForAllMarkets,
  useTokenAccounts,
  useAllMarkets,
  MarketProvider,
  getTradePageUrl,
} from '../utils/dex-markets'
import { useWallet } from '../utils/wallet'
import WalletConnectGuide from '../componentsv2/WalletConnectGuide'
import FloatingElement from '../componentsv2/layout/FloatingElement'
import WalletBalancesTable from '../componentsv2/UserInfoTable/WalletBalancesTable'
import MarketBalancesSettleTable from '../componentsv2/UserInfoTable/MarketBalancesSettleTable'
import { useMintToTickers } from '../utils/tokens-v2'
import { useTranslation } from 'react-i18next'
import DexlabMarketAPI from '../utils/client/dexlabMarketApiConnector'
import { DexLabMarketV2Info } from '../utils/types'
import { Spinner } from 'react-bootstrap'

const { TabPane } = Tabs

export default function BalancesPage() {
  document.title = `Balances - The best DEX platform on SOLANA.`

  const { connected } = useWallet()
  const { marketAddress } = useParams()
  const [tokenAccounts] = useTokenAccounts()
  const [allV2Markets, setAllV2Markets] = useState<DexLabMarketV2Info[]>([])
  const [myAllMarkets, setMyAllMarkets] = useState<DexLabMarketV2Info[]>([])
  const [hideBalacneZeroToken, setHideBalacneZeroToken] = useState(true)

  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress))
    }
  }, [marketAddress])
  const history = useHistory()
  function setMarketAddress(address) {
    history.push(getTradePageUrl(address))
  }

  useEffect(() => {
    async function getAllMarkets() {
      const response = await DexlabMarketAPI.getAllMarketsV2()
      if (response) {
        getMarketByTokenMintIds(response)
        setAllV2Markets(response)
      } else {
        history.push(`/error`)
      }
    }
    getAllMarkets()
  }, [tokenAccounts])

  async function getMarketByTokenMintIds(allDexV2Markets: DexLabMarketV2Info[]) {
    const ids = tokenAccounts?.map((f) => f.effectiveMint.toBase58()) ?? []
    if (!_.isEmpty(ids)) {
      const response = await DexlabMarketAPI.getAllMarketV2ByBaseTokenIds(ids)
      const resultDexlabMarkets: DexLabMarketV2Info[] = []
      if (!_.isEmpty(allDexV2Markets) && response) {
        response.forEach((m) => {
          const findMarket = allDexV2Markets.find((f) => f.address === m.address)
          if (findMarket) {
            resultDexlabMarkets.push(findMarket)
          }
        })
        setMyAllMarkets(resultDexlabMarkets)
      }
    }
  }

  if (!connected) {
    return (
      <Row
        justify="center"
        style={{
          marginTop: '10%',
        }}
      >
        <WalletConnectGuide />
      </Row>
    )
  }

  return (
    // @ts-ignore
    <FloatingElement style={{ flex: 1, paddingTop: 3 }}>
      <div className="col-xl-12 col-lg-12 col-md-12 col-xxl-12" style={{ marginBottom: '6px', textAlign: 'center' }}>
        <Alert
          type="success"
          message={
            <>
              Dexlab platform is open! This page service is available in Dexlab platform.{' '}
              <a href="https://www.dexlab.space/my/balances" target="_blank" rel="noopener noreferrer">
                Go to Dexlab platform
              </a>
            </>
          }
        />
      </div>
      <h3>Wallet Manager</h3>
      <div style={{ marginTop: '10px' }}>
        <div style={{ textAlign: 'right' }}>
          <Switch
            disabled={_.isEmpty(allV2Markets) || _.isEmpty(myAllMarkets)}
            checkedChildren="Hide Zero balance"
            unCheckedChildren="Show Zero balance"
            checked={hideBalacneZeroToken}
            onChange={(checked) => {
              setHideBalacneZeroToken(checked)
            }}
          />
        </div>
        {!_.isEmpty(allV2Markets) && !_.isEmpty(myAllMarkets) ? (
          <MarketProvider allV2Markets={allV2Markets} marketAddress={marketAddress} setMarketAddress={setMarketAddress}>
            <InnerPage
              allV2Markets={allV2Markets}
              baseAllMarkets={myAllMarkets}
              hideBalacneZeroToken={hideBalacneZeroToken}
            />
          </MarketProvider>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </FloatingElement>
  )
}

function InnerPage({ allV2Markets, baseAllMarkets, hideBalacneZeroToken }) {
  const { t: trText } = useTranslation()

  const [firstWalletBalancesLoading, setFirstWalletBalancesLoading] = useState(true)
  const [firstUnsettledBalancesLoading, setFirstUnsettledBalancesLoading] = useState(true)
  const [allMarkets, allMarketsConnected] = useAllMarkets(allV2Markets, baseAllMarkets, true)
  const walletBalances = useWalletBalancesForAllMarkets(allMarkets ?? [])
  const mintToTickers = useMintToTickers()
  const openOrdersBalances = useAllOpenOrdersBalances(allMarkets ?? [], allV2Markets)

  useInterval(() => {
    setFirstWalletBalancesLoading(false)
    setFirstUnsettledBalancesLoading(false)
  }, 4500)

  const balanceData = (walletBalances || []).map((balance, idx) => {
    const balances = {
      key: idx,
      coin:
        mintToTickers[balance.mint] ??
        baseAllMarkets.filter((f) => !f.base.includes(`OLDDXL`)).find((f) => f.baseMint === balance.mint)?.base,
      mint: balance.mint,
      walletBalance: balance.balance,
      openOrdersFree: 0,
      openOrdersTotal: 0,
    }
    for (let openOrdersAccount of openOrdersBalances[balance.mint] || []) {
      balances['openOrdersFree'] += openOrdersAccount.free
      balances['openOrdersTotal'] += openOrdersAccount.total
    }
    return balances
  })

  const settleBalanceData: any[] = []
  if (walletBalances) {
    walletBalances.forEach((balance, idx) => {
      for (let openOrdersAccount of openOrdersBalances[balance.mint] || []) {
        settleBalanceData.push({
          key: idx,
          coin:
            mintToTickers[balance.mint] ??
            baseAllMarkets.filter((f) => !f.base.includes(`OLDDXL`)).find((f) => f.baseMint === balance.mint)?.base,
          mint: balance.mint,
          walletBalance: balance.balance,
          openOrdersFree: openOrdersAccount.free,
          openOrdersTotal: openOrdersAccount.total,
          marketAddress: openOrdersAccount.market.toBase58(),
        })
      }
    })
  }

  function getBalanceData() {
    if (hideBalacneZeroToken) {
      return balanceData.filter((f) => f.walletBalance > 0 || f.openOrdersFree > 0 || f.openOrdersTotal > 0)
    }
    return balanceData
  }

  function getSettleBalanceData() {
    if (hideBalacneZeroToken) {
      return settleBalanceData.filter((f) => f.walletBalance > 0 || f.openOrdersFree > 0 || f.openOrdersTotal > 0)
    }
    return settleBalanceData
  }

  return (
    <Tabs defaultActiveKey="walletBalances">
      <TabPane tab={trText('my_wallet_balance')} key="walletBalances">
        {allMarketsConnected && !firstWalletBalancesLoading ? (
          <>
            <WalletBalancesTable
              walletBalances={getBalanceData()}
              allV2Markets={allV2Markets}
              allMarkets={allMarkets ?? []}
              allMarketsConnected={allMarketsConnected ?? false}
            />
            <div style={{ marginTop: '10px', fontSize: '13px' }}>
              <span>* It may take 30 seconds to 1 minute to load order/settlement information.</span>
            </div>
          </>
        ) : (
          <Spinner animation="border" />
        )}
      </TabPane>
      <TabPane tab={'Individual settlement'} key="marketSettle">
        {allMarketsConnected && !firstUnsettledBalancesLoading ? (
          <>
            <div style={{ textAlign: 'center', color: '#FFFFFF', height: '70px' }}>
              This menu is for individual settlement when there are many settlement tokens.
            </div>
            <MarketBalancesSettleTable
              walletBalances={getSettleBalanceData()}
              allV2Markets={allV2Markets}
              allMarkets={allMarkets ?? []}
              allMarketsConnected={allMarketsConnected ?? false}
            />
            <div style={{ marginTop: '10px', fontSize: '13px' }}>
              <span>* It may take up to several minutes for transaction confirmation to be reflected.</span>
            </div>
          </>
        ) : (
          <Spinner animation="border" />
        )}
      </TabPane>
    </Tabs>
  )
}

import React from 'react'
import { Tooltip } from 'antd'
import { makeStyles, Typography, Popover, IconButton, CircularProgress } from '@material-ui/core'
import { Info, HelpOutline } from '@material-ui/icons'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { PublicKey } from '@solana/web3.js'
import { useTokenMap } from '../context/TokenList'
import { useSwapContext, useSwapFair } from '../context/Swap'
// import { useMint } from '../context/Token'
import { useRoute, useMarketName, useBbo } from '../context/Dex'
import { SOLANA_EXPLORER_URL } from '../../application'
import Link from '../../componentsv2/Link'
import { useTranslation } from 'react-i18next'
// import { useFeeDiscountKeys } from '../../utils/dex-markets'

const useStyles = makeStyles(() => ({
  infoLabel: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  infoButton: {
    marginLeft: '5px',
    padding: 0,
    fontSize: '14px',
  },
}))

export function InfoLabel() {
  const { t: trText } = useTranslation()
  const styles = useStyles()

  // const [feeAccounts] = useFeeDiscountKeys()
  const { fromMint, toMint, slippage, fairOverride } = useSwapContext()
  // const fromMintInfo = useMint(fromMint)
  const fair = useSwapFair()

  const tokenMap = useTokenMap()
  let fromTokenInfo = tokenMap.get(fromMint.toString())
  let toTokenInfo = tokenMap.get(toMint.toString())

  // const swapFeeInfos = (feeAccounts || []).map((account, index) => ({
  //   ...account,
  //   index,
  //   key: account.pubkey.toBase58(),
  // }))
  return (
    <>
      <div className={styles.infoLabel}>
        <Typography component={'span'} style={{ fontSize: '14px' }}>
          {fair !== undefined && toTokenInfo && fromTokenInfo ? (
            `1 ${toTokenInfo.symbol} = ${parseFloat(`${fair.toFixed(9)}`)} ${fromTokenInfo.symbol}`
          ) : (
            <CircularProgress size={15} />
          )}
        </Typography>
        <InfoButton />
      </div>
      <div style={{ height: '100%', marginBottom: '10px' }}>
        {/* <Typography style={{ fontWeight: 'bold' }}>Infomation</Typography> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <span>
            {trText('swap_slippage_tolerance_title')}{' '}
            <Tooltip title={trText('swap_slippage_tolerance_info')}>
              <HelpOutline style={{ fontSize: '16px' }} />
            </Tooltip>
          </span>
          <code style={{ marginLeft: '10px' }}>{slippage}%</code>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <span>
            {trText('swap_fair_price_title')}{' '}
            <Tooltip title={trText('swap_fair_price_info')}>
              <HelpOutline style={{ fontSize: '16px' }} />
            </Tooltip>
          </span>
          <code style={{ marginLeft: '10px' }}>
            {fair ? `${parseFloat(fair.toFixed(9))}${!fairOverride ? '(Auto)' : ''}` : <CircularProgress size={15} />}
          </code>
        </div>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <span>
            {trText('swap_fee_title')}{' '}
            <Tooltip
              title={
                <>
                  {trText('swap_fee_info')}{' '}
                  <a
                    style={{ color: '#FFFFFF', fontWeight: 'bold' }}
                    href="https://docs.dexlab.space/products/getting-started/dexlab-dex/trading-fees"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {trText('swap_fee_view_discount_table')}
                  </a>
                </>
              }
            >
              <HelpOutline style={{ fontSize: '16px' }} />
            </Tooltip>
          </span>
          <code style={{ marginLeft: '10px' }}>{`0.22%`}</code>
        </div> */}
        {/* <TradeRoute allV2Markets={allV2Markets} /> */}
      </div>
    </>
  )
}

function InfoButton() {
  const styles = useStyles()
  return (
    <PopupState variant="popover">
      {
        //@ts-ignore
        (popupState) => (
          <div style={{ display: 'flex' }}>
            <IconButton {...bindTrigger(popupState)} className={styles.infoButton}>
              <Info fontSize="small" />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{ style: { borderRadius: '10px' } }}
              disableRestoreFocus
            >
              <InfoDetails />
            </Popover>
          </div>
        )
      }
    </PopupState>
  )
}

// function TradeRoute({ allV2Markets }) {
//   const { fromMint, toMint } = useSwapContext()
//   const route = useRoute(fromMint, toMint)

//   return (
//     <div style={{ minHeight: '50px', marginBottom: '20px' }}>
//       <Typography style={{ fontWeight: 'bold', marginTop: '25px' }}>Trade Route</Typography>
//       {route ? (
//         route.map((market: PublicKey) => {
//           const dexlabMarketInfo = allV2Markets.find((f) => f.address === market.toBase58())
//           return <MarketRoute key={market.toString()} market={market} />
//         })
//       ) : (
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             marginTop: '5px',
//           }}
//         >
//           <span>-</span>
//           <code style={{ marginLeft: '10px' }}>Route not found</code>
//         </div>
//       )}
//     </div>
//   )
// }

function InfoDetails() {
  const { fromMint, toMint } = useSwapContext()
  const route = useRoute(fromMint, toMint)
  const tokenMap = useTokenMap()
  const fromMintTicker = tokenMap.get(fromMint.toString())?.symbol
  const toMintTicker = tokenMap.get(toMint.toString())?.symbol
  const addresses = [
    { ticker: fromMintTicker, mint: fromMint },
    { ticker: toMintTicker, mint: toMint },
  ]

  return (
    <div style={{ padding: '15px', width: '250px' }}>
      <div>
        <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>Trade Route</Typography>
        {route ? (
          route.map((market: PublicKey) => {
            return <MarketRoute key={market.toString()} market={market} />
          })
        ) : (
          <Typography color="textSecondary">Route not found</Typography>
        )}
      </div>
      <div style={{ marginTop: '15px' }}>
        <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>Tokens</Typography>
        {addresses.map((address) => {
          return (
            <div
              key={address.mint.toString()}
              style={{
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Link external to={`${SOLANA_EXPLORER_URL}/address/${address.mint.toString()}`}>
                {address.ticker}
              </Link>
              <code style={{ width: '128px', overflow: 'hidden' }}>{address.mint.toString()}</code>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function MarketRoute({ market }: { market: PublicKey }) {
  const marketName = useMarketName(market)
  const bbo = useBbo(market)
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px',
      }}
    >
      <Link to={`/market/${market.toString()}`}>
        {/* {dexlabMarketInfo ? <Avatar size={20} style={{ marginRight: '7px' }} src={dexlabMarketInfo?.iconUrl} /> : ''} */}
        {marketName}
      </Link>
      <code style={{ marginLeft: '10px' }}>{bbo && bbo.mid ? bbo.mid.toFixed(6) : <CircularProgress size={15} />}</code>
    </div>
  )
}

/* eslint-disable */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Steps, Col, Row, Button, Tag } from 'antd'
import { useWallet } from '../utils/wallet'
import DexlabWhitelistApi from '../utils/client/dexlabWhitelistApiConnector'
import { DexlabMyApplyWhitelistResponse } from '../utils/types'
import { useWalletBalanceByAccount } from '../utils/dex-markets'
import { notify } from '../utils/notifications'
import { makeTokenWalletTransaction } from '../utils/wallet'
import { sendTransaction } from '../utils/send'
import { useConnection } from '../utils/connection'
import { PublicKey } from '@solana/web3.js'
import { ReloadOutlined } from '@ant-design/icons'

const { Step } = Steps

/**
 * Step0: 지갑 연결
 * Step1: 토큰 지갑 생성
 * Step2: Whitelist 신청
 * Step3: 참여완료 여부 확인
 */
export default function AirdropTimelineView({ saleMarketInfo, reloadMarketInfo, whiteListWinner, refreshAllData }) {
  const connection = useConnection()
  const { wallet, select, connected } = useWallet()
  const [myWhitelistApplyInfo, setMyWhitelistApplyInfo] = useState<DexlabMyApplyWhitelistResponse | undefined | null>(
    undefined,
  )
  const [myTokenInfoErrorMessage, setMyTokenInfoErrorMessage] = useState<string | undefined>(undefined)
  const baseTokenWalletInfo = useWalletBalanceByAccount(
    saleMarketInfo.baseTokenMint,
    saleMarketInfo.baseTokenDecimals,
    3000,
  )
  const currentAirdropWallet = baseTokenWalletInfo ? [baseTokenWalletInfo] : []

  useEffect(() => {
    // 지갑연결 + 화리 참여한 경우
    if (wallet && connected && saleMarketInfo.whitelistInfo.participation) {
      getMyWhitelistApplyInfo()
    }
  }, [connected, saleMarketInfo])

  function getStepCurrent() {
    // 지갑 미연결
    if (!connected || !wallet) {
      return 0
    }

    // Base Token 지갑이 있는지 확인
    if (connected && wallet && saleMarketInfo) {
      if (_.isEmpty(currentAirdropWallet)) {
        return 1
      }
    }

    // whitelist 신청여부
    if (connected && (!saleMarketInfo.whitelistInfo.participation || !myWhitelistApplyInfo)) {
      return 2
    }

    // 신청 완료
    return 3
  }

  // 내 화리 참여정보 조회
  async function getMyWhitelistApplyInfo() {
    try {
      const response = await DexlabWhitelistApi.getMyApplyWhitelist(
        saleMarketInfo.whitelistInfo.whitelistId,
        wallet!!.publicKey.toBase58(),
      )
      if (response) {
        setMyTokenInfoErrorMessage(undefined)
        setMyWhitelistApplyInfo(response)
      }
    } catch (e: any) {
      setMyTokenInfoErrorMessage(e.message)
    }
  }

  async function createBaseTokenWallet() {
    try {
      if (connected && wallet && saleMarketInfo) {
        const createSplTokenWallet = await makeTokenWalletTransaction({
          wallet,
          splTokenMintAddress: new PublicKey(saleMarketInfo.baseTokenMint),
        })
        const txId = await sendTransaction({
          transaction: createSplTokenWallet,
          wallet,
          connection,
        })
        console.log(`txID: ${txId}`)
      }
    } catch (e: any) {
      notify({
        message: e.message,
        type: 'error',
      })
    }
  }

  const checkProgressAirdropEvent = () => {
    const whitelistStartDate = moment(saleMarketInfo.whitelistInfo.startDate).utc(true)
    const whitelistEndDate = moment(saleMarketInfo.whitelistInfo.endDate).utc(true)

    const todayDate = moment().utc()
    // 화이트리스트 시작전인지? ( 시작전 )
    if (todayDate.isBefore(whitelistStartDate)) {
      return 'SOON'
    }

    // 화이트리스트 진행중인지? ( 신청중 )
    if (todayDate.isBetween(whitelistStartDate, whitelistEndDate)) {
      if (wallet && connected && !saleMarketInfo.whitelistInfo.isWhitelistStaked) {
        return 'CONDITION_NOT_MET'
      }
      return 'ING'
    }

    // 화이트리스트 신청 날짜가 지났는지? ( 종료 )
    if (todayDate.isAfter(whitelistEndDate)) {
      return 'END'
    }
  }

  const stepCurrent = getStepCurrent()
  return saleMarketInfo.saleType === 'AIRDROP_EVENT' ? (
    <>
      <div style={{ marginTop: '30px' }}>
        <h3 style={{ marginBottom: '7px' }}>
          Apply for Airdrop <Tag>Staker Only</Tag>
        </h3>
        <p style={{ marginBottom: '25px' }}>
          {`${saleMarketInfo.whitelistInfo.startDate} ~ ${saleMarketInfo.whitelistInfo.endDate} UTC`}
          {saleMarketInfo?.whitelistInfo?.airdropSnapshotDateTime && (
            <>
              <br />
              <span style={{ fontSize: '13px', color: '#646464' }}>
                Staking Snapshot Time: {saleMarketInfo?.whitelistInfo?.airdropSnapshotDateTime ?? ''} UTC
              </span>
            </>
          )}
        </p>
        <Steps direction="vertical" style={{ marginBottom: '30px', width: '100%' }} size="small" current={stepCurrent}>
          <Step
            title={`Connect Wallet`}
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 0 ? 'green' : 'default'}>
                    {connected && !_.isEmpty(currentAirdropWallet)
                      ? wallet?.publicKey.toBase58()
                      : 'Connect your wallet to participate in Airdrop.'}
                  </Tag>
                </p>
                {connected && stepCurrent !== 0 && <p>Wallet is connected.</p>}
                {!connected && (
                  <Row>
                    <Col span={24} style={{ textAlign: 'left', marginTop: '6px', marginBottom: '30px' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          select()
                        }}
                      >
                        Connect Wallet
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            }
          />
          <Step
            title={`Create a ${saleMarketInfo.base} token wallet.`}
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 1 ? 'green' : 'default'}>
                    {connected && !_.isEmpty(currentAirdropWallet)
                      ? currentAirdropWallet[0].pubKey
                      : `You need a wallet to receive tokens.`}
                  </Tag>
                </p>
                {connected && stepCurrent !== 0 && !_.isEmpty(currentAirdropWallet) && (
                  <p>The {saleMarketInfo.base} token wallet has been verified. Do the following.</p>
                )}
                {stepCurrent === 1 && _.isEmpty(currentAirdropWallet) ? (
                  <Button
                    disabled={!_.isEmpty(currentAirdropWallet)}
                    type="primary"
                    onClick={() => {
                      createBaseTokenWallet()
                    }}
                  >
                    Create {saleMarketInfo.base} Token Wallet
                  </Button>
                ) : null}
              </>
            }
          />
          <Step
            title={
              <>
                Submit your wallet address{' '}
                {stepCurrent === 2 && (
                  <span style={{ cursor: 'pointer' }} onClick={refreshAllData}>
                    <ReloadOutlined
                      style={{ fontSize: '20px', color: 'coral', paddingLeft: '5px', paddingBottom: '5px' }}
                    />
                  </span>
                )}
              </>
            }
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 2 ? 'green' : 'default'}>
                    {connected && saleMarketInfo.whitelistInfo.participation
                      ? `Participation in Airdrop Event has been confirmed.`
                      : wallet &&
                        connected &&
                        saleMarketInfo.whitelistInfo &&
                        saleMarketInfo.whitelistInfo.isWhitelistStaked
                      ? 'Confirmed as a DXL staker 🙂'
                      : wallet && connected
                      ? `DXL staking information is not verified. (Snapshot Time: ${saleMarketInfo?.whitelistInfo?.airdropSnapshotDateTime} UTC)`
                      : 'Connect your wallet'}
                  </Tag>
                </p>
                {stepCurrent === 2 && (!saleMarketInfo.whitelistInfo.participation || !myWhitelistApplyInfo) ? (
                  <Button
                    disabled={saleMarketInfo.whitelistInfo.participation || checkProgressAirdropEvent() !== 'ING'}
                    type="primary"
                    onClick={() => {
                      if (!saleMarketInfo.whitelistInfo.participation && wallet?.publicKey) {
                        const whitelistUrl = `https://apply-whitelist.dexlab.space/#/survey?id=${
                          saleMarketInfo.whitelistInfo.whitelistId
                        }&walletAddress=${wallet?.publicKey.toBase58()}`
                        window.open(whitelistUrl)
                      } else {
                        notify({
                          message: `Participation has been completed :)`,
                          type: 'success',
                        })
                      }
                    }}
                  >
                    {checkProgressAirdropEvent() === 'SOON' && 'Airdrop event is starting Soon!'}
                    {checkProgressAirdropEvent() === 'ING' && 'Apply for Airdrop Event'}
                    {checkProgressAirdropEvent() === 'END' && 'Airdrop event has ended'}
                    {checkProgressAirdropEvent() === 'CONDITION_NOT_MET' &&
                      'The event is open for the DXL stakers who have staked before snapshot time as shown in details.'}
                  </Button>
                ) : null}
              </>
            }
          />
          <Step
            title={`Participation done!`}
            description={
              <>
                <p style={{ color: '#6f6f6f', fontSize: '12px' }}>
                  <Tag color={stepCurrent === 3 ? 'green' : 'default'}>
                    {connected && saleMarketInfo.whitelistInfo.participation
                      ? `Participation in Airdrop Event has been confirmed.`
                      : 'Participation is eligible when you complete the above steps.'}
                  </Tag>
                </p>
                {stepCurrent === 2 && (
                  <p>
                    Thank you! Participation is completed.
                    <br />
                    The airdrop will be distributed to all DXL stakers who have applied for it.
                    <br />
                    {saleMarketInfo.withdrawMessage && (
                      <div style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                        <br />
                        Announcement: {saleMarketInfo.withdrawMessage}
                      </div>
                    )}
                  </p>
                )}
              </>
            }
          />
        </Steps>
      </div>
    </>
  ) : null
}

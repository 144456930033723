import { Connection, PerfSample } from '@solana/web3.js'
import { SolanaNetworkStatus } from '../types'

export default class SolanaStatusApi {
  static async get(url: string) {
    try {
      const response = await fetch(url)
      if (response.ok) {
        return response.json()
      }
    } catch (err) {
      console.log(`Error SolanaStatus API ${url}: ${err}`)
    }
    return null
  }

  static async post(url: string, body: any) {
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(body),
      })
    } catch (err: any) {
      throw new Error(err.message)
    }
  }

  static getConnection(url) {
    try {
      return new Connection(url)
    } catch (err) {
      // NONCE
    }
  }

  static async getRpcHealth(endpoint: string): Promise<any> {
    const body = { jsonrpc: '2.0', id: 1, method: 'getHealth' }
    const health = await SolanaStatusApi.post(endpoint, body)

    return await health.json()
  }

  static async getCurrentSolanaStatus(): Promise<SolanaNetworkStatus> {
    return {
      status: 'OK',
      message: '',
      classNames: '',
      shortenMessage: '',
    }
    // try {
    //   const connection = this.getConnection(SOLANA_SERUM_HOST)
    //   const perfRes: PerfSample[] | undefined = await connection?.getRecentPerformanceSamples(5)
    //   const pingStatsRes: any[] = await SolanaStatusApi.get('https://ping.solana.com/mainnet-beta/last6hours')
    //   let averageTps = 0
    //   let averagePingSuccess = 0
    //   if (perfRes) {
    //     const sumTransaction = perfRes.reduce(
    //       (previousValue, currentValue) => previousValue + currentValue.numTransactions,
    //       0,
    //     )
    //     const sumPeriodSecs = perfRes.reduce(
    //       (previousValue, currentValue) => previousValue + currentValue.samplePeriodSecs,
    //       0,
    //     )
    //     averageTps = Number(sumTransaction / sumPeriodSecs)
    //   }

    //   if (pingStatsRes) {
    //     const recentFiveItems = pingStatsRes.slice(0, 5)
    //     const sumSubmitted = recentFiveItems.reduce(
    //       (previousValue, currentValue) => previousValue + currentValue.submitted,
    //       0,
    //     )
    //     const sumConfirmed = recentFiveItems.reduce(
    //       (previousValue, currentValue) => previousValue + currentValue.confirmed,
    //       0,
    //     )
    //     averagePingSuccess = Number(sumConfirmed / sumSubmitted)
    //   }
    //   if (averageTps === 0 && averagePingSuccess <= 0) {
    //     return {
    //       status: 'FAIL',
    //       message:
    //         'The Solana network is experiencing congestion or reduced performance. Transactions may fail to send or confirm.',
    //       classNames: 'bg-red-300 text-red-700',
    //       shortenMessage: 'The Solana network is experiencing congestion or reduced performance.',
    //     }
    //   } else if (averageTps > 1600 && averagePingSuccess >= 0.5) {
    //     return {
    //       status: 'OK',
    //       message: '',
    //       classNames: '',
    //       shortenMessage: '',
    //     }
    //   } else {
    //     return {
    //       status: 'DEGRADED',
    //       message:
    //         'The Solana network is experiencing congestion or reduced performance. Transactions may fail to send or confirm.',
    //       classNames: 'bg-yellow-300 text-yellow-700',
    //       shortenMessage: 'The Solana network is experiencing congestion or reduced performance.',
    //     }
    //   }
    // } catch (e) {
    //   return {
    //     status: 'OK',
    //     message: '',
    //     classNames: '',
    //     shortenMessage: '',
    //   }
    // }
  }
}

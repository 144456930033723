import { createGlobalStyle } from 'styled-components'

export const GlobalStyleLight = createGlobalStyle`
html,body{
  background: #FFFFFF;
}
input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
}
input[type=number]:hover::-webkit-inner-spin-button,
input[type=number]:focus::-webkit-inner-spin-button {
  opacity: 0.25;
}

/* 
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #F6F8FA;
}

::-webkit-scrollbar-thumb {
  background: #F6F8FA;
}

::-webkit-scrollbar-thumb:hover {
  background: #F6F8FA;
}
*/

.ant-slider-track, .ant-slider:hover .ant-slider-track {
  background-color: #5d78ff;
  opacity: 0.75;
}
.ant-col-8 {
  color: #FFFFFF;
}
.ant-slider-track,
.ant-slider ant-slider-track:hover {
  background-color: #5d78ff;
  opacity: 0.75;
}

.ant-slider-dot-active,
.ant-slider-handle,
.ant-slider-handle-click-focused,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)  {
  border: 2px solid #1652f0; 
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #FAFAFA;
}
.ant-table-tbody > tr > td {
  border-bottom: 3px solid #FFFFFF;
}
.ant-table-container table > thead > tr:first-child th {
  border-bottom: none;
}
.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #434a59 !important;
}
.ant-layout {
    background: #FFFFFF
  }
  .ant-table {
    background: #F6F8FA;
  }
  .ant-table-thead > tr > th {
    background: #FFFFFF;
  }
.ant-select-item-option-content {
  img {
    margin-right: 4px;
  }
}
.ant-modal-content {
  background-color: #F6F8FA;
}

@-webkit-keyframes highlight {
  from { background-color: #1652f0;}
  to {background-color: #F6F8FA;}
}
@-moz-keyframes highlight {
  from { background-color: #1652f0;}
  to {background-color: #F6F8FA;}
}
@-keyframes highlight {
  from { background-color: #1652f0;}
  to {background-color: #F6F8FA;}
}
.flash {
  -moz-animation: highlight 0.5s ease 0s 1 alternate ;
  -webkit-animation: highlight 0.5s ease 0s 1 alternate;
  animation: highlight 0.5s ease 0s 1 alternate;
}
.ant-input-group-addon:first-child {
  color: #000000;
}
.ant-select-selection-placeholder {
  color: #000000;
}
`

export const GlobalStyleDark = createGlobalStyle`
html,body{
  background: #000000;
}
input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
}
input[type=number]:hover::-webkit-inner-spin-button,
input[type=number]:focus::-webkit-inner-spin-button {
  opacity: 0.25;
}

/* 
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #F6F8FA;
}

::-webkit-scrollbar-thumb {
  background: #F6F8FA;
}

::-webkit-scrollbar-thumb:hover {
  background: #F6F8FA;
}
*/

.ant-slider-track, .ant-slider:hover .ant-slider-track {
  background-color: #5d78ff;
  opacity: 0.75;
}
.ant-col-8 {
  color: #FFFFFF;
}
.ant-slider-track,
.ant-slider ant-slider-track:hover {
  background-color: #5d78ff;
  opacity: 0.75;
}
.ant-slider-dot-active,
.ant-slider-handle,
.ant-slider-handle-click-focused,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)  {
  border: 2px solid #5d78ff; 
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #000000;
}
.ant-table-tbody > tr > td {
  border-bottom: 3px solid #434a59;
}
.ant-table-container table > thead > tr:first-child th {
  border-bottom: none;
}
.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #434a59 !important;
}
.ant-layout {
    background: transparent
  }
  .ant-table {
    background: transparent;
  }
  .ant-table-thead > tr > th {
    background: transparent;
  }
.ant-select-item-option-content {
  img {
    margin-right: 4px;
  }
}
.ant-modal-content {
  background-color: #F6F8FA;
}

.ant-modal-body {
  background-color: #000000;
}
.ant-modal-footer {
  background-color: #000000;
}

@-webkit-keyframes highlight {
  from { background-color: #5d78ff;}
  to {background-color: #F6F8FA;}
}
@-moz-keyframes highlight {
  from { background-color: #5d78ff;}
  to {background-color: #F6F8FA;}
}
@-keyframes highlight {
  from { background-color: #5d78ff;}
  to {background-color: #F6F8FA;}
}
.flash {
  -moz-animation: highlight 0.5s ease 0s 1 alternate ;
  -webkit-animation: highlight 0.5s ease 0s 1 alternate;
  animation: highlight 0.5s ease 0s 1 alternate;
}
.ant-input-group-addon:first-child {
  color: #FFFFFF;
}
.ant-select-selection-placeholder {
  color: #FFFFFF;
}
`
